<template>
    <router-link :to="url">
        <figure class="image" v-if="image">
            <smart-image :image="image" :size="size" alt=""></smart-image>
        </figure>
        <div v-else class="missing-post-image">
            <icon icon="fa-photo"></icon>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "ListImage",
    props: {
        url: {
            type: String,
            default: () => {
                return "";
            },
        },
        size: {
            type: Number,
            default: null,
        },
        crop: {
            type: Boolean,
            default: true,
        },
        image: {
            type: Object,
            default: () => {
                return null;
            },
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.missing-post-image {
    width: 80px;
    height: 80px;
    border: 1px solid rgba($light-gray, 0.3);
    margin-right: 10px;
    @include flexCenter();
    span > .fa {
        color: rgba($light-gray, 0.3);
    }
}
</style>
