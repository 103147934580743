import Vue from 'vue'
import { Modal, ImageModal, AddLinkModal, AddVideoModal } from './modals'
import MainFooter from './layout/MainFooter'
import LoadingPage from './layout/LoadingPage'
import swal from 'sweetalert'

// ui
import ToggleButton from 'vue-js-toggle-button'
import AddItem from './ui/AddItem'
import ImageInput from './ui/ImageInput'
import TextInput from './ui/TextInput'
import PlusButton from './ui/PlusButton'
import Draggable from './ui/Draggable'
import Editable from './ui/Editable'
import Tagger from './ui/Tagger'
import EditPageButton from './ui/EditPageButton'
import VueClipboards from 'vue-clipboards';
import Icon from './ui/Icon'
import Errors from './ui/Errors'
import BreadCrumbs from './ui/BreadCrumbs'
import Cleave from './ui/Cleave';
import SelectControl from './ui/SelectControl';
import ImageUploadButton from './ui/ImageUploadButton';

// admin
import AdminLayout from './layout/AdminLayout'
import FormCard from './layout/FormCard'

// media
import VideoPlayer from './media/VideoPlayer'
import GalleryImage from './media/GalleryImage'
import SmartImage from './media/SmartImage'

// post
import PostGrid from './page/PostsGrid'

// alerts
Vue.prototype.$swal = swal

// lists
import ContentPagePostItem from './content-page/ContentPagePostItem'
import ListItem from './content-page/ListItem'
import ListImage from './content-page/ListImage'
import ListControls from './content-page/ListControls'

Vue.use(ToggleButton)
Vue.use(VueClipboards);
// Vue.use(VueMask);
// Vue.use(ValidationProvider, {
// 	errorBagName: 'formErrors'
// });

export default {
	Draggable,
	Modal,
	ImageModal,
	AddLinkModal,
	AddVideoModal,

	// layout
	MainFooter,

	// page
	PostGrid,

	// ui
	Tagger,
	TextInput,
	EditPageButton,
	Errors,
	BreadCrumbs,
	ImageUploadButton,
	AddItem,
	ImageInput,
	PlusButton,
	Icon,
	Editable,
	LoadingPage,
	Cleave,
	SelectControl,

	// media
	GalleryImage,
	VideoPlayer,
	SmartImage,

	// admin
	AdminLayout,
	FormCard,

	// lists
	ContentPagePostItem,
	ListItem,
	ListImage,
	ListControls,
}
