<template>
    <a class="button hamburger" :class="{'is-active': $store.state.sideMenuActive}" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
    </a>
</template>

<script>
export default {
    name: "ComponentName",
    props: {},
    data() {
        return {};
    },
    components: {},
    methods: {
        toggleMenu() {
            this.$store.commit("sideMenuActive", !this.$store.state.sideMenuActive);
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";
@import "../../styles/variables";
.hamburger {
    background-color: transparent;
    @include hamburger(8px);
    padding: 20px 30px;
    // top: 0.5px;
    height: 52px;
    // position: fixed;
    z-index: 1000;
    border: none;
    &:hover {
        background-color: transparent;
        color: #3e3e3e;
    }
    &.is-active {
        background-color: transparent;
    }
    position: fixed;
}
</style>
