<template>
    <div class="modal" :class="{'is-active': show}" v-show="show">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content" v-if="src">
            <p class="image">
                <img :src="src" alt="">
            </p>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
</template>

<script>
import ModalMixin from "./ModalMixin";
export default {
    name: "Modal",
    mixins: [ModalMixin],
    props: {},
    data() {
        return {
            src: null,
            title: null,
        };
    },
    components: {},
    methods: {
        close() {
            this.show = false;
            this.$nextTick(() => {
                this.src = null;
            });
            this.$emit("close");
        },
        open(options) {
            options = options || {};
            this.src = options.src;
            this.title = options.title;
            this.show = true;
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        console.log("modal mounted");
    },
};
</script>

<style scoped lang="scss">
.image img {
    max-width: 100%;
}
</style>
