<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <div class="is-margin-centered has-text-centered">
                <div class="title">404</div>
                <div class="subtitle">Sorry nothing here.</div>
                <router-link class="is-link" :to="{path: '/'}">Home</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
    props: {},
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
