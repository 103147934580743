import _ from 'lodash'
import moment from 'moment'
import auth from '../auth'

export default {
    data() {
        return {
        }
    },
    computed: {
        authenticated() {
            return this.$store.state.authenticated
        },
        authID() {
            return this.$store.state.profile._id
        },
        $profile() {
            return this.$store.state.profile
        },
    },
    methods: {
        signin() {
            this.$router.push({ name: 'login' })
        },
        signout() {
            this.$router.push({ name: 'logout' })
        },
        isAuth() {
            return new Promise((resolve, reject) => {
                if (this.authenticated) {
                    resolve(true)
                }
                else {
                    console.log('*** Error: You are not authenticated. ***');
                    reject();
                }
            })
        },
    }
}
