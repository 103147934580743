<template>
    <div class="erros" v-if="formatedErrors">
        <p class="help has-text-centered is-danger" v-for="error in formatedErrors">{{error}}</p>
    </div>
</template>

<script>
export default {
    name: 'Errors',
    props: {
        errors: {
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
        formatedErrors() {
            return this.errors ? this.parseErrors(this.errors) : null
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
