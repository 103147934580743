<template>
    <section class="section" v-if="isLoaded">

        <modal ref="shopingInfoModal">
            <div class="box content">
                <div class="title is-5 is-text-centered">Wanna buy something?</div>
                <p>
                    I'm still building out the payment side of the store. If you would like to buy something now worries I can ship it your way. Email me and we can figure out payment, Venmo is the easiest. <br><br>Thanks 🤠
                </p>
            </div>
        </modal>

        <div class="container" v-if="isLoaded">
            <div class="columns is-multiline">

                <div class="product-gallery-col">
                    <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link :to="getStoreLink('')">Store</router-link>
                            </li>
                            <li>
                                <router-link :to="getStoreLink('collection/prints')">Prints</router-link>
                            </li>
                        </ul>
                    </nav> -->
                    <product-gallery :images="product.images"></product-gallery>
                </div>

                <div class="product-details-col">

                    <div class="field">
                        <p class="title is-4">{{title}}</p>
                    </div>
                    <!-- <p class="subtitle is-6">{{product.descriptionHtml}}</p> -->
                    <div class="field">
                        <p class="description" v-html="product.descriptionHtml"></p>
                    </div>

                    <!-- <div class="product-details">
                        <ul>
                            <li>{{getProductDimensionString(product)}}</li>
                            <li>{{product.medium}}</li>
                            <template v-if="product.quantity == 1">
                                Orignal
                            </template>
                            <template v-else>
                                Quantity: {{product.totalInventory}}
                            </template>

                        </ul>
                    </div> -->
                    <template v-if="product.variants.length > 1">
                        <div class="field">
                            <div class="control">
                                <div class="select">
                                    <select v-model="selectedVariant">
                                        <option v-for="variant in variants" :key="variant.id" :value="variant">{{variant.title}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="field">
                        <div class="price">{{price | price }}</div>
                    </div>

                    <div class="buttons">

                        <template v-if="!product.availableForSale">
                            <div class="sold-out-button">
                                <button class="button is-rounded" @click="emailMeAboutProduct(product, true)">Sold Out</button>
                                <a @click="emailMeAboutProduct(product, true)" class="is-size-7 notify-me">Notify me</a>
                            </div>

                        </template>
                        <template v-else>
                            <button class="button is-rounded" @click="addProductToCart(selectedVariant)">Add to cart</button>

                            <!--<template v-if="authenticated">
                                {{isProductInCart(product)}}
                                <button v-if="!isProductInCart(product)" class="button is-rounded" @click="addProductToCart(product)">Add to cart</button>
                                <button v-if="isProductInCart(product)" class="button is-rounded" @click="removeProductFromCart(product)">Remove from cart</button>
                            </template>-->

                            <!-- <a @click="openProductHelpModel" class="is-button is-rounded about-buying-button">
                                <span class="icon">
                                    <i class="fa fa-question-circle"></i>
                                </span>
                            </a> -->
                        </template>

                    </div>
                    <div class="social-sharing">
                        <ShareNetwork v-for="network in networks" :network="network" :key="network" :url="shareURL" :title="`Nilrednav | ${product.title}`" :description="product.description" hashtags="drawing, illustraion, prints">
                            <div class="share-link">
                                <icon :icon="`fa-${network}`"></icon>
                            </div>
                        </ShareNetwork>
                    </div>
                    <!-- <social-sharing :url="shareURL" inline-template>
                        <div class="product-share-links">
                            <network network="facebook">
                                <div class="share-link">
                                    <icon icon="fa-facebook"></icon>
                                </div>
                            </network>
                            <network network="twitter">
                                <div class="share-link">
                                    <icon icon="fa-twitter"></icon>
                                </div>
                            </network>
                            <network network="pinterest">
                                <div class="share-link">
                                    <icon icon="fa-pinterest"></icon>
                                </div>
                            </network>
                        </div>
                    </social-sharing> -->

                    <!-- <div class="meta-data">
                        <div class="title is-tiny-text">
                            SKU {{product.id}}
                        </div>
                    </div> -->

                </div>
            </div>

            <!-- edit page button -->
            <!-- <edit-page-button v-if="authenticated" @click="() => {$router.push(`/admin/products/${product._id}`)}"></edit-page-button> -->
            <!-- edit page button -->

        </div>
    </section>
</template>

<script>
import ProductGallery from "@/components/store/ProductGallery";
import { ProductMixins, StoreMixins } from "@/mixins";
export default {
    name: "ProductPage",
    mixins: [ProductMixins, StoreMixins],
    props: {},
    data() {
        return {
            selectedVariant: {},
            isLoaded: false,
            product: null,
            networks: ["pinterest", "facebook", "twitter", "whatsapp"],
        };
    },
    components: {
        ProductGallery,
    },
    methods: {
        openProductHelpModel() {
            this.$refs.shopingInfoModal.open();
        },
    },
    computed: {
        variants() {
            return this.product.variants;
        },
        price() {
            return this.selectedVariant.price;
        },
        title() {
            if (this.product.variants.length > 1) {
                return this.product.title + "-" + this.selectedVariant.title;
            }
            return this.product.title;
        },
        options() {
            console.log(this.product.options);
            const types = ["color"];
            return this.product.options.filter((item) => {
                return types.indexOf(item.name.toLowerCase()) !== -1;
            });
        },
        uid() {
            return this.$route.params.id;
        },
        shareURL() {
            if (process.env.NODE_ENV === "development") {
                return `https://www.nilrednav.com/${this.$route.fullPath}`;
            } else {
                return document.location.href;
            }
        },
    },
    watch: {},
    created() {},
    async mounted() {
        this.product = await this.$shopify.product.fetchByHandle(this.uid);
        this.isLoaded = true;
        this.product.variants.reverse();
        this.selectedVariant = this.variants[0];

        const title = "Nilrednav | " + this.product.title;
        document.getElementsByTagName("title")[0].innerHTML = title;
        this.setMetaAttribute("name=title", title);
        this.setMetaAttribute("name=description", this.product.description);
        this.setMetaAttribute("name=keywords", "drawing, illustration, art, prints");
        this.setSocialMetaAttribute("og:url", document.location);
        this.setSocialMetaAttribute("og:title", title);
        this.setSocialMetaAttribute("og:description", this.product.description);
        this.setSocialMetaAttribute("og:image", this.product.images.length ? this.product.images[0].src : "");

        this.setSocialMetaAttribute("twitter:url", document.location);
        this.setSocialMetaAttribute("twitter:title", title);
        this.setSocialMetaAttribute("twitter:description", this.product.description);
        this.setSocialMetaAttribute("twitter:image", this.product.images.length ? this.product.images[0].src : "");
    },
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";
@import "../../styles/variables";

.price {
    margin-top: 20px;
    margin-bottom: 20px;
}
.social-sharing {
    display: flex;
}
.product-gallery-col {
    margin-right: 30px;
    flex: 1;
    margin-bottom: 20px;
}
.product-details-col {
    flex: 1;
}
@include mobile() {
    .product-gallery-col {
        margin-right: 0;
    }
}
.product-details {
    margin-top: 20px;
    font-family: $heading-font;
}
.about-buying-button {
    border-radius: 50%;
    position: relative;
    top: -5px;
    &:hover {
        background-color: #eee;
    }
    color: #444;
}
.meta-data {
    margin-top: 30px;
    .title {
        color: $light-gray;
    }
}
.breadcrumb {
    font-size: 14px;
    font-family: $heading-font;
}
.sold-out-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .notify-me {
        font-family: $heading-font;
        font-weight: normal;
        text-decoration: underline;
    }
}
.label {
    font-family: $heading-font;
}
.description {
    font-family: $heading-font;
    margin-bottom: 30px;
}
</style>
