<template>
    <div class="main-content home-page" v-if="profile">

        <modal ref="modal"></modal>

        <!-- home page hero -->
        <section class="hero is-fullheight home-hero">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-multiline">
                        <div class="column is-10-desktop">
                            <text-input v-model="profile.intro"
                                textClass="site-intro"
                                placeholder="Hello I am a person and I like to make things."
                                :useRichEditor="true"
                                :isEditable="editPage"
                                @input="saveIntro">
                            </text-input>
                        </div>
                        <div class="column is-12">
                            <div class="home-hero-nav">
                                <page-link v-for="(link, i) in profile.home_links" :url="link.url" :title="link.title" :key="i"></page-link>
                                <page-link v-if="profile.show_email_home" :url="`mailto:${profile.contact_email}`" title="Email me" key="email"></page-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end home page hero -->


        <!-- <loading-page :isLoading="!contentIsLoaded"></loading-page> -->
        <div class="section">
            <div class="container">

                <h1 class="section-title" id="projects">Projects</h1>

                <!-- project list -->
                <post-grid :isEditable="editPage" :query="projectsQuery"></post-grid>
                <!-- end project list -->

                <!-- add content -->
                <div class="columns is-mobile is-multiline" v-if="authenticated">
                    <div class="column is-12 add-content-navbar">
                        <!--<a class="is-link" @click.prevent="() => {$refs.addLinkModal.open()}">
                             <span class="icon">
                                <i class="fa fa-link"></i>
                            </span>
                        </a>
                        <a class="is-link" @click.prevent="() => {$refs.addVideoModal.open()}">
                             <span class="icon">
                                <i class="fa fa-vimeo"></i>
                            </span>
                        </a>-->
                        <a class="is-link is-round-icon-button" @click.prevent="createNewPost">
                             <span class="icon">
                                <i class="fa fa-plus"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <!-- end add content -->

            </div>
        </div>

        <div class="section other-items">
            <div class="container">
                <h1 class="section-title">Other Things I have Made.</h1>
                <div class="columns is-multiline">
                    <ul class="column is-4" v-for="(projects, index) in otherProjects" :key="`col-${index}`">
                        <li v-for="project in projects" :key="project.id">
                            <a href="">{{project}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <edit-page-button :isActive="editPage" @click="() => {editPage = !editPage}"></edit-page-button>
        <!-- edit home page button -->

        <main-footer :social="profile.social"></main-footer>

    </div>
</template>

<script>
import {PageMixins} from '@/mixins';
import PageLink from '@/components/ui/PageLink';
import _ from 'lodash';

export default {
    name: 'IndexPage',
    props: {},
    mixins: [PageMixins],
    data() {
        return {
            editPage: false,
            postsRef: null,
            showEditModal: false,
            projectsQuery: {
                status: 'published',
                select: 'title subtitle image categories',
                with: 'image',
                limit: 100,
                categories: ['project', 'featured']
            }
        }
    },
    components: {
        PageLink
    },
    methods: {
        saveContentOrder(items) {

        },
        openEditPage(item) {
            if (item.isLink) {
                this.$refs.linkModal.open({uid: item['.key']})
            }
            else {
                this.$router.push({path: `/page/${item['.key']}/edit`})
            }
        },
        openPage(item) {
            // we are just a simple link
            if (item.isLink && item.url) {
                window.open(item.url, '_blank');
            }
            else if (item.isVideo && item.videoID) {
                this.$refs.modal.open({videoID: item.videoID, autoplay:true, title: item.title})
            }
            else {
                this.$router.push({path: '/page/'+item.slug})
            }
        },
        toggleEdit() {
            if (this.isEditable) {
                this.saveProfile()
            }
            this.isEditable=!this.isEditable
        },
        handleProfileImage(image) {
            console.log(image);
            this.profile.image = image['.key']
            this.saveProfile()
        },
        saveProfile() {
            var payload = this.parsePayload(this.profile)
            //this.$firebaseRefs.profile.update(payload)
            console.log('Save Profile', payload);
        },
        saveIntro() {
            var payload = {
                intro: this.profile.intro
            }
            this.$api.put('profile', payload).then((e) => {
                console.log('save the profile intro');
            })
        },
        save() {
            if (this.authProfile) {
                this.$db.ref(`users/${this.authProfile.uid}`).update({
                    github: this.parseValue(this.authProfile.github),
                    color: this.parseValue(this.authProfile.color)
                }).then(() => {
                    this.showEditModal = false
                })
            }
        },
    },
    computed: {
        otherProjects() {
            let items = [];
            for (let i = 0; i < 30; i++) {
                items.push({id: i, name: _.random(0, 1000)});                
            }
            return _.chunk(items, 10);
        },
        featuredProjects() {

        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    },
    destroyed() {

    }
}
</script>

<style scoped lang="scss">
@import '~@/styles/mixins';
@import '~@/styles/variables';
.sidebar {
    background: #eee;
}

.edit-button {
    margin-top: 30px;
}

.add-content-navbar {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.other-items {
    margin-bottom: 250px;
    a {
        text-decoration: underline;
    }
}
</style>
