<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="container">
            <router-link class="navbar-item sitename-nav-link" to="/" v-if="isPostPage">
                {{$profile.sitename}}
            </router-link>
        </div>
    </nav>
</template>

<script>
export default {
    name: "SiteNav",
    props: {},
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";
@import "../../styles/variables";
.navbar-item {
    font-family: $heading-font;
}
a.navbar-item:hover {
    background-color: transparent;
    color: rgba($text, 0.4);
}
.navbar {
    background: transparent;
    padding-top: 10px;
}
.sitename-nav-link {
    background-color: red;
    margin: 0 auto;
    max-width: 300px;
    text-align: center;
    &.is-active {
        background-color: transparent;
    }
}
</style>
