<template>
    <transition mode="out-in" enter-active-class="fadeIn" leave-active-class="fadeOut" appear>
        <div class="image-container" :class="{'is-loading': !isLoaded}">
            <img class="animated" :src="src" :srcset="srcSet" ref="img" data-pin-no-hover nopin="nopin" />
        </div>
    </transition>
</template>

<script>
export default {
    name: "GalleryImage",
    props: {
        size: {
            type: String,
            required: true,
        },
        path: {
            type: String,
            required: true,
        },
        crop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    components: {},
    methods: {},
    computed: {
        isCropped() {
            return this.crop ? "-c" : "";
        },
        srcSet() {
            return null;
            // if (this.path) {
            //     var s1 = parseInt(this.size) * 1.5;
            //     var s2 = parseInt(this.size) * 2;
            //     var url = this.imageURL(this.path);
            //     console.log(url);
            //     var c = `${url}?size=s${s1}${this.isCropped} 1x, ${url}?size=${s2}${this.isCropped} 2x`;
            //     return c;
            // }
            // return null;
        },
        src() {
            return this.imageURL(this.path, `s${5}${this.isCropped}`);
        },
        fullResSrc() {
            return this.imageURL(this.path, `s${this.size}${this.isCropped}`);
        },
    },
    watch: {},
    created() {},
    mounted() {
        var image = new Image();
        image.onload = () => {
            this.isLoaded = true;
            this.$refs.img.src = image.src;
        };
        image.src = this.fullResSrc;
    },
};
</script>

<style scoped lang="scss">
.image-container.is-loading {
    width: 100%;
    height: 100%;
    img {
        filter: blur(5px);
    }
}
</style>