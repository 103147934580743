<template>
    <div>

        <!-- loading page  -->
        <loading-page :isLoading="!isLoaded"></loading-page>

        <section class="section" v-if="isLoaded && prints.length">
            <products-view :products="prints" title="Prints"></products-view>
        </section>

        <template v-if="isLoaded">
            <div class="hero is-large store-hero">
                <div class="hero-body" ref="hero" :style="{'background-image': `url(${getShopifyImageURL(collection.image, '100x100')})`}">
                    <div class="container">
                        <div class="columns text-cover-container">
                            <div class="column is-6">
                                <div class="text-wrap">
                                    <div class="title has-text-black-ter is-uppercase"><span>{{collection.title}}</span></div>
                                    <div class="subtitle is-size-7 has-text-black-ter" v-html="collection.descriptionHtml"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="section">
                <products-view :products="collection.products" :uid="collection.id" :title="null"></products-view>
            </section>
        </template>
        <!-- loading page -->

        <!-- <section class="section">
            <div class="container" v-if="isLoaded">
                <products-view :products="originals" title="Originals"></products-view>
            </div>
        </section> -->
    </div>
</template>

<script>
import _ from "lodash";
import { StoreMixins, ProductMixins } from "@/mixins";
import ProductsView from "@/components/store/ProductsView";
export default {
    name: "StoreIndexPage",
    mixins: [StoreMixins, ProductMixins],
    props: {},
    data() {
        return {
            isLoaded: false,
            products: [],
            collection: {},
        };
    },
    components: {
        ProductsView,
    },
    methods: {
        async bindData() {
            try {
                var res = await this.$shopify.collection.fetchByHandle(this.$route.params.id);
                this.$set(this.$data, "collection", res);
            } catch (error) {
                error.map((e) => {
                    console.log(e.message);
                });
            }
            if (this.collection == null) {
                this.$router.replace(this.getStoreLink(""));
                return;
            }
            this.setDefaultMeta({
                ...this.storeMeta,
                ...{
                    title: `Nilrednav | ${this.collection.title}`,
                    image: this.collection.image.src,
                    url: document.location,
                },
            });

            var img = new Image();
            img.onload = () => {
                this.$refs.hero.style["background-image"] = `url(${img.src})`;
            };
            img.src = this.getShopifyImageURL(this.collection.image);

            this.isLoaded = true;
        },
    },
    computed: {
        handle() {
            return this.$route.params.id;
        },
        prints() {
            return _.filter(this.products, (item) => {
                if (item.productType.toLowerCase() === "digital print") {
                    return item;
                }
            }).sort((a, b) => {
                return a.availableForSale || b.availableForSale ? -1 : 1;
            });
        },
        originals() {
            return _.filter(this.products, (item) => {
                if (item.productType.toLowerCase() !== "digital print") {
                    return item;
                }
            }).sort((a, b) => {
                return a.availableForSale || b.availableForSale ? -1 : 1;
            });
        },
        sortedProducts() {
            return this.products;
        },
    },
    watch: {
        handle() {
            this.bindData();
        },
    },
    created() {},
    async mounted() {
        this.bindData();
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.store-hero {
    background-color: #eee;
    .hero-body {
        background-position: 50%;
        background-size: cover;
    }
    .text-cover-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        .text-wrap {
            display: flex;
            flex-direction: column;
            .title,
            .subtitle {
                padding: 5px;
                background-color: $pink-color;
            }
        }
    }
    @include mobile() {
        .hero {
            height: 100vh !important;
        }
        .hero-body {
            height: 50vh;
            .title {
                font-size: 20px;
            }
            .subtitle {
                font-size: 15px;
            }
        }
    }
}
</style>
