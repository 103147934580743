<template>
    <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
            <li :class="{'is-active': link.active}" v-for="link in links" :key="link.name">
                <router-link :to="link.path?link.path:''" aria-current="page" :key="link.path">{{link.name}}</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "BreadCrumbs",
    props: {
        links: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";
@import "../../styles/variables";
li a {
    font-family: $heading-font;
}
</style>
