<template>
    <div :class="classNames">
        <div class="card form-card">
            <header class="card-header">
                <div class="level card-header-title">
                    <div class="level-left">
                        <div class="level-item">
                            {{title}}
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <template v-if="$hasSlot('headerAction')">
                                <slot name="headerAction"></slot>
                            </template>
                        </div>
                    </div>
                </div>
            </header>
            <div class="card-content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormCard",
    props: {
        title: {
            default: null,
        },
        column: {
            default: null,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {
        classNames() {
            let n = {};
            if (this.column) {
                n["column"] = true;
                n[this.column] = true;
            }
            return n;
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.form-card {
    margin-bottom: 30px;
}
.card-header-title {
    font-family: $heading-font;
    width: 100%;
    .level-right {
        margin-top: 0;
    }
    .field .control {
        margin-bottom: 0 !important;
    }
}
</style>
