<template>
    <div class="dropdown is-hoverable">
        <div class="dropdown-trigger">
            <a class="navbar-item" aria-haspopup="true" aria-controls="dropdown-menu">
                <icon size="is-small" icon="fa-th-large"></icon>
            </a>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content ">
                <a class="dropdown-item" :class="{'is-active': value===name}" v-for="(size, name) in sizes" @click="() => { $emit('input', name) }">{{name}}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemSizes',
    props: {
        value: {
            required: true
        },
        sizes: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
