<template>
    <div>
        <draggable v-if="isLoaded" v-model="sortedPosts" classNames="gallery-items columns is-mobile is-multiline main-content-grid" :options="{ghostClass: 'item-ghost'}" :isEditable="isEditable" @end="endSort">
            <!-- is-4-tablet is-2-desktop is-6-mobile -->
            <div class="column item is-2-fullhd is-2-desktop is-3-tablet is-6-mobile" v-for="item in sortedPosts" :key="item._id" @click.stop="gotoPage(item)">
                <figure class="image is-square" :class="{'no-image': !item.image}">

                    <gallery-image v-if="item.image" :path="item.image" size="300" :crop="true"></gallery-image>

                    <div class="edit-controls" v-if="authenticated">
                        <a class="is-link" :class="{'is-active':isEditable}" @click.stop="gotoEditPage(item)">
                            <span class="icon">
                                <i class="fa fa-pencil"></i>
                            </span>
                        </a>
                        <!-- We have this here to open the edit page (not using at the moment) -->
                        <!-- <a class="is-link" :class="{'is-active':isEditable}" @click.stop="gotoEditPage(item)">
                            <span class="icon">
                                <i class="fa fa-upload"></i>
                            </span>
                        </a> -->
                    </div>
                </figure>
                <div class="details">
                    <div class="title" v-html="item.title"></div>
                    <div class="category" v-html="item.subtitle"></div>
                </div>
            </div>
        </draggable>
        <div class="help" v-if="error">
            {{error}}
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { PageMixins } from "@/mixins";
export default {
    name: "PostGrid",
    mixins: [PageMixins],
    props: {
        isEditable: {
            type: Boolean,
            default: false,
        },
        posts: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            results: null,
            postsRef: null,
            error: null,
            isLoaded: true,
        };
    },
    components: {},
    methods: {
        endSort() {
            console.log("end sort");
        },
    },
    computed: {
        sortedPosts: {
            get() {
                if (this.isLoaded) {
                    return _.orderBy(this.posts, ["order"]);
                }
                return [];
            },
            set(posts) {
                for (var i = 0; i < posts.length; i++) {
                    const post = posts[i];
                    post.order = i;
                    this.$db.collection("posts").doc(post.id).update({ order: i });
                }
            },
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";

.item-ghost {
    box-shadow: inset 0 0 0 4px $green-highlight;
    opacity: 0.7;
}
.edit-controls {
    position: absolute;
}
</style>
