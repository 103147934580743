<template>
    <section class="hero is-fullheight is-loading loading-section" v-if="isLoading">
        <div class="hero-body has-text-centered">
            <div class="container">
                <div class="loader-container">
                    <span class="is-loader"></span>
                </div>
                <span class="loading-text" v-if="loadingText">{{loadingText}}</span>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "LoadingPage",
    props: {
        isLoading: {
            default: false,
        },
        loadingText: {
            default: null,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";
@import "../../styles/variables";
.loading-section {
    background-color: $body-background;
    .loader-container {
        @include flexCenter();
    }
    .is-loader {
        @include loader();
        @include square(40px);
    }
    .loading-text {
        font-family: $heading-font;
    }
}
</style>
