<template>
    <section class="section" v-if="$profile">
        <div class="columns is-multiline">

            <div class="column is-half">

                <!-- site name and emal -->
                <div class="box">
                    <div class="field">
                        <label class="label">Sitename</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Site name" v-model="$profile.sitename" @input="updateProfile($event.target.value, 'sitename')">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Store Name</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Store name" v-model="$profile.store_name" @input="updateProfile($event.target.value, 'store_name')">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Contact Email</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Contact email" v-model="$profile.contact_email" @input="updateProfile($event.target.value, 'contact_email')">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Location Name</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Location Name" v-model="$profile.location_name" @input="updateProfile($event.target.value, 'location_name')">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Footer Message</label>
                        <div class="control">
                            <textarea class="textarea" placeholder="Footer Message" v-model="$profile.footer_message" @input="updateProfile($event.target.value, 'footer_message')" />
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Store Footer Message</label>
                        <div class="control">
                            <textarea class="textarea" placeholder="Store Footer Message" v-model="$profile.store_footer_message" @input="updateProfile($event.target.value, 'store_footer_message')" />
                        </div>
                    </div>
                </div>
                <!-- site name and emal -->

                <!-- home page -->
                <div class="box">
                    <label class="label">Home Page Settings</label>
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                <toggle-button v-model="$profile.show_email_home" @change="updateProfile($event.value, 'show_email_home')" :labels="true" />
                                Show email on home page.
                            </label>
                        </div>
                    </div>

                    <!-- home links -->
                    <label class="label">Home page links</label>
                    <draggable v-model="homeLinksOrdered" :isEditable="true">
                        <div class="field has-addons home-links" v-for="(link, i) in homeLinksOrdered" :key="`home-link-${i}`">
                            <p class="control">
                                <input class="input is-small" type="text" v-model="link.title" @input="updateHomeLinks" placeholder="Title">
                            </p>
                            <p class="control">
                                <input class="input is-small" type="text" v-model="link.url" @input="updateHomeLinks" placeholder="URL">
                            </p>
                            <p class="control">
                                <a class="button is-small">
                                    <icon icon="fa-arrows-v"></icon>
                                </a>
                            </p>
                            <p class="control">
                                <a class="button is-small" @click.prevent="removeHomeLink(link, i)">
                                    <icon icon="fa-trash"></icon>
                                </a>
                            </p>
                        </div>
                    </draggable>
                    <div class="field has-addons add-home-link">
                        <p class="control">
                            <input class="input is-small" type="text" v-model="homeLink.url" placeholder="URL">
                        </p>
                        <p class="control">
                            <input class="input is-small" type="text" v-model="homeLink.title" placeholder="Title">
                        </p>
                        <p class="control">
                            <a class="button is-small" :disabled="!homeLink.url || !homeLink.title" @click.prevent="addHomeLink">Add</a>
                        </p>
                    </div>
                    <!-- end home links -->

                </div>
                <!-- home page -->

            </div>

            <!-- social links -->
            <div class="column is-half">
                <div class="box">
                    <label class="label">Social Links</label>
                    <div class="field" v-for="(link, i) in socialLinks" :key="`social-link-${i}`">
                        <div class="control has-icons-right">
                            <input class="input" type="text" :placeholder="link.name" v-model="link.url" @input="updateProfile(socialLinks, 'social')">
                            <span class="icon is-right">
                                <i :class="{'fa':true, [`${link.icon}`]: true}"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- social links -->

            <!-- store social links -->
            <div class="column is-half">
                <div class="box">
                    <label class="label">Store Social Links</label>
                    <div class="field" v-for="(link, i) in storeSocialLinks" :key="`store-social-link-${i}`">
                        <div class="control has-icons-right">
                            <input class="input" type="text" :placeholder="link.name" v-model="link.url" @input="updateProfile(storeSocialLinks, 'store_social')">
                            <span class="icon is-right">
                                <i :class="{'fa':true, [`${link.icon}`]: true}"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- social links -->

            <!-- categories -->
            <div class="column is-half">
                <div class="box">
                    <label class="label">Categories</label>
                    <div class="field has-addons" v-for="(category, i) in $categories" :key="`categorie-${i}`">
                        <div class="control">
                            <input class="input" type="text" v-model="category.name" @input="updateCategory(category)">
                        </div>
                        <p class="control">
                            <a class="button" @click.prevent="removeCategory(category, i)">
                                <icon icon="fa-trash"></icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <!-- end categories -->
        </div>
    </section>
</template>

<script>
import _ from "lodash";
import socialDefaults from "@/social";
export default {
    name: "AdminSettingsPage",
    props: {},
    data() {
        return {
            isMerging: false,
            userProfile: null,
            social: socialDefaults,
            homeLink: {
                url: "",
                title: "",
            },
        };
    },
    components: {},
    methods: {
        updateCategory: _.debounce(async function (category) {
            await this.$db.collection("categories").doc(category.id).update(category);
        }, 400),

        async removeCategory(category) {
            var options = {
                title: "Are you sure?",
                showCancelButton: true,
            };
            await this.$swal(options).then(async (result) => {
                if (result) {
                    await this.$db.collection("categories").doc(category.id).delete();
                }
            });
        },

        // update profile
        // -------------------------------------
        updateProfile: _.debounce(function (value, key) {
            var obj = {};
            obj[key] = value;
            console.log(obj);
            this.$db.doc("profile").update(obj);
        }, 400),

        // Home Links
        // -------------------------------------
        // add a home link
        addHomeLink() {
            // make a link
            var link = this.homeLink;
            link.order = this.$profile.home_links ? this.$profile.home_links.length : 0;

            // update the payload
            var links = this.$profile.home_links || [];
            links.push(link);

            // commit to the store
            this.$store.commit("updateProfile", { home_links: links });

            // update the api
            this.$db.doc("profile").update({ home_links: links });

            // clear the link
            this.homeLink = {
                url: null,
                title: null,
            };
        },

        // remove home link
        removeHomeLink(link, index) {
            // get the links
            var links = this.$profile.home_links || [];

            // remove from list
            links.splice(index, 1);

            // commit to store
            this.$store.commit("updateProfile", { home_links: links });

            // update api
            this.$db.doc("profile").update({ home_links: links });
        },

        // update the home links
        updateHomeLinks: _.debounce(function () {
            // links
            var links = this.$profile.home_links || [];

            // commit to the store
            this.$store.commit("updateProfile", { home_links: links });

            // update the api
            this.$db.doc("profile").update({ home_links: links });
        }, 400),
    },
    computed: {
        socialLinks: {
            get() {
                console.log(socialDefaults);
                const social = [...socialDefaults];
                for (var i = 0; i < social.length; i++) {
                    var index = _.findLastIndex(this.$profile.social, { name: social[i].name });
                    if (index != -1) {
                        social[i] = this.$profile.social[index];
                    }
                }
                return social;
            },
            set(links) {
                console.log("update socla", links);
            },
        },
        storeSocialLinks: {
            get() {
                const social = [...socialDefaults];
                for (var i = 0; i < social.length; i++) {
                    var index = _.findLastIndex(this.$profile.store_social, { name: social[i].name });
                    if (index != -1) {
                        social[i] = this.$profile.store_social[index];
                    }
                }
                return social;
            },
            set(links) {
                console.log("update socla", links);
            },
        },
        homeLinksOrdered: {
            get() {
                return _.orderBy(this.$profile.home_links, ["order"]);
            },
            set(links) {
                for (var i = 0; i < links.length; i++) {
                    links[i].order = i;
                }
                this.$store.commit("updateProfile", { home_links: links });

                this.$db.doc("profile").update({ home_links: links });
            },
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";

.home-links {
    margin-bottom: 5px;
}
.add-home-link {
    margin-top: 10px;
}
</style>
