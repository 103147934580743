export default {
	props: {
		width: {
			default: '90%'
		}
	},
	data() {
		return {
			show: false
		}
	},
	components: {
	},
	methods: {

	},
	computed: {
	},
	watch: {
	},
	created() {
	},
	mounted() {
		document.addEventListener("keydown", (e) => {
			if (this.show && e.code == 27) {
				this.close();
			}
		});
	}
}