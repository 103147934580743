<template>
    <section class="content-section hero" :class="heroClassNames(section)" v-droppable="authenticated" @drop="handleImage($event)">

        <!-- color overlay -->
        <!-- <div class="hero-color-cover" v-if="section.color" :style="{'background-color': toColor(section.color)}"></div> -->

		<div class="content-hero-body hero-body" :style="heroStyle">

            <template v-if="isLoadingImage">
                <div class="loading-image">
                    <i class="fa fa-refresh fa-spin fa-2x"></i>
                    <span class="sr-only">Loading...</span>
                </div>
            </template>

            <!-- video -->
            <video-player class="hero-video" v-if="section.video" :id="section.video"></video-player>
            <!-- video -->

            <!-- hero gui -->
            <div class="page-gui hero-gui" v-if="isEditable">
                <nav class="navbar gui-controls">

                    <!-- hero size -->
                    <div class="dropdown is-hoverable">
                        <div class="dropdown-trigger">
                            <a class="navbar-item" aria-haspopup="true" aria-controls="dropdown-menu">
                                <icon icon="fa-th-large" size="is-small"></icon>
                            </a>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content ">
                                <a class="dropdown-item" v-for="(size, name) in heroSizes" :key="name" @click="update({size: name})">{{name}}</a>
                            </div>
                        </div>
                    </div>

                    <!-- set hero image -->
                    <a class="navbar-item" v-if="!section.image">
                        <icon icon="fa-image" size="is-small"></icon>
                        <input type="file" accept="image/*" :multiple="false" name="image" @change="handleImage">
                    </a>

                    <!-- if we have hero image -->
                    <div class="dropdown is-hoverable" v-if="section.image">
                        <div class="dropdown-trigger">
                            <a class="navbar-item" :class="{'is-active': section.image}" aria-haspopup="true" aria-controls="dropdown-menu">
                                <icon icon="fa-image" size="is-small"></icon>
                            </a>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content ">
                                <a class="dropdown-item is-icon-button" @click.prevent="removeHeroImage">
                                    <icon icon="fa-trash" size="is-small"></icon>
                                </a>
                                <a class="dropdown-item is-icon-button">
                                    <icon icon="fa-upload" size="is-small"></icon>
                                    <input type="file" accept="image/*" :multiple="false" name="image" @change="handleImage">
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- if we have hero image -->

                    <!-- move up section -->
                    <a class="navbar-item" @click.prevent="moveSection(section.uid, -1)">
                        <icon icon="fa-arrow-up" size="is-small"></icon>
                    </a>

                    <!-- move down section -->
                    <a class="navbar-item" @click.prevent="moveSection(section.uid, 1)">
                        <icon icon="fa-arrow-down" size="is-small"></icon>
                    </a>

                    <!-- title color class -->
                    <div class="dropdown is-hoverable" v-if="useTitle">
                        <div class="dropdown-trigger">
                            <a class="navbar-item" :class="{'is-active': section.title_class}" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span class="icon is-small">
                                    H1
                                </span>
                            </a>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content ">
                                <a class="dropdown-item" v-for="(size, name) in titleClasses" :key="name" @click.prevent="update({title_class:name})">{{name}}</a>
                            </div>
                        </div>
                    </div>

                    <!-- Add Text -->
                    <a class="navbar-item" :class="{'is-active': section.has_text}" @click.prevent="update({has_text: !section.has_text})">
                        <icon icon="fa-font" size="is-small"></icon>
                    </a>

                    <!-- color picker dropdown -->
                    <div class="dropdown is-hoverable">
                        <div class="dropdown-trigger">
                            <a class="navbar-item" aria-haspopup="true" aria-controls="dropdown-menu">
                                <icon icon="fa-paint-brush" size="is-small"></icon>
                            </a>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content ">
                                <color-picker v-model="heroColor" @input="updateHeroColor"></color-picker>
                                <button class="color-clear-button vc-sketch-presets-color" @click.prevent="update({color: null})">
                                	<span class="icon is-small">
                                        <i class="fa fa-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- video gui -->
                    <div class="video-gui">
                        <div class="field">
                            <p class="control">
                                <input class="input" type="text" v-model="section.video" placeholder="Vimeo ID" v-keyupDebounce="updateVideo">
                            </p>
                        </div>
                    </div>

                    <!-- remove hero -->
                    <a class="navbar-item" @click.prevent="()=>{ $emit('removeSection', section.uid) }">
                        <icon icon="fa-trash" size="is-small"></icon>
                    </a>

                </nav>
            </div>
            <!-- hero gui -->

            <template v-if="section.has_text">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <text-input class="hero-text" v-model="section.text" :isEditable="isEditable" :useRichEditor="true" @input="update({text: section.text})"></text-input>
                        </div>
                    </div>
                </div>
            </template>

            <!-- title subtitle -->
            <!--<div class="container" v-if="useTitle">
                <div class="columns">
                    <div class="column is-4">
                        <template v-if="isEditable">
                            <text-input class="hero-title-edit title" placeholder="Big Title" v-model="section.title" :isEditable="isEditable" :useRichEditor="false" @input="update({title:section.title})"></text-input>
                            <text-input class="hero-title-edit subtitle" placeholder="You can say something here as well" v-model="section.subtitle" :isEditable="isEditable" :useRichEditor="true" @input="update({title:section.subtitle})"></text-input>
                        </template>

                        <template v-if="!isEditable">
                            <h1 class="title" v-html="section.title"></h1>
                            <h2 class="subtitle" v-html="section.subtitle"></h2>
                        </template>
                    </div>
                </div>
            </div>-->
            <!-- title subtitle -->

        </div>

    </section>
</template>

<script>
import _ from 'lodash'
import {Sketch as ColorPicker} from 'vue-color'
import contentSizes from '../../content-sizes'
import {ImageUploaderMixins} from '@/mixins'
export default {
    name: 'Hero',
    mixins: [ImageUploaderMixins],
    props: {
        useTitle: {
            type: Boolean,
            default: true
        },
        uid: {
            type: String,
            required: true
        },
    	isEditable: {
            type: Boolean,
    		default: false
    	},
    	section: {
            type: Object,
    		required: true
    	}
    },
    data() {
        return {
        	titleClasses: {
                'none': null,
                'dark': 'is-hero-dark',
                'light': 'is-hero-light',
            },
            isLoadingImage: false,
			heroSizes: contentSizes.hero,
            itemSizes: contentSizes.item,
            sectionSizes: contentSizes.section,
        }
    },
    components: {
        ColorPicker
    },
    methods: {
        updateVideo() {
            this.update({video: this.section.video})
        },
        updateHeroColor(e) {
            console.log(e);
        },
        moveSection(key, dir) {
            this.$emit('moveSection', {key, dir})
        },
        saveColorValue(e) {
            console.log(e);
        },
        update: _.debounce(function(payload) {
            var data = {
                key: this.section.uid,
                payload: payload
            }
            this.$emit('update', data)
        }, 200),
    	setHeroSize(size) {
            this.section.size = size
        },
        setHeroTitleClass(section, size) {
            section.titleClass = size
        },
    	columnSize(size, sizes) {
            return sizes[size] || 'error-in-size'
        },
        titleClass(name) {
            return this.titleClasses[name] || ''
        },
    	heroClassNames() {
            return {
                'is-editable':this.isEditable,
                'has-video':this.section.video,
                [`${this.columnSize(this.section.size, this.heroSizes)}`]: true,
                [`${this.titleClass(this.section.title_class)}`]: true,
            }
        },
        async removeHeroImage() {
            if (this.section.image) {
                try {
                    await this.removeImage(this.section.image)    
                } catch (error) {
                    console.log(error);
                }
                this.update({image: null})
            }
        },
        async handleImage(e) {
            this.isLoadingImage = true;
            let file = await this.hasSingleFile(e)
            var filename;
            try {
                filename = await this.uploadImage(file);
            } catch (error) {
                console.log('uploading hero error', error);
            }   
            this.update({image: filename})
            this.isLoadingImage = false
        },
    },
    computed: {
    	heroStyle() {
            var image = this.section.image
            var url = this.servingImage(image);
            var style = {}

            if (this.section.color) {
                style['background-color'] = this.toColor(this.section.color)
            }
            if (this.section.image) {
                style['background-image'] = `url(${this.imageURL(image)})`
                style['background-size'] = 'cover'
            }
            return style
    	},
        heroColor: {
            get() {
                if (!this.section.color) {
                    return {r:0, g: 0, b: 0, a: 0.5}
                }
                return this.section.color
            },
            set(color) {
                this.section.color = color.rgba
                this.update({color: this.section.color})
                //this.$emit('heroColor', color.rgba)
            }
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.hero-video {
    position: relative;
    @include mobile() {
        display: inherit;
    }
}
.hero.is-drag-over {
    .hero-body {
        @include edit-border($green-highlight);
    }
}
.content-section.hero {
    position: relative;
    margin-bottom: 3rem;

    .hero-body {
        min-height: 300px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    &.has-video .hero-body {
        padding: 0;

    }

    .hero-color-cover {
        z-index: 300;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        pointer-events: none;
    }
}
.hero.is-hero-dark {
    color: lighten($text, 90);
}
.loading-image {
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.64);
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: $white-color;
}
.content-section.is-editable {
    @include edit-border($light-gray);
    &:hover {
        @include edit-border($green-highlight);
    }
}
.hero-text {
    min-width: 300px;
}
</style>
