import _ from 'lodash'
import toMarkdown from 'to-markdown'
import showdown from 'showdown'
import striptags from 'striptags'

showdown.setFlavor('github');
var converter = new showdown.Converter()

export default {
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {

		$findIndexFromId(arr, id) {
			return _.findLastIndex(arr, { _id: id });
		},
		$findFromId(arr, id) {
			var index = _.findLastIndex(arr, { _id: id });
			return index > -1 ? arr[index] : null
		},
		$findFromUID(arr, uid) {
			var index = _.findLastIndex(arr, { uid: uid });
			return index > -1 ? arr[index] : null
		},
		$removeWithId(arr, id) {
			var index = this.$findIndexFromId(arr, id);
			if (index > -1) {
				this.$delete(arr, index)
				return true
			}
			return false;
		},
		$hasSlot(name) {
			return Object.keys(this.$slots).indexOf(name) != -1
		},

		stripHTML(text) {
			return striptags(text);
		},

		// helper for firebase values
		parseValue(val) {
			return val || ""
		},

		parsePayload(obj) {
			var payload = _.cloneDeep(obj)
			delete payload['.key']
			delete payload['.value']
			delete payload['_id']
			return payload
		},
		toHTML(md) {
			return md ? converter.makeHtml(md) : null
		},
		toMD(html) {
			return html ? toMarkdown(html) : null
		},
		toColor(obj) {
			if (obj.r) {
				return `rgba(${obj.r}, ${obj.g}, ${obj.b}, ${obj.a})`
			}
			return obj.rgba ? `rgba(${obj.rgba.r}, ${obj.rgba.g}, ${obj.rgba.b}, ${obj.rgba.a})` : null
		}
	}
}
