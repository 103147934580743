import { render, staticRenderFns } from "./PageGallery.vue?vue&type=template&id=91e98f7a&scoped=true&"
import script from "./PageGallery.vue?vue&type=script&lang=js&"
export * from "./PageGallery.vue?vue&type=script&lang=js&"
import style0 from "./PageGallery.vue?vue&type=style&index=0&id=91e98f7a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91e98f7a",
  null
  
)

export default component.exports