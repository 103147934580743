<template>
    <section class="section">
        <div class="container">
            <div class="columns is-multiline">
                <div class="column is-full">
                    <nav class="breadcrumb is-small" aria-label="breadcrumbs">
                        <ul>
                            <li><a href="#">Cart</a></li>
                            <li><a href="#">Shipping information</a></li>
                            <li><a href="#">Payment</a></li>
                        </ul>
                    </nav>
                    <!-- Cart  Customer information  Shipping method  Payment method -->

                </div>
                <div class="column is-half">
                    <div class="store form">
                        <button id="customButton">Purchase</button>

                        <!-- contact information -->
                        <div class="content">
                            <h1 class="title is-size-6">Contact information</h1>
                            <div class="field">
                                <input-field autocomplete="email" label="Email"></input-field>
                            </div>
                        </div>
                        <!-- end contact information -->

                        <!-- shipping information -->
                        <div class="content">
                            <h1 class="title is-size-6">Shipping information</h1>
                            <div class="field is-grouped">
                                <input-field class="is-expanded" type="text" label="Firstname" required autocomplete="given-name"></input-field>
                                <input-field class="is-expanded" type="text" label="Lastname" required autocomplete="family-name"></input-field>
                            </div>
                            <div class="field">
                                <input-field type="text" label="Address" required autocomplete="street-address"></input-field>
                            </div>
                            <div class="field">
                                <input-field type="text" label="City" required autocomplete="address-level2"></input-field>
                            </div>
                            <div class="field is-grouped">
                                <div class="control is-expanded">
                                    <label class="label">Country</label>
                                    <div class="select">
                                        <select class="country-select" name="country" v-model="shipping.country_code" required autocomplete="country">
                                            <option :value="null">Country</option>
                                            <option v-for="country in countries" :value="country.code" :key="country.code">{{country.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <input-field type="text" label="Postal Code" required autocomplete="shipping postal-code"></input-field>
                            </div>
                            <div class="field">
                                <input-field type="text" label="Phone (optional)" required autocomplete="tel"></input-field>
                            </div>
                        </div>
                        <!-- end shipping information -->

                        <div class="content">
                            <h1 class="title is-size-6">Payment</h1>
                            <div class="field">
                                <div class="control">
                                    <div id="card-element">
                                      <!-- a Stripe Element will be inserted here. -->
                                    </div>

                                    <!-- Used to display form errors -->
                                    <div id="card-errors" role="alert"></div>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <input class="input" type="text" placeholder="4444 4444 4444 4444" v-mask="'#### #### #### ####'" v-model="payment.cc" required autocomplete="cc-number">
                                </div>
                            </div>
                            <div class="field is-grouped">
                                <div class="control">
                                    <input class="input" type="text" placeholder="11/18" v-mask="'##/##'" v-model="payment.expiration_date" required autocomplete="cc-exp">
                                </div>
                                <div class="control">
                                    <input class="input" type="text" placeholder="CVV" required autocomplete="cc-csc">
                                </div>
                            </div>
                        </div>

                        <!-- billing information -->
                        <div class="content">
                            <h1 class="title is-size-6">Billing</h1>
                            <div class="field is-grouped">
                                <div class="control is-expanded">
                                    <input class="input" type="text" placeholder="Firstname" required autocomplete="given-name">
                                </div>
                                <div class="control is-expanded">
                                    <input class="input" type="text" placeholder="Lastname" required autocomplete="family-name">
                                </div>
                            </div>
                            <div class="field is-grouped">
                                <div class="control is-expanded">
                                    <input class="input" type="text" placeholder="Address" required autocomplete="email">
                                </div>
                                <div class="control">
                                    <input class="input" type="text" placeholder="Apt, suite, etc. (optional)" required autocomplete="shipping street-address">
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <input class="input" type="text" placeholder="City" required autocomplete="shipping locality">
                                </div>
                            </div>
                            <div class="field is-grouped">
                                <div class="control is-expanded">
                                    <div class="select">
                                        <select class="country-select" name="country" v-model="shipping.country_code" required autocomplete="shipping country">
                                            <option :value="null">Country</option>
                                            <option v-for="country in countries" :value="country.code" :key="country.code">{{country.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <input class="input" type="text" placeholder="Postal Code" required autocomplete="shipping postal-code">
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <input class="input" type="text" placeholder="Phone (optional)" required autocomplete="tel">
                                </div>
                            </div>
                        </div>
                        <!-- end billing information -->


                    </div>
                </div>
                <div class="column is-half cart">
                    <pre>
{{shipping}}
                    </pre>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import countries from '@/countries-list'
import StoreInputField from "@/components/store/StoreInputField";
export default {
    name: 'CheckoutPage',
    props: {},
    data() {
        return {
            countries: countries,

            shipping: {
                firstname: null,
                lastname: null,
                address: null,
                zipcode: null,
                city: null,
                country_code: 'US',
                phone: null,
            },

            payment: {
                cc: null,
                expiration_date: null
            }
        }
    },
    components: {
        'input-field': StoreInputField
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">
.country-select {
    width: 100%;
}
.cart {
    background-color: beige;
}

</style>
