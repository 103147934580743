<template>
    <div>
        <template v-if="isEditable">
            <editor ref="editor" :text="value" :class="{'text-editor': true, [textClass]: true, 'is-editable': isEditable}" :options="options" v-on:edit='updateRichText'></editor>
        </template>
        <template v-else>
            <div v-if="showPlainText" :class="textClass" v-html="value">{{value}}</div>        
        </template>        
    </div>
</template>

<script>
const editorOptions = {
    
}
import Editor from 'vue2-medium-editor'
export default {
    name: 'TextInput',
    props: {
        save: {
            type: Function,
            default: () => {}
        },
    	value: {
    		type: String,
    		default: ''
    	},
    	isEditable: {
            default: false
        },
        showPlainText: {
    		default: true
    	},
    	textareaClass: {
    		default: ''
    	},
    	useRichEditor: {
            default: false
        },
        forcePlainText: {
    		default: false
    	},
    	textClass: {
            default: ''
        },
    	placeholder: {default: 'Enter something here.'}
    },
    data() {
        return {
            timeout: null,
        	editorOptions: editorOptions
        }
    },
    components: {
        Editor,
    },
    methods: {
        subscribe() {
            if (this.$refs.editor) {
                this.$refs.editor.api.subscribe('blur', () => {
                    this.$emit('blur', this)
                })
            }
        },
    	updateText(e) {
    		var text = e.target.innerHTML
    		this.$emit('input', text)
    	},
    	updateRichText(e) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
        		var el = e.event.target ? e.event.target : e.api.origElements
        		if (el) {
    				var text = el.innerHTML
                    // does not work
                    if(this.forcePlainText) {
                        // text = el.innerText
                    }
        			this.$emit('input', text)
        		}
            }, 500)
            //this.$emit('changed', this);
    	},
        shouldSave(e) {
            console.log('shouldSave', e);
        }
    },
    computed: {
        compName() {
            return this.isEditable ? 'TextEditor' : 'TextElement'
        },
        toolbar() {
            if(!this.useRichEditor) return false;
            return {
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'h1',
                    'h2',
                    'h3',
                    'anchor',
                    'removeFormat',
                    'html'
                ]
            }
        },
        options() {
            return {
                extensions: {
                    imageDragging: {}
                },
                forcePlainText: this.forcePlainText,
                imageDragging: false,
                buttonLabels: 'fontawesome',
                placeholder: {
                    text: this.placeholder
                },
                toolbar: this.toolbar,
                anchor: {
                    targetCheckbox: true,
                    targetCheckboxText: 'Open in new window'
                }
            }
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
        this.subscribe()
    }
}
</script>

<style lang="scss">
@import '../../styles/variables';
@import '../../styles/mixins';
.text-editor {
    margin: 0; 
    &:focus {
        outline: none;
    }
    &.is-editable {
        padding: 10px; 
        background-color: $textedit-background-color;
        margin-bottom: 10px;
        &:hover {
            @include edit-border($green-highlight);
        }
    }
}

</style>