import moment from 'moment'
import _ from 'lodash'
import firebase from 'firebase'
import shortid from 'shortid'
import db from '../db'

var IMAGE_SERVING_URL = process.env.IMAGE_SERVING_URL

export default {
	methods: {

		// dropping images on section
		// -------------------------------------
		handleFilesEvent(e) {
			return new Promise((resolve, reject) => {

				// file dropped!
				if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
					var output = []
					for (var i = 0; i < e.dataTransfer.files.length; i++) {
						if (e.dataTransfer.files[i].type.indexOf('image') !== -1) {
							output.push(e.dataTransfer.files[i])
						} else {
							console.log('Invalid file drop', e.dataTransfer.files[i]);
						}
					}
					resolve(output)
				}

				// just a normal file upload
				else if (e.target.files && e.target.files.length) {
					resolve(e.target.files)
				}

				// else ...
				else if (e.dataTransfer.items && e.dataTransfer.items.length) {
					var onePerList = false; // just a safty
					for (var i = 0; i < e.dataTransfer.items.length; i++) {
						if (e.dataTransfer.items[i].type === 'text/uri-list' && !onePerList) {
							onePerList = true
							e.dataTransfer.items[i].getAsString((url) => {
								console.log('image drop', url);
								this.getProxyImage(url).then((blob) => {
									resolve([blob])
								})
							})
						}
					}
				}

			})
		},

		getFileExtensionFromType(file) {
			return file.type.substring(file.type.lastIndexOf('/') + 1);
		},

		// read a file
		// ------------------------------------------------------------------------
		readFile(f, pack) {
			pack = pack || true
			return new Promise((resolve, reject) => {
				var reader = new FileReader();
				reader.onload = function (e) {
					pack ? resolve({ file: f, src: e.target.result }) : resolve(e.target.result)
				}
				reader.readAsDataURL(f);
			})
		},

		// ------------------------------------------------------------------------
		hasFiles(e) {
			return this.handleFilesEvent(e)
		},

		// ------------------------------------------------------------------------
		hasSingleFile(e) {
			return new Promise((resolve, reject) => {
				this.handleFilesEvent(e).then((files) => {
					if (files && files.length) {
						resolve(files[0])
					} else {
						reject(null)
					}
				})
			})
		},

		// ------------------------------------------------------------------------
		getProxyImage(url) {
			return new Promise((resolve, reject) => {
				var params = {
					responseType: 'blob'
				}
				this.$http.get(`${IMAGE_SERVING_URL}/proxy?url=${url}`, params).then((response) => {
					if (response.data) {
						response.data.source = url
						resolve(response.data)
					}
				})
			})
		},

		// ------------------------------------------------------------------------
		async removeImage(image) {
			console.log('removeImage', image);
			try {
				await firebase.storage().ref().child(image).delete()
				await firebase.doc('images/' + image).delete()
			}
			catch (error) {
				console.log('Error removing image', error);
			}
		},

		// ------------------------------------------------------------------------
		async uploadImage(file, cb) {

			var ext = this.getFileExtensionFromType(file) || 'jpg'
			const filename = `${shortid()}.${ext}`;

			const ref = firebase.storage().ref();
			const child = ref.child(filename)
			var uploadTask = child.put(file);

			return new Promise((resolve, reject) => {

				uploadTask.on('state_changed', (snapshot) => {
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log(progress + '% done');
					if (cb) {
						cb(progress)
					}
					// switch (snapshot.state) {
					// 	case firebase.storage.TaskState.PAUSED: // or 'paused'
					// 		console.log('Upload is paused');
					// 		break;
					// 	case firebase.storage.TaskState.RUNNING: // or 'running'
					// 		console.log('Upload is running');
					// 		break;
					// }
				}, (error) => {
					console.log(error);
					this.isLoadingThumbnail = false;
				}, () => {
					uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
						if (cb) {
							cb(100)
						}
						resolve(filename)
					});
				});
			});

			// var removeProm = new Promise((resolve, reject) => {
			//     if(this.page.image) {
			//         this.removeImage(this.page.image).then(resolve)
			//     } else {
			//         resolve()
			//     }
			// })
			// removeProm.then(() => {
			//     var fd = this.objectToFormData({
			//         content_type: 'Post',
			//         content_id: this.page._id,
			//         file: file,
			//     })
			//     this.uploadImage(fd).then((resp) => {
			//         if(resp.error) {
			//             console.log('page thumbnail error', err);
			//         } else {
			//             this.page.image = resp.image
			//             this.save();
			//         }
			//         this.isLoadingThumbnail = false;

			//     }).catch((err) => {
			//         this.isLoadingThumbnail = false;
			//         console.log('page thumbnail error', err);
			//     })
			// })


			// return new Promise((resolve, reject) => {
			// 	// this.isAuth().then(() => {
			// 	// 	this.$api.post('assets/upload', fd, {
			// 	// 		headers: {
			// 	// 			'Content-Type': 'multipart/form-data'
			// 	// 		}
			// 	// 	}).then((e) => {
			// 	// 		var resp = {
			// 	// 			image: null,
			// 	// 			error: null,
			// 	// 			data: cb_data
			// 	// 		}
			// 	// 		if (e.data.error) {
			// 	// 			resp['error'] = e.data.error;
			// 	// 		}
			// 	// 		else {
			// 	// 			resp['image'] = e.data;
			// 	// 		}
			// 	// 		resolve(resp)

			// 	// 	})
			// 	// }).catch((err) => {
			// 	// 	reject(err)
			// 	// })
			// })
		}
	}
}
