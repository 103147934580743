<template>
    <div class="buttons">
        <a @click="() => {$emit('duplicate')}" class="button">
            <icon icon="fa-copy"></icon>
        </a>
        <a @click="() => {$emit('edit')}" class="button">
            <icon icon="fa-pencil"></icon>
        </a>
        <a @click="() => {$emit('view')}" class="button">
            <icon icon="fa-eye"></icon>
        </a>
        <a @click="() => {$emit('trash')}" class="button is-danger">
            <icon icon="fa-trash"></icon>
        </a>
    </div>
</template>

<script>
export default {
    name: "ListControls",
    props: {},
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
</style>
