import _ from 'lodash'
import Client from 'shopify-buy';
const storeMeta = require('../../store-meta')

const client = Client.buildClient({
	domain: 'vanderlin.myshopify.com',
	storefrontAccessToken: '022a0859542204b3539643e14b84d7f2'
});
export default {
	data() {
		return {
		}
	},
	computed: {
		storeMeta() {
			return storeMeta;
		},
		$shopify() {
			return client
		},
		totalProductsInCart() {
			return this.$store.state.checkout.lineItems ? this.$store.state.checkout.lineItems.length : 0;
		},
		isCartEmpty() {
			return this.totalProductsInCart == 0;
		},
		checkout() {
			return this.$store.state.checkout;
		},
		cartTotal() {
			var t = 0;
			for (var i = 0; i < this.checkout.lineItems.length; i++) {
				const item = this.checkout.lineItems[i]
				t += parseFloat(item.variant.priceV2.amount)
			}
			return t
		}
	},
	methods: {
		getStoreLink(path) {
			return this.$isStoreRoot ? `/${path}` : `/store/${path}`
		},
		async addProductToCart(product) {
			const checkoutID = this.$store.state.checkout.id;

			try {
				var res = await this.$shopify.checkout.addLineItems(checkoutID, [
					{
						variantId: product.id,
						quantity: 1,
					}
				])
			} catch (error) {
				console.log(error);
			}
			console.log('askdhk');
			this.$store.commit('checkout', res)
			this.$store.commit('showCartPopover', true)
		},
		removeProductFromCart(product) {
			console.log('Removing ' + product.sku + ' from cart.');
			this.$store.commit('removeProductFromCart', product)
		},
		isProductInCart(product) {
			return _.findIndex(this.cart, { _id: product._id }) > -1
		},
		getShopifyImageURL(image, size) {
			var sizeStr = size ? '_' + size : ''
			var url = new URL(image.src)
			var pathParts = url.pathname.split('.')
			var respPath = `${pathParts[0]}${sizeStr}.${pathParts[1]}`
			return `${url.origin}${respPath}`
		}
	}
}
