<template>
<div class="caption">
    <figcaption v-if="!isEditable" class="has-text-centered" :class="{'is-editable':isEditable}" v-html="value"></figcaption>
    <text-input v-bind:value="value" 
        :isEditable="isEditable" 
        :useRichEditor="true"
        :buttons="['bold']"
        :showPlainText="false"
        @input="updateCaption"
        class="caption-text-editor"
        placeholder="Type caption for image (optional)">
    </text-input>
</div>
</template>

<script>
export default {
    name: 'ItemCaption',
    props: {
        value: {
            required:true
        },
        isEditable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        updateCaption(e) {
            this.$emit('input', e)
        }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
@import '../../styles/variables';
@import '../../styles/mixins';
.caption {
}
.caption-text-editor {

}

figcaption {
    font-style: italic;
}
figcaption.is-editable {
    &[placeholder]:empty:before {
        content: attr(placeholder);
        color: #555; 
    }
    padding: 10px;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &:hover {
        @include edit-border($green-highlight);
    } 
}
</style>