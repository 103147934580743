<template>
    <div>
        <template v-if="!dropdown">
            <div class="add-items-links">
                <a class="is-link" @click.prevent="itemSelected($event, 'text')">
                    <icon size="is-small" icon="fa-bars" text="Text"></icon>
                </a>
                <a class="is-link has-file">
                    <icon size="is-small" icon="fa-image" text="Image"></icon>
                    <input type="file" accept="image/*" multiple="false" name="image" @change="itemSelected($event, 'image')">
                </a>
                <a class="is-link" @click.prevent="itemSelected($event, 'video')">
                    <icon size="is-small" icon="fa-vimeo" text="Video"></icon>
                </a>
            </div>
        </template>

        <template v-if="dropdown">
            <div class="dropdown is-hoverable add-item">
                <div class="navbar-item dropdown-trigger">
                    <icon size="is-small" icon="fa-plus"></icon>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content ">
                        <a class="dropdown-item" @click.prevent="itemSelected($event, 'text')">
                            <icon size="is-small" icon="fa-bars"></icon>
                        </a>
                        <a class="dropdown-item image-button">
                            <icon size="is-small" icon="fa-photo"></icon>
                            <input type="file" accept="image/*" multiple="false" name="image" @change="itemSelected($event, 'image')">
                        </a>
                        <a class="dropdown-item" @click.prevent="itemSelected($event, 'video')">
                            <icon size="is-small" icon="fa-vimeo"></icon>
                        </a>
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
export default {
    name: "AddItem",
    props: {
        dropdown: {
            default: true,
        },
        sectionKey: {
            type: String,
            required: true,
        },
        itemsCount: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {
        itemSelected(e, type) {
            const event = {
                sectionKey: this.sectionKey,
                type: type,
                event: e,
                order: this.itemsCount + 1,
            };
            this.$emit("addItem", event);
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";
@import "../../styles/mixins";
.add-items-links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    a {
        color: $dark-gray;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        font-family: $heading-font;
        &:hover {
            color: $text;
        }
    }
}
</style>