<template>
    <div class="player">

        <iframe v-if="hasVideo" :src="videoURL" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

        <div v-if="!hasVideo" class="no-video">
            <div class="video-id-gui">
                <div class="field">
                    <p class="control">
                        <input type="text" name="" class="input" v-keyupDebounce="updateID" :value="id" placeholder="Enter a Vimeo ID">
                    </p>
                    <p class="help has-text-centered">No Video</p>
                </div>
                <!--<div class="field has-addons">
                    <p class="control">
                        <input class="input" v-bind:value="id" placeholder="Enter a video id" type="text" name="">
                    </p>
                    <p class="control">
                        <button class="button" @click="() => {$emit('setVideoID', id)}">
                            <icon icon="fa-check" size="is-small"></icon>
                        </button>
                    </p>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoPlayer",
    props: {
        id: {
            required: true,
        },
        autoplay: {
            default: false,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {
        // from here:
        // https://gist.github.com/lekkerduidelijk/ff3d25fe00956787a42220533f94511b
        parseVideo(url) {
            // - Supported YouTube URL formats:
            //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
            //   - http://youtu.be/My2FRPA3Gf8
            //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
            // - Supported Vimeo URL formats:
            //   - http://vimeo.com/25451551
            //   - http://player.vimeo.com/video/25451551
            // - Also supports relative URLs:
            //   - //player.vimeo.com/video/25451551

            url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/);
            var type;
            if (RegExp.$3.indexOf("youtu") > -1) {
                type = "youtube";
            } else if (RegExp.$3.indexOf("vimeo") > -1) {
                type = "vimeo";
            }

            return {
                type: type,
                id: RegExp.$6,
            };
        },
        updateID(id) {
            this.$emit("input", id);
            this.$emit("updateVideoID", id);
        },
    },
    computed: {
        hasVideo() {
            if (!this.id) {
                return false;
            }
            var data = this.parseVideo(this.id);
            return data.id != "";
        },
        videoURL() {
            var data = this.parseVideo(this.id);
            switch (data.type) {
                case "youtube":
                    return `http://www.youtube.com/embed/${data.id}?enablejsapi=1`;
                case "vimeo":
                    return `https://player.vimeo.com/video/${data.id}?title=0&byline=0&portrait=0&autoplay=${this.autoplay ? "1" : "0"}`;
            }
            return null;
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";
@import "../../styles/mixins";
.no-video {
    position: relative;
    min-height: 250px;
    width: 100%;
    background-color: $light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: italic;
    input,
    button {
        box-shadow: none;
        background-color: darken($light-gray, 5);
        padding: 20px;
        border: none;
        color: white;
    }
}
iframe {
    background-color: #000;
    padding: 0;
    margin: 0;
    width: 100%;

    @include mobile() {
        min-height: 240px;
    }
    @include tablet() {
        min-height: 340px;
    }
    @include desktop() {
        min-height: 500px;
    }

    position: relative;
}
.player {
    width: 100%;
    display: flex;
}
</style>
