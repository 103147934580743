<template>
    <section class="section">
        <div class="container">
            <!-- heading -->
            <div class="columns is-multiline cart-items" v-if="!isCartEmpty">
                <div class="column cart-headings" :class="colSize">
                    <div class="cart-heading-left">
                        <div class="cart-heading cart-title">Item</div>
                    </div>
                    <div class="cart-heading-right">
                        <div class="cart-heading cart-quanity">Qty.</div>
                        <div class="cart-heading">Price</div>
                    </div>
                </div>
            </div>
            <!-- heading -->

            <!-- items -->
            <transition-group enter-active-class="fadeIn" leave-active-class="fadeOut" tag="div" class="columns is-multiline cart-items">
                <div class="column animated cart-item" :class="colSize" v-for="item in lineItems" :key="item.id">
                    <div class="product">
                        <div class="product-level product-left">
                            <a class="button is-remove-button is-remove-button-left" @click.prevent="removeItemFromCart(item)">
                                <span class="icon">
                                    <i class="fa fa-remove"></i>
                                </span>
                            </a>

                            <router-link :to="itemURL(item)">
                                <figure class="image" :style="{'background-image': `url(${getProductImage(item)})`}">
                                </figure>
                            </router-link>

                            <div class="product-text product-title">
                                <router-link :to="itemURL(item)">
                                    {{item.title}}
                                </router-link>
                                <template v-if="item.variant.selectedOptions.length>1">
                                    <span v-for="option in item.variant.selectedOptions" :key="`opt-${option.value}`">
                                        {{option.value}}
                                    </span>
                                </template>

                                <div class="is-light-gray is-tiny-text">{{item.variant.sku}}</div>
                                <a class="is-tiny-text title is-remove-button-bottom" @click.prevent="removeItemFromCart(item)">
                                    <span class="icon">
                                        <i class="fa fa-remove"></i>
                                    </span>
                                    Remove
                                </a>
                            </div>
                        </div>

                        <div class="product-level product-right">
                            <div class="product-text product-quantiy">
                                <input type="number" min="1" step="1" class="input" @input="updateQuantity(item, $event.target.value)" :value="item.quantity">
                            </div>
                            <div class="product-text product-price">{{item.variant.priceV2.amount | price}}</div>
                        </div>

                    </div>
                </div>
            </transition-group>
            <!-- items -->

            <!-- no items -->
            <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
                <div class="column is-8 is-offset-2 animated has-text-centered is-empty-cart" v-if="isCartEmpty">
                    <p class="content">
                        You have nothing in your cart, but don't worry there is so much out there. <router-link :to="getStoreLink('')">Continue Shopping</router-link>
                    </p>
                </div>
            </transition>
            <!-- no items -->

            <!-- grand total -->
            <div class="columns is-multiline grand-total" v-if="!isCartEmpty">
                <div class="column" :class="colSize">
                    <div class="cost-breakdown">
                        <div class="cost-row">
                            <div class="item item-heading">Subtotal</div>
                            <div class="item item-details has-text-right">{{checkout.subtotalPrice | price}}</div>
                        </div>
                        <!-- <div class="cost-row">
                                <div class="item item-heading">Shipping</div>
                                <div class="item item-details has-text-right">Free</div>
                            </div> -->
                        <div class="cost-row">
                            <div class="item item-heading has-text-bold">Total</div>
                            <div class="item item-details has-text-right has-text-bold">{{checkout.subtotalPrice | price}}</div>
                        </div>
                        <div class="cost-row checkout-button-item">
                            <a @click="updateShopifyCheckout" class="button is-rounded" :disabled="Object.keys(itemsToUpdate).length==0" :class="{'is-loading': isLoadingCart}">Update</a>
                        </div>
                        <div class="cost-row checkout-button-item">
                            <a @click="openShopifyCheckout" class="button is-rounded" :class="{'is-loading': isLoadingCart}">Checkout</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- grand total -->

        </div>
    </section>
</template>

<script>
import _ from "lodash";
import { StoreMixins, ProductMixins } from "@/mixins";
export default {
    name: "CartPage",
    mixins: [StoreMixins, ProductMixins],
    props: {},
    data() {
        return {
            isLoadingCart: false,
            itemsToUpdate: {},
        };
    },
    components: {},
    methods: {
        async removeItemFromCart(item) {
            var res = await this.$shopify.checkout.removeLineItems(this.checkout.id, [item.id]);
            this.$store.commit("checkout", res);
        },
        getProductImage(item) {
            return item.variant.image.src;
        },
        itemURL(item) {
            return this.getStoreLink(`products/${item.variant.product.id}`);
        },
        openShopifyCheckout() {
            document.location = this.checkout.webUrl;
        },
        async updateShopifyCheckout() {
            var payload = [];
            for (var k in this.itemsToUpdate) {
                payload.push({ id: k, quantity: parseInt(this.itemsToUpdate[k]) });
            }
            var checkout = await this.$shopify.checkout.updateLineItems(this.checkout.id, payload);
            this.$set(this.$data, "itemsToUpdate", {});
            this.$store.commit("checkout", checkout);
        },
        async updateQuantity(item, quantity) {
            this.$set(this.itemsToUpdate, item.id, quantity);
        },
    },
    computed: {
        colSize() {
            return "is-12-tablet is-8-desktop is-offset-2-desktop";
        },
        cartIds() {
            return _.map(this.cart, (item) => {
                return item._id;
            });
        },
        lineItems() {
            return this.checkout.lineItems.filter((item) => {
                console.log(item);
                return item.variant ? item : null;
            });
        },
    },
    watch: {},
    created() {},
    async mounted() {
        this.setDefaultMeta(this.storeMeta);
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";

// top heading for the list of items
.cart-headings {
    display: flex;
    padding: 5px;
    .cart-heading {
        font-family: $heading-font;
        text-transform: uppercase;
        font-size: $size-7;
    }
    .cart-quanity {
        flex: 1;
    }
    .cart-heading-left {
        display: flex;
        width: 50%;
        @include mobile() {
            width: 80%;
        }
    }
    .cart-heading-right {
        display: flex;
        width: 50%;
        @include mobile() {
            width: 25%;
        }
    }
}

.grand-total {
    .cost-breakdown {
        display: flex;
        flex-direction: column;
    }
    .cost-row {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .item {
            font-family: $heading-font;
            padding-top: 10px;
        }
        .item-heading {
            width: 130px;
            text-align: right;
        }
        .item-details {
            width: 70px;
        }
    }
    .checkout-button-item {
        margin-top: 20px;
    }
}

.is-empty-cart {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: $size-6;
    height: 80vh;
    max-width: 500px;
    margin: 0 auto;
    @include flexCenter();
    a {
        @include pageLink();
    }
}

.cart-item {
    border-top: 1px solid lighten($gray-color, 50);
    padding-top: 10px;
    padding-bottom: 10px;
    //background-color: plum;
    padding-left: 0;
    padding-right: 0;

    &:last-child {
        border-bottom: 1px solid lighten($gray-color, 50);
    }

    .product {
        background-color: #fff;
        height: 120px;
        display: flex;
        .image {
            height: 120px;
            width: 120px;
            margin-right: 12px;
            overflow: hidden;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
        }
        .product-level {
            height: 100%;
        }
        .product-title {
            font-weight: 800;
            .is-truncate-text {
                max-width: 165px;
            }
        }
        .product-text {
            font-family: $heading-font;
        }
        .product-right {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 50%;
            @include mobile() {
                width: 20%;
            }
            .product-quantiy {
                flex: 1;
                //align-self: flex-start;
                display: flex;
                .input {
                    width: 65px;
                    @include mobile() {
                        width: 30px;
                    }
                }
            }
            .product-price {
                display: flex;
                font-weight: 800;
                //align-self: flex-end;
            }
        }
        .product-left {
            display: flex;
            align-items: center;
            width: 50%;
            @include mobile() {
                width: 75%;
            }
        }
        .edit-product {
            background: red;
            display: block;
        }
        .product-details {
            display: flex;
            align-items: center;
        }
        .is-remove-button {
            border: none;
            color: $gray-color;
            margin-right: 10px;
        }
        .is-remove-button-bottom {
            display: none;
            padding-top: 10px;
        }
        @include until(500px) {
            .is-remove-button-left {
                display: none;
            }
            .is-remove-button-bottom {
                display: block;
            }
            .image {
                width: 70px;
            }
        }
    }
}
</style>
