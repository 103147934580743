<template>
    <page-layout :isLoaded="isLoaded" :isEditingPage="editPage" :openSidebar="editPage" :class="{'post-page': true, 'is-editable': canEdit, 'is-collapsed':collapseSections}">

        <!-- sidebar -->
        <template slot="sidebar">
            <div class="page-editor" v-if="isLoaded">

                <!-- page thumbnai -->
                <div class="page-thumbnail" :class="{'is-loading': isLoadingThumbnail, 'no-thumbnail': !page.image}">
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/admin/pages">Posts</router-link>
                            </li>
                            <li class="is-active"><a href="#" aria-current="page">{{uid}}</a></li>
                        </ul>
                    </nav>
                    <figure :class="{'is-missing-thumbail': !page.image}" v-droppable="authenticated" @drop="handlePageThumbnail">
                        <img v-if="page.image" :src="imageURL(page.image, 's300-c')" alt="">
                        <div class="page-thumbnail-buttons">
                            <a class="has-file is-link is-round-icon-button">
                                <icon :icon="`${isLoadingThumbnail ? 'loading' : 'fa-photo'}`" size="is-large"></icon>
                                <input type="file" name="page-thumbnail" accept="image/*" multiple="false" @change="handlePageThumbnail">
                            </a>
                            <a class="is-link is-round-icon-button" @click.prevent="removePageThumbnail" v-if="page.image">
                                <icon :icon="`${isLoadingThumbnail ? 'loading' : 'fa-trash'}`" size="is-large"></icon>
                            </a>
                        </div>
                    </figure>
                </div>
                <!-- end page thumbnai -->

                <!-- edit page nav -->
                <div class="columns is-gapless is-multiline">
                    <div class="column is-12">
                        <nav class="navbar is-mobile edit-controls">
                            <a class="navbar-item" @click="toggleEditPage">
                                <icon icon="fa-pencil"></icon>
                            </a>
                            <a class="navbar-item" @click.stop="toggleCollapse">
                                <icon :icon="`fa-${collapseSections?'expand':'compress'}`"></icon>
                            </a>
                            <a class="navbar-item" @click="save">
                                <icon icon="fa-save"></icon>
                            </a>
                            <a class="navbar-item has-file is-icon-button thumbnail-upload-button" :class="{'is-disabled': isLoadingThumbnail}">
                                <icon icon="fa-image"></icon>
                                <input type="file" name="page-thumbnail" accept="image/*" multiple="false" @change="handlePageThumbnail">
                            </a>
                            <a class="navbar-item is-icon-button" @click="removePage">
                                <icon icon="fa-trash"></icon>
                            </a>
                        </nav>
                    </div>
                </div>
                <!-- edit page nav -->

                <div class="columns is-gapless is-multiline page-editor-form">
                    <div class="column is-12">

                        <div class="field">
                            <label class="label">
                                Published
                                <toggle-button :value="page.status=='published'" :sync="true" @change="(e) => {page.status = e.value ? 'published':'draft'}"></toggle-button>
                            </label>
                        </div>
                        <div class="field">
                            <label class="label">
                                Show Title
                                <toggle-button :value="page.show_title" :sync="true" @change="(e) => {page.show_title = e.value}"></toggle-button>
                            </label>
                        </div>

                        <label class="label">Page Slug</label>
                        <div class="field has-addons">
                            <div class="control is-expanded">
                                <input class="input" type="text" v-model="page.slug" placeholder="permalink slug" name="">
                            </div>
                            <div class="control">
                                <a class="button" @click.stop="setPageSlugFromTitle">
                                    <span class="icon">
                                        <i class="fa fa-refresh"></i>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <label class="label">External URL</label>
                        <div class="field">
                            <div class="control is-expanded">
                                <input class="input" type="text" v-model="page.external_url" placeholder="" name="">
                            </div>
                        </div>

                        <!--<label class="label">Page ID</label>
                        <div class="field has-addons">
                            <div class="control is-expanded">
                                <input class="input" type="text" :value="uid" :disabled="true">
                            </div>
                            <div class="control">
                                <a class="button" v-clipboard="uid">
                                    <span class="icon">
                                        <i class="fa fa-copy"></i>
                                    </span>
                                </a>
                            </div>
                        </div>-->

                        <!-- page categories -->
                        <div class="field">
                            <div class="control">
                                <label class="label">Category</label>
                                <tagger @results="handleCategoriesResults" @add="handleCategoriesAdd" @remove="handleCategoriesRemove" placeholder="add or search for category" :options="$categories" :value="page.categories" :multiple="true">
                                </tagger>
                            </div>
                        </div>
                        <!-- end page categories -->

                        <!--
                        <div class="field">
                            <div class="control">
                                <label class="label">Sections</label>
                                <ul class="sections">
                                    <draggable v-model="orderedSections" :options="{ghostClass: 'item-ghost'}" :isEditable="canEdit">
                                        <li :class="{[`${section.type}-section`]: true}" v-for="section in orderedSections" :key="section.uid" @mouseover="mouseOverSection($event, section)" @mouseout="mouseOutSection($event)">
                                            <div class="is-clearfix">
                                                <span class="is-help-text section-type tag is-size-7">{{section.type}}</span>
                                                <ul class="section-items" v-if="section.type!='hero'">
                                                    <edit-page-section-items
                                                        v-model="section.items"
                                                        :isEditable="canEdit"
                                                        :sectionKey="section.uid"
                                                        @updateItemsOrder="save"
                                                        @mouseOverItem="mouseOverSectionItem"
                                                        @mouseOutItem="mouseOutSectionItem">
                                                    </edit-page-section-items>
                                                </ul>
                                            </div>
                                        </li>
                                    </draggable>
                                </ul>
                            </div>
                        </div>
                        -->

                    </div>
                </div>

                <div class="page-editor-footer">
                    <div class="field">
                        <div class="control has-text-centered">
                            <a class="button is-rounded" @click.prevent="save">Save</a>
                        </div>
                    </div>
                    <div class="field has-text-centered">
                        <small class="created-at">updated {{page.updated_at | fromnow}}</small>
                    </div>
                </div>
            </div>
        </template>
        <!-- end sidebar -->

        <!-- main page -->
        <template slot="page">
            <div class="page-body" v-if="isLoaded">

                <!-- page wrapper -->
                <div class="page-wrapper">

                    <!-- edit page button -->
                    <edit-page-button :isActive="editPage" @click="toggleEditPage"></edit-page-button>
                    <!-- edit page button -->

                    <site-nav></site-nav>

                    <!-- title & subtitle -->
                    <section class="section page-details" ref="meta" v-if="canEdit">
                        <div class="container">
                            <div class="columns meta">
                                <div class="column" :class="columnSize('normal', sectionSizes)">
                                    <div class="columns is-multiline">
                                        <div class="column is-12">
                                            <template v-if="canEdit">
                                                <text-input v-model="page.title" :forcePlainText="true" textClass="title page-title-input" placeholder="Untitled" :isEditable="canEdit" @input="update({'title': $event})"></text-input>
                                                <text-input v-model="page.subtitle" textClass="subtitle page-subtitle-input" placeholder="Add a subtitle" :isEditable="canEdit" @input="update({'subtitle': $event})"></text-input>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- end page title & subtitle -->

                    <!-- page title & subtitle (non edit mode) -->
                    <section class="section page-details" ref="meta" v-if="!canEdit && page.show_title">
                        <div class="container">
                            <div class="columns">
                                <div class="column" :class="columnSize('normal', sectionSizes)">
                                    <div class="title" v-html="page.title"></div>
                                    <div v-if="page.subtitle" class="subtitle is-size-6" v-html="page.subtitle"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- end page title & subtitle -->

                    <!-- loop sections -->
                    <template v-for="(section) in orderedSections">
                        <!-- debug info -->
                        <!-- <div class="container has-text-centered">
                            <div class="title is-size-7" :style="{'z-index':10000, 'background-color': 'rgba(255, 0, 255, 0.4)', 'padding':'10px', 'position': 'relative','top': '30px'}">
                                {{section.type}} - {{section._id}} {{section.order}}
                                index: {{section.index}}
                                {{section.text}}
                            </div>
                        </div> -->

                        <!-- a hero type section -->
                        <template v-if="section.type === 'hero'">
                            <hero :isEditable="canEdit" :section="section" :uid="uid" :key="section['.key']" @removeSection="removeSection" @update="updateHero" @moveSection="(e) => {moveSection(e.key, e.dir)}">
                            </hero>
                        </template>
                        <!-- end hero type section -->

                        <!-- normal section -->
                        <template v-if="section.type == 'section'">
                            <section class="content-section section" :class="{'is-moused-over': sectionMousedOver==section.uid, 'is-editable':canEdit}" :key="section.uid" v-droppable="authenticated" @drop="handleSectionDrop($event, section.uid)">
                                <div class="container section-container">
                                    <div class="columns is-multiline">
                                        <div :class="columnSize(section.size, sectionSizes)">
                                            <div class="columns is-multiline section-column">

                                                <!-- section gui -->
                                                <div class="column is-12" v-if="canEdit">
                                                    <div class="page-gui section-gui">
                                                        <nav class="navbar gui-controls">

                                                            <!-- move up section -->
                                                            <a class="navbar-item" @click.prevent="moveSection(section.uid, -1)">
                                                                <icon icon="fa-arrow-up" size="is-small"></icon>
                                                            </a>

                                                            <!-- move down section -->
                                                            <a class="navbar-item" @click.prevent="moveSection(section.uid, 1)">
                                                                <icon icon="fa-arrow-down" size="is-small"></icon>
                                                            </a>

                                                            <!-- set section size -->
                                                            <section-sizes v-model="section.size" @input="updateSection()"></section-sizes>

                                                            <!-- remove section -->
                                                            <a class="navbar-item" @click.prevent="removeSection(section.uid)">
                                                                <icon icon="fa-trash" size="is-small"></icon>
                                                            </a>

                                                            <!-- add item to section -->
                                                            <!-- <add-item :sectionKey="section['.key']" :itemsCount="sections.length" @addItem="addItemToSection"></add-item> -->

                                                        </nav>
                                                    </div>
                                                </div>
                                                <!-- section gui -->

                                                <!-- items -->
                                                <div class="column is-12 items-container">
                                                    <!-- render the content -->
                                                    <section-items v-model="section.items" @update="saveSectionItems" @remove="removeSectionItem" :postKey="uid" :sectionKey="section.uid" :isEditable="canEdit" :sectionItemMousedOver="sectionItemMousedOver" :addItem="addItemToSection">
                                                    </section-items>
                                                    <!-- end content rendering -->
                                                </div>
                                                <!-- end items -->

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </template>
                        <!-- end normal section -->

                        <!-- page gallery -->
                        <template v-if="section.type == 'gallery'">
                            <section class="content-section section" :class="{'is-moused-over': sectionMousedOver==section.uid, 'is-editable':canEdit}" :key="section.uid" v-droppable="authenticated" @drop="handleSectionDrop($event, section.uid)">
                                <div class="container section-container">
                                    <div class="columns is-multiline">
                                        <div :class="columnSize(section.size, sectionSizes)">
                                            <page-gallery @update="updateGallery" @removeSection="removeSection" :uid="uid" :isEditable="canEdit" :section="section">
                                            </page-gallery>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </template>
                        <!-- page gallery -->

                    </template>
                    <!-- end loop sections -->

                    <section class="section" v-if="!hasSections">
                        <div class="container has-text-centered">
                            Nothing here yet but don't worry you can make a page about anything!
                        </div>
                    </section>

                    <!-- add new section or hero -->
                    <section class="section add-content-gui" v-if="canEdit">
                        <div class="columns">
                            <div class="column is-12 has-text-centered">

                                <div class="dropdown is-centered is-hoverable">
                                    <div class="dropdown-trigger">
                                        <plus-button aria-haspopup="true" aria-controls="dropdown-menu"></plus-button>
                                    </div>
                                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div class="dropdown-content">
                                            <a class="dropdown-item" @click.prevent="addSection">
                                                Section
                                            </a>
                                            <a class="dropdown-item" @click.prevent="addHero">
                                                Hero
                                            </a>
                                            <a class="dropdown-item" @click.prevent="addGallery">
                                                Gallery
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <!-- end add new here + sections -->

                    <!-- next and prev project -->
                    <page-navigation :prevProject="prevProject" :nextProject="nextProject"></page-navigation>
                    <!-- next and prev project -->

                    <!-- back to projects -->
                    <section class="section">
                        <div class="container has-text-centered">
                            <router-link class="is-link is-heading-font" :to="{path: '/#projects'}">Back to projects</router-link>
                        </div>
                    </section>
                    <!-- end back to projects -->
                </div>
                <!-- end page wrapper -->

                <!-- footer -->
                <main-footer :class="{'column is-12': canEdit}" :social="$profile.social"></main-footer>
                <!-- end footer -->

            </div>
        </template>
        <!-- main page -->

    </page-layout>
</template>

<script>
import _ from "lodash";
import _move from "lodash-move"; // until add to lodash
import { PageMixins, ImageUploaderMixins } from "@/mixins";
import SiteNav from "@/components/layout/SiteNav";
import { PageLayout, SectionItems, PageGallery, PageNavigation, SectionSizes, Hero } from "@/components/page";

export default {
    metaInfo: {
        title: "Todd Vanderlin - ",
    },
    name: "Page",
    props: {},
    mixins: [ImageUploaderMixins, PageMixins],
    data() {
        return {
            editPage: false,
            pageDidLoad: false,
            showBottomEdits: false,
            isLoadingThumbnail: false,
            collapseSections: false,
            page: {},
            ref: null,

            // get next and prev links for post
            prevProject: {},
            nextProject: {},

            // use when mouse over sidebar editor
            sectionMousedOver: null,
            sectionItemMousedOver: null,
        };
    },

    components: {
        Hero,
        SiteNav,
        PageLayout,
        PageGallery,
        SectionItems,
        SectionSizes,
        PageNavigation,
    },

    methods: {
        // save section for page - we go through
        // each section match UID == sectionKey and
        // set the section items - then save
        // -------------------------------------
        saveSectionItems(e) {
            _.forEach(this.page.sections, (section) => {
                if (section.uid == e.sectionKey) {
                    section.items = e.items;
                }
            });
            this.save();
        },

        // clean the data to post.
        // -------------------------------------
        parsePageData(page) {
            page.slug = this.$slugify(page.slug).toLowerCase();

            // var data = JSON.parse(JSON.stringify(page));
            // if (data.image) data.image = data.image._id
            // for (var i = 0; i < data.sections.length; i++) {
            //     var section = data.sections[i]
            //     if (section.type == 'hero') {
            //         if (section.image) {
            //             section.image = section.image._id
            //         }
            //     } else {
            //         for (var j = 0; j < section.items.length; j++) {
            //             if (section.items[j].type == 'image' && section.items[j].image) {
            //                 section.items[j].image = section.items[j].image._id
            //             }
            //         }
            //     }

            // }
            return page;
        },

        // save the page data - take the whole page
        // object and post to api
        // -------------------------------------
        async save() {
            var page = this.parsePageData(this.page);
            await this.ref.update(page);
        },

        // save data based on payload passed
        // -------------------------------------
        async update(payload) {
            this.ref.update(payload);
        },

        async updateSection(payload) {
            console.log(payload);
            await this.ref.update({ sections: this.page.sections });
        },

        // set the page status (draft or published)
        // -------------------------------------
        updateFromToggle(status, key, values) {
            values = values || { checked: true, unchecked: false };
            var value = status.value ? values.checked : values.unchecked;
            var payload = {};
            payload[key] = value;
            this.page[key] = value;
            this.update(payload).then(() => console.log(`page ${key} set`, value));
        },

        // update the timestamp for updated_at
        // -------------------------------------
        updateLastTimeSaved(notify) {
            this.isAuth().then(() => {
                this.update({ updated_at: this.$api.now() });
                if (notify) this.$toasted.show("Saved").goAway(1500);
            });
        },

        // update the page title
        // -------------------------------------
        updateTitle() {
            this.isAuth().then(() => {
                var payload = {
                    title: this.page.title,
                    subtitle: this.page.subtitle || null,
                    updatedAt: this.$api.now(),
                };
                this.$api.put(`posts/${this.uid}`, payload).then(() => {
                    console.log("post title update");
                });
            });
        },

        // upload a new page thumbnai
        // -------------------------------------
        async handlePageThumbnail(e) {
            this.isLoadingThumbnail = true;

            if (this.page.image) {
                try {
                    await this.removeImage(this.page.image);
                } catch (error) {
                    console.log(error);
                }
            }

            const file = await this.hasSingleFile(e);
            const filename = await this.uploadImage(file);
            this.isLoadingThumbnail = false;
            this.ref.update({ image: filename });
        },

        // remove the page thumbnai
        async removePageThumbnail() {
            if (this.page.image) {
                try {
                    await this.removeImage(this.page.image);
                } catch (error) {
                    console.log(error);
                }
                this.$set(this.page, "image", null);
                this.save();
            }
        },

        // -------------------------------------
        handleScroll() {
            if (this.page && this.$refs.meta) {
                var top = document.body.scrollTop;
                this.showBottomEdits = top > this.$refs.meta.offsetTop;
            }
        },

        // ------------------------------------------------------------------------
        // remove content
        // ------------------------------------------------------------------------

        // -------------------------------------
        removeItemFromSection(section, item) {
            console.log("NEED TO BUILD THIS", section, item);
        },

        // remove the page - this will mark the page as
        // trash - you can later delete the page in admin section
        // -------------------------------------
        removePage() {
            var options = {
                title: "Are you sure you want to delete this page",
                buttons: true,
                confirmButtonClass: "is-danger",
            };
            this.$swal(options)
                .then((e) => {
                    if (e === true) {
                        this.isAuth().then(() => {
                            this.update({ status: "trash" });
                            this.$router.push({ path: "/" });
                        });
                    }
                })
                .catch(() => {});
        },

        // remove section from page
        // -------------------------------------
        async removeSection(key) {
            // get the section and the section index from the
            // key passed - we are searching from the UID not _id
            var section = this.getSectionFromKey(key);

            if (section) {
                // remove a hero
                if (section.type == "hero") {
                    try {
                        await this.removeImage(section.image);
                    } catch (error) {
                        console.log(error);
                    }
                    this.$delete(this.page.sections, key);
                    this.save();
                    console.log("remove hero image");
                }

                // remove normal section or gallery
                else if (section.type == "section" || section.type == "gallery") {
                    console.log("removing " + section.type);
                    var removeProms = [];
                    try {
                        for (var k in section.items) {
                            var item = section.items[k];
                            if (item.type == "image") {
                                if (item.image) {
                                    removeProms.push(this.removeImage(item.image));
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }

                    // all images have been deleted now remove the section
                    Promise.all(removeProms)
                        .then(() => {
                            console.log("now delete the section");
                            this.$delete(this.page.sections, key);
                            this.save();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }

            // missing a section
            else {
                console.log("Missing section", key);
            }
        },

        // -------------------------------------
        async removeSectionItem(e) {
            var section = this.page.sections[e.sectionKey];
            var item = section.items[e.itemKey];
            if (section && item) {
                if (item.type === "image" && item.image !== null) {
                    await this.removeImage(item.image);
                }

                this.$delete(section.items, e.itemKey);
                this.save();
            }
        },

        // ------------------------------------------------------------------------
        // adding content
        // ------------------------------------------------------------------------

        // add a new section
        // -------------------------------------
        addSection() {
            let k = this.$shortid();
            this.page.sections = this.page.sections || {};
            console.log(this.page.sections);
            this.$set(this.page.sections, k, this.baseSection(this.orderedSections.length + 1, k));
            this.save();
        },

        // add a hero section
        // -------------------------------------
        async addHero() {
            let k = this.$shortid();
            this.page.sections = this.page.sections || {};
            this.page.sections[k] = this.baseHero(this.orderedSections.length + 1, k);
            this.save();
        },

        // add a gallery
        // -------------------------------------
        addGallery() {
            this.isAuth().then(() => {
                this.page.sections.push(this.baseGallery(this.page.sections.length + 1));
                this.save();
                console.log("adding a gallery");
            });
        },

        // get section from a key (UID)
        // -------------------------------------
        getSectionFromKey(key) {
            return _.find(this.page.sections, { uid: key });
        },

        // get the section index (UID)
        // -------------------------------------
        getSectionIndexFromKey(key) {
            return _.findLastIndex(this.page.sections, (e) => {
                return e.uid == key;
            });
        },

        // get the section item index
        // -------------------------------------
        getSectionItemIndexFromKey(sectionKey, itemKey) {
            var section = this.getSectionFromKey(sectionKey);
            if (section) {
                return _.findLastIndex(section.items, (e) => {
                    return e.uid == itemKey;
                });
            }
            return -1;
        },

        // -------------------------------------
        getSectionItemFromKeys(sectionKey, itemKey) {
            var section = this.getSectionFromKey(sectionKey);
            if (section) {
                return _.find(section.items, { uid: itemKey });
            }
            return null;
        },

        // -------------------------------------
        titleClass(name) {
            return this.titleClasses[name] || "";
        },

        // -------------------------------------
        toggleCollapse() {
            this.collapseSections = !this.collapseSections;
        },

        // -------------------------------------
        setItemSize(item, size) {
            item.size = size;
        },

        // -------------------------------------
        handleSectionDrop(e, sectionKey) {
            e.preventDefault();
            e.stopPropagation();

            var order = 0;
            var section = this.getSectionFromKey(sectionKey);
            if (section && section.items) {
                order = section.items.length + 1;
            }
            this.addItemToSection({
                event: e,
                type: "image",
                sectionKey: sectionKey,
                order: order,
            });
        },

        // adding and item to the section
        // -------------------------------------
        async addItemToSection(e) {
            var section = this.getSectionFromKey(e.sectionKey);
            if (!section) return;

            section.items = section.items || {};
            var key = this.$db.uid();
            var item = {};

            // image
            if (e.type == "image") {
                var files = await this.hasFiles(e.event);

                for (var i = 0; i < files.length; i++) {
                    var file = files[i];

                    if (section) {
                        key = this.$db.uid();

                        // now add the image
                        item = {
                            type: "image",
                            size: "full",
                            image: "loading",
                            progress: 0,
                            uid: key,
                            id: key,
                            order: e.order + i,
                        };

                        this.$set(section.items, key, item);

                        var filename = await this.uploadImage(file, (e) => {
                            item.progress = e;
                        });

                        item.image = filename;

                        this.save();
                    }
                }
            }

            // text
            else if (e.type == "text") {
                key = this.$db.uid();

                item = {
                    size: "full",
                    type: "text",
                    text: "You can write something here about really... anything.",
                    order: e.order,
                    uid: key,
                    id: key,
                };
                if (section) {
                    this.$set(section.items, key, item);
                    this.save();
                }
            }

            // video
            else if (e.type == "video") {
                key = this.$db.uid();
                item = {
                    size: "full",
                    type: "video",
                    video: null,
                    order: e.order,
                    uid: key,
                    id: key,
                };
                if (section) {
                    this.$set(section.items, key, item);
                    this.save();
                }
            }
        },

        // move a section up / down
        // -------------------------------------
        moveSection(key, dir) {
            this.isAuth().then(() => {
                var currIndex = -1;
                var currentOrder = this.orderedSections.map((item, i) => {
                    if (item.uid == key) {
                        currIndex = i;
                    }
                    return item.uid;
                });
                var moveIndex = currIndex + dir;
                if (moveIndex < currentOrder.length) {
                    var results = _move(currentOrder, currIndex, moveIndex);
                    for (var i = 0; i < results.length; i++) {
                        this.page.sections[results[i]].order = i;
                    }
                    this.save();
                }
            });
        },

        // gallery update
        // -------------------------------------
        updateGallery(e) {
            var notify = e.notify || false;
            var section = this.getSectionFromKey(e.key);
            if (section) {
                var sectionIndex = this.getSectionIndexFromKey(e.key);

                var payload = this.parsePayload(e.payload);
                var gallery = { ...section, ...payload };

                this.$set(this.page.sections, sectionIndex, gallery);
                this.updateLastTimeSaved(notify);
                this.save();
            }
        },

        // hero edits
        // -------------------------------------
        updateHero(e) {
            // var notify = e.notify || false
            var section = this.getSectionFromKey(e.key);
            if (section) {
                let hero = { ...section, ...e.payload };
                this.$set(this.page.sections, e.key, hero);
                this.save();
            }

            // if(section) {
            //     console.log(e.key, section);
            //     var sectionIndex = this.getSectionIndexFromKey(e.key)
            //     var payload = this.parsePayload(e.payload)
            //     var hero = {...section, ...payload}
            //     this.$set(this.page.sections, sectionIndex, hero)
            //     this.updateLastTimeSaved(notify);
            //     this.save();
            // }
        },

        // find the page from the slug and then
        // bind the data to the page
        // -------------------------------------
        findPageFromSlug() {
            return new Promise((resolve) => {
                this.$db
                    .postsRef()
                    .where("slug", "==", this.pageSlug)
                    .get()
                    .then((collection) => {
                        if (!collection.empty) {
                            this.uid = collection.docs[0].id;
                            this.pageRef = collection.docs[0].ref;
                        } else {
                            console.log("no page found");
                        }
                        resolve(this.pageRef);
                    });
            });
        },

        // -------------------------------------
        async bindPage() {
            this.$set(this.$data, "ref", this.$db.collection("posts").doc(this.pageSlug));
            await this.ref.onSnapshot((doc) => {
                var page = doc.data();
                if (!page.items) {
                    page.items = {};
                }
                for (var k in page.sections) {
                    page.sections[k].uid = k;
                    page.sections[k].id = k;
                }
                this.$set(this.$data, "page", page);
                this.pageDidLoad = true;
                console.log(this.page);
                this.setDefaultMeta({
                    title: this.siteMeta.title + " | " + this.stripHTML(this.page.title),
                    description: this.stripHTML(this.page.subtitle),
                    image: this.page.image ? this.imageURL(this.page.image) : this.siteMeta.image,
                    keywords: this.siteMeta.keywords,
                });
            });
        },

        // ------------------------------------------------------------------------
        // categories for the page
        // ------------------------------------------------------------------------
        handleCategoriesAdd(category) {
            this.page.categories.push(category.name);
        },
        handleCategoriesRemove(item) {
            var index = this.page.categories.indexOf(item);
            if (index !== -1) {
                this.page.categories.splice(index, 1);
            }
        },
        async handleCategoriesResults(e) {
            if (e.add) {
                await this.$db.collection("categories").doc(e.tag).set({
                    updated_at: new Date(),
                    name: e.tag,
                });
            }
        },

        // edit page
        // ------------------------------------------------------------------------
        toggleEditPage() {
            this.editPage = !this.editPage;
        },
        mouseOverSection(e, section) {
            this.sectionMousedOver = section.uid;
        },
        mouseOutSection() {
            this.sectionMousedOver = null;
        },
        mouseOverSectionItem(item) {
            this.sectionItemMousedOver = item.uid;
        },
        mouseOutSectionItem() {
            this.sectionItemMousedOver = null;
        },
    },
    computed: {
        uid() {
            return this.ref.id;
        },
        canEdit() {
            return this.authenticated && this.editPage && this.isLoaded;
        },
        isLoaded() {
            return this.pageDidLoad && this.page != null;
        },
        pageSlug() {
            return this.$route.params.slug;
        },
        showHero() {
            if (this.canEdit) return true;
            if (this.page.hero.image || this.page.hero.video) {
                return true;
            }
            return false;
        },
        hasSections() {
            return this.orderedSections.length;
        },
        orderedSections: {
            get() {
                return _.orderBy(this.page.sections, ["order"]);
            },
            set(items) {
                for (var i = 0; i < items.length; i++) {
                    items[i].order = i;
                }
                this.update({ sections: items });
            },
        },
    },
    watch: {
        pageSlug() {
            this.page = null;
            this.pageDidLoad = false;
            this.bindPage();
            if (this.$route.name == "edit-page") {
                this.editPage = true;
            }
        },
    },
    created() {},
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    async mounted() {
        window.addEventListener("scroll", this.handleScroll);
        await this.bindPage();
        if (this.$route.name == "edit-page") {
            this.editPage = true;
        }
    },
};
</script>

<style  lang="scss">
@import "~@/styles/variables";
@import "~@/styles/mixins";
</style>
