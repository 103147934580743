import _ from 'lodash'

export default {
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {

		// error helpers
		parseError(err) {
			if (typeof err === 'string') {
				return err;
			}
			else if (typeof err === 'object' && err['msg']) {
				return err['msg'];
			}
			return err;
		},

		parseErrors(e) {
			if (e instanceof String || typeof e == 'string') {
				return [e];
			}
			else if (e instanceof Array && e.length) {
				return _.map(e, (i) => {
					return this.parseError(i);
				})
			}
			else if (e instanceof Object && Object.keys(e).length) {
				return this.parseError(e);
			}
			return e;
		},

		parseFirstError(err) {
			if (err instanceof String) {
				return err;
			}
			else if (err instanceof Array && err.length) {
				return this.parseError(err[0]);
			}
			else if (err instanceof Object && Object.keys(err).length) {
				return this.parseError(err[0]);
			}
			return 'Unknown error just went down!';
		},

		objectToFormData(data) {

			var fd = new FormData()

			for (var key in data) {
				var value = data[key];
				if (value instanceof Array) {
					for (var i in value) {
						fd.append(`${key}[]`, value[i]);
					}
				}
				else if (value instanceof File) {
					console.log('adding file', key);
					fd.append(key, value)
				}
				else if (value instanceof Object) {
					for (var k in value) {
						fd.append(`${key}[${k}]`, value[k])
					}
				}
				else if (value instanceof Date) {
					fd.append(key, value.getTime())
				}

				else {
					fd.append(key, data[key]);
				}
			}

			return fd;
		}
	}
}
