<template>
    <a class="button is-edit-button" :class="{'is-active': isActive}" @click.prevent="() => {$emit('click')}" v-if="authenticated">
        <span class="icon is-small">
            <i class="fa fa-pencil"></i>
        </span>
    </a>
</template>

<script>
export default {
    name: 'EditPageButton',
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
