<template>
    <admin-layout :isLoaded="isLoaded" :crumbs="crumbs">
        <div class="columns is-multiline">
            <div class="column is-12 list">
                <div class="list-item is-mobile level" v-for="(productType) in productTypes" :key="productType.id">
                    <div class="level-left">
                        <div class="level-item">
                            <figure class="image" v-if="productType.images.length > 0">
                                <img :src="`${productType.images[0]}=s80-c`" alt="">
                            </figure>
                            <p>
                                <strong>{{productType.name}}</strong><br>
                                <small>{{productType.id}}</small>
                            </p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div class="control is-grouped">
                                <b-dropdown hoverable>
                                    <button class="button" slot="trigger">
                                        <icon icon="fa-ellipsis-h"></icon>
                                    </button>
                                    <b-dropdown-item>
                                        <router-link :to="`/admin/products/types/${productType.id}`">Edit</router-link>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                        <a target="_blank" :href="`https://dashboard.stripe.com/test/products/${productType.id}`">View at Stripe</a>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </admin-layout>
</template>

<script>
export default {
    name: "AdminProductTypesPage",
    props: {},
    data() {
        return {
            isLoaded: false,
            crumbs: [
                { name: "Admin", path: "/admin" },
                { name: "Types", path: "/admin/products/types", active: true },
            ],
            productTypes: null,
        };
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.$api.get("products/types").then((e) => {
            if (e.data.error) {
                this.errors = e.data.error;
            } else {
                this.isLoaded = true;
                this.productTypes = e.data.data;
            }
        });
    },
};
</script>

<style scoped lang="scss">
</style>
