<template>
	<div class="columns is-multiline page-gallery section-column">

		<!-- image details -->
        <modal ref="image-details-modal" v-if="isEditable && selectedItem">
			<div class="box image-details-modal">
				<div class="field">
					<figure class="image" v-if="selectedItem.image">
						<img :src="`${selectedItem.image.serving_url}=s300`" alt="">
					</figure>
				</div>
				<div class="field">
					<label class="label">UID</label>
					<div class="control">
						<input class="input" type="text" :disabled="true" :value="selectedItem.uid">
					</div>
				</div>
				<div class="field">
					<label class="label">Alt</label>
					<div class="control">
						<input class="input" type="text" v-model="selectedItem.image.alt" placeholder="alt text (optional)">
					</div>
				</div>
				<div class="field">
					<label class="label">Caption</label>
					<div class="control">
						<input class="input" type="text" v-model="selectedItem.image.caption" placeholder="caption (optional)">
					</div>
				</div>
				<div class="field">
					<div class="control has-text-centered">
						<button class="button is-rounded" @click.prevent="saveItemDetails(selectedItem)">Save</button>
						<button class="button is-rounded" @click.prevent="closeItemDetails(selectedItem)">Cancel</button>
					</div>
				</div>
			</div>
		</modal>
		<!-- end image details -->

		<!-- page gallery gui -->
        <div class="page-gui section-gui page-gallery-gui" v-if="isEditable">
            <nav class="navbar gui-controls">

                <!-- move up section -->
                <a class="navbar-item" @click.prevent="moveSection(section.uid, -1)">
                    <icon icon="fa-arrow-up" size="is-small"></icon>
                </a>

                <!-- move down section -->
                <a class="navbar-item" @click.prevent="moveSection(section.uid, 1)">
                    <icon icon="fa-arrow-down" size="is-small"></icon>
                </a>

				<!-- gallery size -->
				<section-sizes v-model="section.size" @input="update({size: $event})"></section-sizes>

				<!-- gallery layout -->
				<item-sizes v-model="section.gallery_size" :sizes="gallerySizes" @input="(name) => {update({gallery_size: name})}"></item-sizes>
				<!-- gallery layout -->

                <!-- remove hero -->
                <a class="navbar-item" @click.prevent="()=>{ $emit('removeSection', section.uid) }">
                    <icon icon="fa-trash" size="is-small"></icon>
                </a>

            </nav>
        </div>

        <!-- gallery items -->
    	<div class="column is-12">
			<draggable classNames="columns is-mobile is-multiline" v-model="orderedImages" :isEditable="isEditable" :options="{ghostClass: 'item-ghost'}">
				<div class="column gallery-image" :class="gallerySizeClass" v-for="(item, i) in orderedImages" :key="i">
		    		<figure class="image" :class="{'is-loading': item.image == 'loading'}">
		    		    <template v-if="!item.image">
		    		    	<icon icon="fa-exclamation-triangle" text="Missing Photo" class="is-missing-photo"></icon>
		    		    </template>
		    		    <template v-else-if="item.image == 'loading'">
		    		    	<icon icon="loading"></icon>
		    		    </template>
		    		    <template v-else>
		    		    	<page-image :image="item.image" :doesZoom="!isEditable"></page-image>
		    		    </template>
				    	<div class="page-gui gallery-image-gui" v-if="isEditable">
				    		<nav class="navbar gui-controls">
						    	<a class="navbar-item" @click.prevent="removeItem(item)">
						    		<icon icon="fa-trash"></icon>
						    	</a>
						    	<a class="navbar-item" @click.prevent="showDetails(item)">
						    		<icon icon="fa-info"></icon>
						    	</a>
					    	</nav>
				    	</div>
		    		</figure>
	    		</div>
    		</draggable>
    	</div>
		<!-- end gallery image -->

    	<!-- no images -->
    	<div class="column is-12 no-images has-text-centered" v-if="isEditable">
			<button class="button is-rounded has-file" :class="{'is-loading': isUploading}" :disabled="isUploading">
				<span>Add Images</span>
				<input type="file" accept="image/*" :multiple="true" name="image" @change="handleImages" :disabled="isUploading">
			</button>
    	</div>
    	<!-- no images -->

	</div>
</template>

<script>
import _ from 'lodash'
import {ImageUploaderMixins} from '@/mixins'
import PageImage from './PageImage'
import SectionSizes from './SectionSizes'
import ItemSizes from './ItemSizes'
export default {
    name: 'PageGallery',
    mixins: [ImageUploaderMixins],
    props: {
    	section: {
            type: Object,
    		required: true
    	},
    	uid: {
            type: String,
    		required: true
    	},
    	isEditable: {
            type: Boolean,
    		default: false
    	},
    },
    data() {
        return {
        	selectedItem: null,
        	isUploading: false,
        }
    },
    components: {
    	PageImage,
		ItemSizes,
		SectionSizes,
    },
    methods: {
		update: _.debounce(function(payload) {
			var data = {
				key: this.section.uid,
				payload: payload
			}
			this.$emit('update', data)
		}, 200),
    	saveItemDetails(item) {
			var image = item.image
			var payload = {
				caption: image.caption,
				alt: image.alt
			}
			this.$api.put(`/assets/${image._id}`, payload).then((e) => {
				this.closeItemDetails()
			})
    	},
    	closeItemDetails() {
    		this.$refs['image-details-modal'].close()
    		this.$nextTick(() => {
    			this.selectedItem = null;
    		});
    	},
    	showDetails(item) {
    		this.selectedItem = item
    		this.$nextTick(() => {
    			this.$refs['image-details-modal'].open()
    		})
    	},
    	moveSection(key, dir) {
            this.$emit('moveSection', {key, dir})
        },
        removeItem(item) {
			var p = new Promise((resolve, reject) => {
				if (item.image) {
					this.removeImage(item.image).then(resolve).catch(err => {
						console.error(err);
					})
				} else {
					resolve()
				}
			})
			p.then(() => {
				this.$removeWithId(this.section.items, item._id)
				this.update(this.section.items)
			})
        },
        handleImages(e) {
			this.isUploading = true;
			this.hasFiles(e).then((files) => {
				var proms = []
        		for (var i = 0; i < files.length; i++) {
        			proms.push(this.addImageToGallery(files[i]));
        		}
        		Promise.all(proms).then(() => {
        			console.log('done uploading files ', files.length);
        			this.isUploading = false
        		})
        	});
        },
        addImageToGallery(file) {
        	return new Promise((resolve, reject) => {
        		var orderCount = this.orderedImages.length;
				var uid = this.$api.uid();
    			var item = {
					uid: uid,
                    type: 'image',
                    size: 'full',
                    image: 'loading',
                    text: null,
                    progress: 0,
                    order: orderCount + 1
                }

				this.section.items.push(item)

				var fd = this.objectToFormData({
                    file: file,
                    content_id: this.uid,
                    content_type: 'Post'
                })

                this.uploadImage(fd, {uid: uid}).then((resp) => {
                    if (resp.error) {
                        console.log('uploading gallery image error', resp.error);
						reject(null)
                    }
                    else {
						var item = this.$findFromUID(this.section.items, resp.data.uid)
						item.image = resp.image
						this.update({items: this.section.items});
                        resolve(item)
                        console.log(`image added to galler`, resp.image);
                    }
                })
        	})
        }
    },
    computed: {
    	sectionKey() {
    		return this.section.uid
    	},
    	hasImages() {
    		return this.orderedImages.length > 0
    	},
		gallerySizeClass() {
			console.log('this.section.gallery_size', this.section.gallery_size);
			var size = this.section.gallery_size || '3by'
			return this.gallerySizes[size];
		},
    	orderedImages: {
    		get() {
	    		if (this.section) {
	    			return _.orderBy(_.filter(this.section.items, (item, k) => {
	    				return item;
	    			}), ['order'])
	    		}
	    		return []
    		},
    		set(items) {
				console.log('set items', items);
    			// for (var i = 0; i < items.length; i++) {
				// 	this.$db.postRef(this.uid).update(`sections.${this.sectionKey}.items.${items[i]['.key']}.order`, i)
    			// }
    		}
    	},
	},
    watch: {
    },
    created() {
    },
    mounted() {





    }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';
.page-gallery-gui {

}
.gallery-image {
	transition: all 200ms $easing;
	@include until(500) {
		width: 100%;
	}
	&.item-ghost {
		opacity: 0.5;
	}
	&.list-complete-enter, .list-complete-leave-active {
  		opacity: 0;
	}
}
.image {
	&.is-loading {
		background-color: #eee;
		min-height: 100px;
		@include flexCenter();
	}
	.gallery-image-gui {
		transition: all 200ms $easing;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		color: white;
		background-color: rgba($black-color, 0.6);
		border: none;
	}
	&:hover {
		.gallery-image-gui {
			opacity: 1;
		}
	}
}
.image-details-modal {
	.image img {
		max-width: 300px;
		margin: 0 auto;
	}
}

</style>
