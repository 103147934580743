<template>
    <span>
	    <span v-if="hasText">
            {{text}}
        </span>
        <template v-if="icon=='loading'">
            <span class="is-loading-image"></span>
        </template>
        <template v-else>
            <span class="icon" :class="size">
    	    	<i class="fa" :class="getIcon"></i>
    	    </span>
        </template>
    </span>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'Icon',
    props: {
    	icon: {
    		default: ''
    	},
    	size: {
    		default: ''
    	},
    	text: {
    		default: null
    	}
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
        hasText() {
            return !_.isNil(this.text);
        },
        getIcon() {
            if (this.icon == 'loading') {
                return 'fa-cog fa-spin'
            }
            return this.icon
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';
.is-loading-image {
    @include loader();
}
</style>
