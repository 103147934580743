<template>
    <section class="section page-navigation">
        <div class="container">
            <div class="columns">
                <div class="column" :class="columnSize('normal', sectionSizes)">
                    <nav class="level page-nav">
                        <!-- Left side -->
                        <div class="level-left">
                            <div class="level-item" v-if="prevProject._id">
                                <router-link class="is-link" :to="{path:`/page/${prevProject.slug}`}">
                                    <icon icon="fa-chevron-left"></icon>
                                </router-link>
                            </div>
                        </div>

                        <!-- Right side -->
                        <div class="level-right">
                            <div class="level-item" v-if="nextProject._id">
                                <router-link class="is-link" :to="{path:`/page/${nextProject.slug}`}">
                                    <icon icon="fa-chevron-right"></icon>
                                </router-link>
                            </div>
                        </div>

                    </nav>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PageNavigation',
    props: {
    	prevProject: {
    		required: true
    	},
    	nextProject: {
    		required: true
    	}
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
// page navigation 
// back to projects (home)
// <-- -->
// ------------------------------------------------------------------------
.page-navigation {
    padding-bottom: 10px;
    padding-top: 10px;
    .group {
        padding-top: 10px;
        box-shadow: none;
        &:hover {
            box-shadow: none;
        }
    }
}
.page-nav {
    display: flex;
    font-family: $heading-font;
    min-height: 30px;
    .level-right, .level-left {
        display: flex;
        margin-top: 0;
        .level-item {
            display: flex;
            margin-bottom: 0;
        }
    }
    .is-link {
        transition: color 200ms $easing;
        color: rgba($black-color, 0.5);
        &:hover {
            color: $black-color;
        }
    }
    .level-right .level-item {
        &:last-child {
            margin-left: 10px;
        }
    }
}
</style>