<template>
    <div class="list-item is-mobile level">
        <div class="level-left">
            <div class="level-item">
                <list-image :url="url" :image="image" :size="size"></list-image>
                <slot v-if="$hasSlot('details')" name="details"></slot>
                <!-- <p>
                    <strong>{{getPostTitle(post) || 'Untitled'}}</strong><br>
                    <template>{{post.created_at | fromnow}}</template><br>
                    <small>{{post._id}}</small>
                    <small>{{post.status}}</small>
                </p> -->
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
                <div v-if="$hasSlot('controls')" class="control is-grouped">
                    <slot name="controls"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageMixins from '@/mixins/page-mixins'
export default {
    name: 'ProductPageItem',
    mixins: [PageMixins],
    props: {
        image: {
            type: Object,
            default: () => {
                return null;
            }
        },
        url: {
            type: String,
            default: () => {
                return null;
            }
        },
        size: {
            type: Number,
            default: 80
        },
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import '~@/styles/mixins';
@import '~@/styles/variables';
</style>
