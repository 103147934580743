<template>
    <div id="app" :class="{'in-edit-mode': $store.state.inEditMode, 'sidebar-menu-active':$store.state.sideMenuActive, 'is-admin-page':isAdminPage, 'is-post-page':isPostPage}">

        <admin-sidebar-nav></admin-sidebar-nav>

        <hamburger v-if="authenticated"></hamburger>

        <transition name="page" mode="out-in" appear>
            <router-view class="animated app-main"></router-view>
        </transition>

    </div>
</template>

<script>
import AdminSidebarNav from "./components/layout/AdminSidebarNav";
import Hamburger from "./components/layout/Hamburger";
export default {
    name: "App",
    data() {
        return {};
    },
    components: {
        Hamburger,
        AdminSidebarNav,
    },
    watch: {},
    methods: {
        closeSideMenu() {
            this.$store.commit("sideMenuActive", false);
        },
    },
    computed: {},
    mounted() {},
};
</script>

<style lang="scss">
@import "./styles/main.scss";
#app {
    min-height: 100vh;
}
</style>
