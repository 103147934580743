<template>
    <div class="control">
        {{required}}
        <label class="label">{{label}}</label>
        <input class="input" type="text" :placeholder="placeholder" required :autocomplete="autocomplete">
    </div>
</template>
<script>
export default {
    name: 'StoreInputField',
    props: {
        label: {
            default: null
        },
        placeholder: {
            default: null
        },
        autocomplete: {
            default: null
        },
        required: {
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {

    },
    methods: {

    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    destroyed() {

    },
    mounted() {

    }
}
</script>


<style scoped lang="scss">
@import '~@/styles/mixins';
@import '~@/styles/variables';
</style>