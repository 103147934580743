    <template>
    <div>
        <div class="level-left">
            <div class="level-item">
                <router-link :to="`/page/${post.slug}/edit`">
                    <figure class="image" v-if="post.image">
                        <img :src="imageURL(post.image, 's80-c')" alt="">
                    </figure>
                    <div v-else class="missing-post-image">
                        <icon icon="fa-photo"></icon>
                    </div>
                </router-link>
                <p>
                    <strong>{{getPostTitle(post) || 'Untitled'}}</strong><br>
                    <template>{{post.created_at | fromnow}}</template><br>
                    <small>{{post._id}}</small>
                    <small>{{post.status}}</small>
                </p>
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
                <div class="control is-grouped">
                    <router-link :to="`/page/${post.id}/edit`" class="button">
                        <icon icon="fa-pencil"></icon>
                    </router-link>
                    <a @click="movePostToTrash(post)" class="button is-danger">
                        <icon icon="fa-trash"></icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageMixins from "@/mixins/page-mixins";
export default {
    name: "ContentPagePostItem",
    mixins: [PageMixins],
    props: {
        post: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {
        movePostToTrash(post) {
            this.setPostStatus(post._id, "trash").then(() => {
                this.$emit("trashed", post);
                console.log("trashed");
            });
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.add-content {
    margin-top: 40px;
    .plus-button {
        margin: 0 auto;
    }
}
.title {
    width: 100%;
    font-family: $heading-font;
}
.page-status {
    color: $medium-gray;
    text-transform: capitalize;
}
.content-group {
    margin-top: 40px;
}
</style>
