<template>
    <draggable v-model="orderItems" :options="{ghostClass: 'item-ghost'}" :isEditable="isEditable">
        <li class="is-clearfix" v-for="(item, index) in orderItems" @mouseover="() => { $emit('mouseOverItem', item) }" @mouseout="() => { $emit('mouseOutItem', item) }" :key="index">
            <span class="tag is-help-text is-pulled-left">{{item.type}}</span>
            <span class="tag is-help-text is-pulled-right">{{item.order}}</span>
        </li>
    </draggable>
</template>

<script>
export default {
    name: 'EditPageSectionItems',
    props: {
        value: {
            type: Array,
            required: true
        },
        sectionKey: {
            type: String,
            required: true
        },
        isEditable: {
            default: false
        }
    },
    data() {
        return {

        }
    },
    components: {
    },
    methods: {
    },
    computed: {
        orderItems: {
            get() {
                return _.orderBy(this.value, ['order'])
            },
            set(items) {
                for (var i = 0; i < items.length; i++) {
                    items[i].order = i
                }
                this.$emit('updateItemsOrder', {items:items, sectionKey:this.sectionKey})
            }
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>