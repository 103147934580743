<template>
    <img v-if="image" :src="src" :alt="image.altText">
</template>

<script>
import { StoreMixins } from "@/mixins";
export default {
    name: "ShopifyImage",
    mixins: [StoreMixins],
    props: {
        small: {
            type: String,
            default: "",
        },
        large: {
            type: String,
            default: "",
        },
        image: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            src: this.getShopifyImageURL(this.image, this.small),
        };
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {
        var img = new Image();
        img.onload = () => {
            this.src = img.src;
        };
        img.src = this.getShopifyImageURL(this.image, this.large);
    },
};
</script>

<style scoped lang="scss">
</style>
