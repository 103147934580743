export default function() {
    var origin = document.location.origin
    var subdomain = origin.split('://')[1].split('.')[0]
    var domain = origin.split('.')[1]
    // console.log('origin', origin);
    // console.log('subdomain', subdomain);
    // console.log('domain', domain);
    if (subdomain === 'store' || domain === 'nilrednav' || process.env.VUE_APP_SITE_ROOT === 'store') {
        return true;
    }
    return false
}
