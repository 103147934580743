<template>
    <admin-layout :isLoaded="isLoaded">
        <b-tabs v-model="activeTab" class="section" @change="clickTab">
            <b-tab-item label="Published">
                <div class="columns is-multiline">
                    <div class="column is-12-tablet list">
                        <div class="list-item is-mobile level" v-for="file in orderedFiles" :key="file._id">
                            <div class="level-left">
                                <div class="level-item">
                                    <figure class="image" v-if="file.serving_url">
                                        <img :src="`${file.serving_url}=s80-c`" alt="">
                                    </figure>
                                    <div class="details">
                                        <p class="title is-6">{{file.filename}}</p>
                                        <p class="title is-7 has-text-weight-normal">
                                            UID: {{file._id}}
                                            <icon icon="fa-copy" v-clipboard="file._id"></icon>
                                        </p>
                                        <p class="title is-7 has-text-weight-normal">
                                            <template>{{file.created_at | fromnow}}</template>
                                        </p>
                                        <p class="title is-7 has-text-weight-normal">
                                            <template>{{file.width}}x{{file.height}}</template>
                                        </p>
                                        <template v-if="hasCotentRef(file)">
                                            <router-link :to="{path: `/page/${file.content.slug}`}" class="title is-7 has-text-weight-normal"><span v-html="file.content.title"></span></router-link>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="level-right">
                                <div class="level-item">
                                    <div class="control is-grouped">
                                        <router-link :to="`/page/${file._id}/edit`" class="button">
                                            <icon icon="fa-pencil"></icon>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Add" icon="upload">
            </b-tab-item>
        </b-tabs>
    </admin-layout>
</template>

<script>
import _ from 'lodash'
import {ImageUploaderMixins} from '@/mixins'
import ContentPageItem from '@/components/content-page/ContentPageItem'
export default {
    name: 'FilesPage',
    mixins: [ImageUploaderMixins],
    props: {},
    data() {
        return {
            posts: null,
            files: null,
            moreInfoToggles: {},
            filesRef: null,
        	contentRef: null,
            isLoadingImage: false,
            isLoaded: false,
            activeTab: 0,
        }
    },
    components: {
        ContentPageItem
    },
    methods: {
    	clickTab(tab) {
        },
        hasCotentRef(file) {
            return (file.content instanceof Object)
        },
        openFile(f) {
            this.$router.push({path: `/page?id=${f.content_id}`})
    	},
        deleteFile(file) {
            var index = _.findLastIndex(this.files, {_id: file._id});
            this.files.splice(index, 1);

            this.removeImage(file).then((e) => {
                console.log('remove at index '+index);
            }).catch(err => {
                console.log(err);
            })
        },
        hasPage(key) {
            return this.content[key]
        },
        thumbnail(image) {
            if (image) {
                return this.servingImage(image, 's64-c')
            }
            return `https://placehold.it/100x100?text=+${image['.key']}`
        },
        getImageFromKey(key) {
            for (var i = 0; i < this.files.length; i++) {
                if(this.files[i]['.key'] === key) {
                    return this.files[i]
                }
            }
            return null
        },
        handleFileUpload(e) {
            this.isLoadingImage = true;
            this.hasSingleFile(e).then(file => {
                var fd = new FormData();
                fd.append('file', file)
                this.uploadImage(fd).then(asset => {
                    this.isLoadingImage = false;
                    this.files.push(asset)
                }).catch(err => {
                    this.isLoadingImage = false;
                    console.log(err);
                })
            })
        },
        updateAsset(imageID, payload) {
            return new Promise((resolve, reject) => {
                this.$api.put(`assets/${imageID}`, payload).then(() => {
                    resolve()
                })
            })
        }
    },
    computed: {
        orderedFiles() {
            return _.orderBy(this.files, ['created_at'], ['desc']);
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
        var p2 = new Promise((resolve, reject) => {
            this.$api.get('assets?with=content&limit').then((e) => {
                if (!e.error) {
                    this.files = e.data
                }
                resolve()
            })
        })
        Promise.all([p2]).then(() => {
            this.isLoaded = true
        })
    }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';
li.is-missing-post {
    background-color: $red-color;
}
.level-button {
    background: #ddd;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    &:hover {
        background: #eee;
    }
}
.more-info-button {
    justify-content: center;
    align-items: center;
    display: flex;
}
.content-list {
    .more-info {
        display: none;
        background: #f9f9f9;
        padding: 10px;
        overflow: scroll;
        &.is-open {
            display: inherit;
        }
    }
}
.list-heading {
    display: flex;
    flex-direction: row;
    .title {
        margin-bottom: 0;
    }

}
.upload-button {
    margin-left: auto;
    border: none;
    top: -8px;
    // margin-bottom: 1.5em;
    .icon:first-child:not(:last-child) {
        margin-left: 0;
        margin-right: 0;
    }
    @include flexCenter();
}
</style>
