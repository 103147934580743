export default [
	{
		name: 'github',
		icon: 'fa-github',
		link: ''
	},
	{
		name: 'instagram',
		icon: 'fa-instagram',
		link: ''
	},
	{
		name: 'twitter',
		icon: 'fa-twitter',
		link: '',
	},
	{
		name: 'vimeo',
		icon: 'fa-vimeo',
		link: ''
	},
	{
		name: 'medium',
		icon: 'fa-medium',
		link: ''
	},
	{
		name: 'facebook',
		icon: 'fa-facebook',
		link: ''
	},
	{
		name: 'google',
		icon: 'fa-google',
		link: ''
	},
	{
		name: 'linkedin',
		icon: 'fa-linkedin',
		link: ''
	},
	{
		name: 'flickr',
		icon: 'fa-flickr',
		link: ''
	},
]
