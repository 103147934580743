<template>
    <nav class="nav-bar subnav">
        <div class="container">
            <div class="nav-bar-container">

                <div class="nav-bar-side nav-bar-left">
                    <!-- <router-link class="nav-bar-item site-nav-link" :to="getStoreLink('')"><b>Nilrednav Store</b></router-link> -->
                </div>

                <div class="nav-bar-side nav-bar-center">
                    <router-link v-for="collection in collections" :key="collection.id" class="nav-bar-item site-nav-link" :to="getStoreLink(`collections/${collection.handle}`)">
                        {{collection.title}}
                    </router-link>
                </div>

                <div class="nav-bar-side nav-bar-right">

                </div>

            </div>
        </div>
    </nav>
</template>

<script>
import { StoreMixins } from "@/mixins";
export default {
    name: "StoreSubNav",
    mixins: [StoreMixins],
    props: {
        collections: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    async mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.number-of-items {
    font-size: $size-7;
    @include square(20px);
    @include flexCenter;
    border-radius: 50%;
    border: 2px solid $lighter-gray;
    top: 1px;
    position: relative;
    span {
        position: relative;
        top: -1px;
    }
    &.is-empty {
        opacity: 0;
    }
}
.currency {
    font-family: $heading-font;
    margin-left: 5px;
}
.subnav {
    a {
        margin-left: 15px;
        margin-right: 15px;
        font-size: 13px;
        text-align: center;
        &.is-active {
            font-weight: 700;
        }
    }
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
