<template>
    <div class="columns is-multiline">
        <div class="column item" :class="itemClasses(item)" v-for="(item) in orderItems" :key="item.uid">

            <!-- item gui -->
            <item-gui :isEditable="isEditable" :item="item" :moveItem="moveItem" @removeItem="removeItem" @updateVideoID="updateValue" @setItemSize="setItemSize">
            </item-gui>
            <!-- item gui -->

            <div class="content" :class="{[`content-type-${item.type}`]: true}">

                <template v-if="item.type === 'text'">
                    <text-input v-model="item.text" :isEditable="isEditable" :useRichEditor="true" @input="updateValue"></text-input>
                </template>

                <template v-if="item.type === 'image'">
                    <figure class="item-image image" :class="{'is-editable': isEditable}">
                        <template v-if="!imageIsLoaded(item)">
                            <div class="loading-image">
                                <span class="icon is-large">
                                    <i class="fa fa-photo"></i>
                                </span>
                                <i>{{uploadProgress(item)}}</i>
                            </div>
                        </template>
                        <template v-if="imageIsLoaded(item)">
                            <page-image :image="item.image" :alt="item.description"></page-image>
                            <item-caption v-model="item.caption" @input="updateValue" :isEditable="isEditable"></item-caption>
                        </template>
                    </figure>
                </template>

                <template v-if="item.type === 'video'">
                    <video-player :id="item.video" v-model="item.video" @updateVideoID="updateValue"></video-player>
                    <item-caption v-if="item.caption" v-model="item.caption" @input="updateValue" :isEditable="isEditable"></item-caption>
                </template>

            </div>

        </div>

        <!-- no items -->
        <div class="column is-12 has-text-centered empty-items" :class="{'is-empty': !hasItems}" v-if="isEditable">
            <div class="empty-items-container">
                <h1 class="is-4" v-if="!hasItems">Nothing yet but go ahead and add something.</h1>
                <add-item :sectionKey="sectionKey" @addItem="addItem" :itemsCount="orderItems.length" :dropdown="false"></add-item>
            </div>
        </div>
        <!-- no items -->
    </div>
</template>

<script>
import _ from "lodash";
import ItemGui from "./ItemGui";
import PageImage from "./PageImage";
import ItemCaption from "./ItemCaption";
import { ImageUploaderMixins } from "@/mixins";
import _move from "lodash-move"; // until add to lodash
export default {
    name: "SectionItems",
    mixins: [ImageUploaderMixins],
    props: {
        sectionItemMousedOver: {
            default: null,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
        postKey: {
            type: String,
            required: true,
        },
        sectionKey: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        addItem: {
            type: Function,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            itemsRef: null,
            items: null,
            draggableOptions: {
                handle: ".item-gui",
                draggable: ".item",
                ghostClass: "item-draggable",
            },
        };
    },
    components: {
        ItemGui,
        PageImage,
        ItemCaption,
    },
    methods: {
        showSuccess(e) {
            console.log(e);
        },

        saveItemCaption(caption, itemKey) {
            var item = _.find(this.value, { uid: itemKey });
            if (item) {
                item.caption;
            }
            //this.itemsRef.child(itemKey).update({caption: caption})
            // var path = `sections.${this.sectionKey}.items.${itemKey}.caption`
            // this.$db.postRef(this.postKey).update(path, caption)
            // console.log('saved item caption', caption);
            console.log(caption, itemKey);
        },

        // save the text of the item
        updateValue() {
            console.log(this.value);
            this.$emit("update", { sectionKey: this.sectionKey, items: this.value });
        },

        // set the item size
        // e.item
        // e.name (size value)
        // -------------------------------------
        setItemSize(e) {
            var item = _.find(this.value, { uid: e.item.uid });
            if (item) {
                item.size = e.name;
                this.$emit("update", { sectionKey: this.sectionKey, items: this.value });
            }
        },

        // remove item
        // -------------------------------------
        async removeItem(item) {
            /*console.log('trying to remove this item', item.uid);

            var items = _.filter(this.value, async (sectionItem) => {
                if (sectionItem.uid != item.uid) {
                    return sectionItem
                }
                else {
                    // removing an the image if we are type 'image'
                    if (sectionItem.type == 'image') {
                        if (item.image) {
                            try {
                                await this.removeImage(item.image);
                            }
                            catch(error) {
                                console.log('Error removing image', error);
                            }
                        }
                    }
                }
            })*/
            this.$emit("remove", { sectionKey: this.sectionKey, itemKey: item.uid });
        },

        // move item
        // uid
        // dir +1 -1
        // -------------------------------------
        moveItem(key, dir) {
            var currIndex = -1;

            var currentOrder = this.orderItems.map((item) => {
                if (item.uid == key) {
                    currIndex = parseInt(item.order);
                }
                return item.uid;
            });
            // move to
            var moveIndex = currIndex + dir;

            // can we move
            if (moveIndex < currentOrder.length) {
                var results = _move(currentOrder, currIndex, moveIndex);
                for (var i = 0; i < results.length; i++) {
                    var itemKey = results[i];
                    var item = _.find(this.value, { uid: itemKey });
                    if (item) {
                        item.order = i;
                    }
                }
            }
            console.log("Current Index", currIndex, "Move Index", moveIndex);
            this.$emit("update", { sectionKey: this.sectionKey, items: this.value });
        },

        itemClasses(item) {
            return { [this.columnSize(item.size, this.itemSizes)]: true, "is-editable": this.isEditable, "is-moused-over": this.sectionItemMousedOver == item.uid };
        },

        imageIsLoaded(item) {
            return item.image && item.image !== "loading";
        },

        uploadProgress(item) {
            if (item.progress) {
                return parseInt(item.progress, 0) + "%";
            }
            return "Loading...";
        },
    },
    computed: {
        hasItems() {
            return this.orderItems.length;
        },
        orderItems: {
            get() {
                if (this.value) {
                    for (var i = 0; i < this.value.length; i++) {
                        if (this.value[i].order === undefined || this.value[i].order === null) {
                            this.value[i].order = i;
                        }
                    }
                }
                return _.orderBy(this.value, "order");
            },
            set(list) {
                console.log("set sections-list", list);
            },
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";
@import "../../styles/mixins";

.item {
    position: relative;

    &.is-editable {
        &.is-moused-over {
            @include edit-border($green-highlight);
        }
        .content {
            margin-top: 50px;
            &.content-type-text {
                &:hover {
                    @include edit-border($green-highlight);
                }
            }
            &.content-type-image {
                position: relative;
                &:after {
                    transition: all 100ms;
                    pointer-events: none;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                &:hover {
                    &:after {
                        @include edit-border($green-highlight);
                    }
                }
            }
        }
        &.item-draggable {
        }
        &:hover {
            .item-gui {
                opacity: 1;
            }
        }
    }
    .item-image {
    }
}

.empty-items {
    min-height: 55px;
    opacity: 0;
    transition: opacity 200ms;
    &.is-empty {
        min-height: 250px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .empty-items-container {
        margin-top: -20px;
    }
}
// ------------------------------------------------------------------------
.item-image {
    position: relative;
    margin: 0;
    &.is-editable {
    }
    .image-caption-editor {
        min-height: 40px !important;
    }
}
</style>
