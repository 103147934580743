import config from './config'
import firebase from 'firebase'
import shortid from 'shortid'

console.log('ENV', config.env);
firebase.initializeApp(config.firebase);
firebase.analytics();

const db = {
	collection: (path, env) => {
		env = env == null ? config.env + '-' : env
		return firebase.firestore().collection(`${env}${path}`)
	},
	doc: (path, env) => {
		env = env == null ? config.env : env
		return firebase.firestore().doc(`${env}/${path}`)
	},
	collectionGroup: (path) => {
		return firebase.firestore().collectionGroup(path)
	},
	batch() {
		return firebase.firestore().batch()
	},
	uid() {
		return shortid()
	},
}

export default db

export const rtdb = {
	ref: (path) => {
		return firebase.database().ref(`${config.env}-${path}`)
	},
}
