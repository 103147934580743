<template>
    <div class="section-sizes">
        <a  v-for="(size, name) in sectionSizes" class="navbar-item is-small" :class="{'is-active': value==name}" @click="() => { $emit('input', name)}">{{name}}</a>
    </div>
</template>
<script>
export default {
    name: 'SectionSizes',
    props: {
        value: {
            required: true
        }
    },
    data() {
        return {

        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
.section-sizes {
    display: flex;
}
</style>
