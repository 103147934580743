<template>
    <admin-layout :isLoaded="isLoaded" :crumbs="crumbs">

        <image-modal ref="imageModal"></image-modal>

        <div v-if="isLoaded" class="columns is-multiline">

            <!-- Product Information -->
            <form-card column="is-12" :title="`Information${product.sold_out?': SOLD OUT':''}`">

                <div slot="headerAction">
                    <select-control v-model="product.status" :options="productStatusOptions"></select-control>
                </div>

                <div slot="content" class="columns is-multiline">

                    <!-- Name and caption -->
                    <div class="column is-half">
                        <div class="field">
                            <div class="control is-expanded">
                                <label class="label">Name</label>
                                <input class="input" v-validate="'required'" name="name" type="text" v-model="product.name" placeholder="Name of product">
                                <span v-show="formErrors.has('name')" class="help is-danger">{{ formErrors.first('name') }}</span>
                            </div>
                            <div class="control is-expanded">
                                <label class="label">SKU</label>
                                <input class="input" name="sku" type="text" v-model="product.sku" placeholder="Product SKU (auto filled on create)">
                                <span v-show="formErrors.has('sku')" class="help is-danger">{{ formErrors.first('sku') }}</span>
                            </div>
                            <div class="control is-expanded">
                                <label class="label">Caption</label>
                                <input class="input" name="caption" type="text" v-validate="'required'" v-model="product.caption" placeholder="One line description (optional)">
                                <span v-show="formErrors.has('caption')" class="help is-danger">{{ formErrors.first('caption') }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- end name and caption -->

                    <!-- quantity and price -->
                    <div class="column is-half">
                        <div class="field is-grouped">
                            <div class="control is-expanded">
                                <label class="label">Quantity</label>
                                <input class="input" name="quantity" v-validate="'required|numeric'" type="number" step="1" v-model="product.quantity">
                                <span v-show="formErrors.has('quantity')" class="help is-danger">{{ formErrors.first('quantity') }}</span>
                            </div>
                            <div class="control">
                                <label class="label">&nbsp;</label>
                                <label class="checkbox">
                                    <input type="checkbox" v-model="product.is_orignal">
                                    Original
                                </label>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control is-expanded">
                                <label class="label">Price</label>
                                <cleave v-model="productPrice" v-validate="'required|min_value:1'" :options="inputOptions.currency" class="input" name="price" data-vv-value-path="value" data-vv-name="price">
                                </cleave>
                                <span v-show="formErrors.has('price')" class="help is-danger">{{ formErrors.first('price') }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- end quantity and price -->

                    <!-- Diminsions -->
                    <div class="column is-12">
                        <div class="field is-grouped">
                            <div class="control is-expanded">
                                <label class="label">Diminsions <small class="is-muted">(in)</small></label>
                                <input class="input" name="width" v-validate="{required: true, decimal:true}" type="text" placeholder="Width (in)" v-model="product.width">
                                <span v-show="formErrors.has('width')" class="help is-danger">{{ formErrors.first('width') }}</span>
                            </div>
                            <div class="control is-expanded">
                                <label class="label">&nbsp;</label>
                                <input class="input" name="height" v-validate="'required|decimal:1'" type="text" placeholder="Height (in)" v-model="product.height">
                                <span v-show="formErrors.has('height')" class="help is-danger">{{ formErrors.first('height') }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="column is-12">
                        <div class="field">
                            <div class="control">
                                <label class="label">Medium</label>
                                <input class="input" name="medium" v-validate="{required: true}" type="text" placeholder="What is this thing made out of?" v-model="product.medium">
                                <span v-show="formErrors.has('medium')" class="help is-danger">{{ formErrors.first('medium') }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- end Diminsions -->

                </div>

            </form-card>

            <!-- Product hero image -->
            <form-card title="Hero Image" column="is-6">
                <div slot="content" class="card-content">
                    <div class="preview-images">
                        <figure class="image" v-if="product.hero_image">

                            <smart-image :image="product.hero_image" :size="120" alt=""></smart-image>

                            <div class="buttons">
                                <a class="is-link is-round-icon-button" @click.prevent="removeHeroImage()">
                                    <span class="icon">
                                        <i class="fa fa-trash"></i>
                                    </span>
                                </a>
                                <a class="is-link is-round-icon-button" @click.prevent="previewImage(product.hero_image)">
                                    <span class="icon">
                                        <i class="fa fa-search"></i>
                                    </span>
                                </a>
                            </div>
                        </figure>
                        <div v-else>
                            <small class="help is-muted">No Hero image will default to first image in gallery</small>
                        </div>
                    </div>
                    <!-- Preview hero image for upload -->
                    <div class="preview-images">
                        <figure class="image" v-if="heroPreviewImage">
                            <img :src="heroPreviewImage.src" alt="">
                            <div class="buttons">
                                <a class="is-link is-round-icon-button" @click.prevent="() => {heroPreviewImage = null}">
                                    <span class="icon">
                                        <i class="fa fa-times"></i>
                                    </span>
                                </a>
                            </div>
                        </figure>
                    </div>

                    <image-upload-button @handleImages="handleHeroImage" class="is-flex is-flex-centered" name="hero_image" :multiple="false"></image-upload-button>

                </div>
            </form-card>
            <!-- end Product hero image -->

            <!-- Product Images -->
            <form-card title="Images" column="is-6">
                <div slot="content" class="card-content">
                    <div class="field">

                        <draggable class="preview-images" v-model="orderedImages" :options="{ghostClass: 'item-ghost'}" classNames="preview-sortable-images-list" :isEditable="true">
                            <figure class="image" :key="i" v-for="(image, i) in orderedImages">

                                <smart-image :image="image" :size="120" alt=""></smart-image>

                                <div class="buttons">
                                    <a class="is-link is-round-icon-button" @click.prevent="removeProductImage(image, i)">
                                        <span class="icon">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </a>
                                    <a class="is-link is-round-icon-button" @click.prevent="previewImage(image, i)">
                                        <span class="icon">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </a>
                                </div>

                            </figure>
                        </draggable>

                        <!-- Preview Images for upload -->
                        <div class="preview-images">
                            <figure class="image" :key="i" v-for="(image, i) in previewImages">
                                <img :src="image.src" alt="">
                                <div class="buttons">
                                    <a class="is-link is-round-icon-button" @click.prevent="removePreviewImage(image, i)">
                                        <span class="icon">
                                            <i class="fa fa-times"></i>
                                        </span>
                                    </a>
                                </div>
                            </figure>
                        </div>

                        <image-upload-button class="is-flex is-flex-centered" @handleImages="handleImages"></image-upload-button>

                    </div>
                </div>
            </form-card>
            <!-- End Product Images -->

            <!-- Description -->
            <div class="field column is-12">
                <label class="label">Description</label>
                <div class="control">
                    <textarea class="textarea" name="description" v-validate="'required'" v-model="product.description" placeholder="Products description"></textarea>
                    <span v-show="formErrors.has('description')" class="help is-danger">{{ formErrors.first('description') }}</span>
                </div>
            </div>
            <!-- end description -->

            <!-- form submit -->
            <div class="field column is-12">
                <div class="control">
                    <div class="buttons is-flex-centered">
                        <button class="button" @click.prevent="handleProductUpdate">{{`${uid ? 'Update' : 'Create'}`}}</button>
                        <router-link :to="getProductURL(product)" class="button" v-if="uid" @click.prevent="handleProductUpdate">View</router-link>
                    </div>
                </div>
                <div class="control">
                    <span class="help has-text-centered">{{product.updated_at | fromnow}}</span>
                </div>
            </div>
            <!-- end form submit -->
            <pre>
{{product}}
</pre>
            <errors :errors="errors"></errors>
        </div>

    </admin-layout>
</template>

<script>
import _ from "lodash";
import { ProductMixins, ImageUploaderMixins } from "@/mixins";
export default {
    name: "AdminProductCreatePage",
    props: {},
    mixins: [ProductMixins, ImageUploaderMixins],
    data() {
        return {
            errors: null,
            isLoaded: false,
            previewImages: [],
            heroPreviewImage: null,
            inputOptions: {
                currency: {
                    prefix: "$ ",
                    numeral: true,
                    numeralPositiveOnly: true,
                    noImmediatePrefix: true,
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 9,
                    numeralDecimalScale: 2,
                },
            },
            crumbs: [
                { name: "Admin", path: "/admin" },
                { name: "Products", path: "/admin/products" },
            ],
            product: this.defaultProduct(),
            productTypes: [],
        };
    },
    components: {},
    methods: {
        // hero single image
        // --------------------------------------------------
        handleHeroImage(e) {
            console.log(e);
            this.hasSingleFile(e).then((file) => {
                this.readFile(file).then((obj) => {
                    this.heroPreviewImage = obj;
                });
            });
        },

        // these are images for the product gallery
        // --------------------------------------------------
        handleImages(e) {
            this.hasFiles(e).then((files) => {
                for (var i = 0; i < files.length; i++) {
                    this.readFile(files[i]).then((obj) => {
                        this.previewImages.push(obj);
                    });
                }
            });
        },
        removePreviewImage(image, index) {
            this.previewImages.splice(index, 1);
        },
        previewImage(image) {
            var modal = this.$refs.imageModal;
            modal.open({ src: image.serving_url + "=s1024" });
        },
        removeProductImage(image, index) {
            this.removeImage(image).then(() => {
                this.$delete(this.product.images, index);
                this.postProduct();
            });
        },
        removeHeroImage() {
            this.removeImage(this.product.hero_image).then(() => {
                this.product.hero_image = undefined;
                this.postProduct();
            });
        },

        // binding products
        // --------------------------------------------------
        bindData() {
            this.errors = [];
            if (this.uid) {
                this.$api.get(`products/${this.uid}`).then((e) => {
                    if (e.data.error) {
                        this.errors.push(e.data.error);
                    } else {
                        this.product = { ...this.product, ...e.data };
                        this.crumbs.push({ name: this.product._id, path: null, active: true });
                    }
                    console.log("loaded product");
                    console.log(e.data);
                    this.isLoaded = true;
                });
            } else {
                this.isLoaded = true;
            }
        },

        // --------------------------------------------------
        resetPostButton(event) {
            setTimeout(function () {
                if (event && event.target) event.target.classList.remove("is-loading");
            }, 300);
        },
        parseProductData() {
            var data = { ...this.product };
            data.images = _.map(data.images, (i) => {
                return i._id;
            });
            data.hero_image = data.hero_image ? data.hero_image._id : undefined;

            if (data.width) {
                data.width = parseFloat(data.width);
            }
            if (data.height) {
                data.height = parseFloat(data.height);
            }
            delete data.stripe_sku;
            return data;
        },
        handleProductError(err, target) {
            if (target) {
                target.classList.remove("is-loading");
            }
            this.errors = err;
        },
        handleProductUpdate(e) {
            // we must have images
            if (this.product.images.length == 0 && this.previewImages.length == 0) {
                this.$swal({ icon: "warning", title: "Oppps", text: "Sorry but you need to have at least one image" });
                return;
            }
            this.$validator.validateAll().then((result) => {
                console.log(result);
                if (result) {
                    this.postProduct(e);
                }
            });
        },
        postProduct(event) {
            this.errors = null;
            var target = event ? event.target : null;
            var data = this.parseProductData(this.product);

            var files = _.map(this.previewImages, (i) => {
                return i.file;
            });
            var fd = this.objectToFormData({ ...data });

            // add the gallery images
            if (files.length) {
                for (var i = 0; i < files.length; i++) {
                    fd.append("images", files[i]);
                }
            }

            // add the hero image
            if (this.heroPreviewImage) {
                fd.append("hero_image", this.heroPreviewImage.file);
            }

            if (target) {
                target.classList.add("is-loading");
            }

            // update a existing product
            if (this.uid) {
                this.updateProduct(this.uid, fd)
                    .then((product) => {
                        this.product = product;
                        this.resetPostButton(event);
                        this.previewImages = [];
                        this.heroPreviewImage = null;
                    })
                    .catch((err) => {
                        this.resetPostButton(event);
                        this.handleProductError(err, target);
                    });
            }

            // create a new product
            else {
                this.createProduct(fd)
                    .then((product) => {
                        this.$router.push(`/admin/products/${product._id}`);
                        this.resetPostButton(event);
                        this.previewImages = [];
                        this.heroPreviewImage = null;
                    })
                    .catch((err) => {
                        this.resetPostButton(event);
                        this.handleProductError(err, target);
                    });
            }
        },
    },
    computed: {
        uid() {
            return this.$route.params.id;
        },
        orderedImages: {
            get() {
                if (!this.product) {
                    return [];
                }
                return this.product.images;
            },
            set(images) {
                console.log(images);
                this.product.images = images;
            },
        },
        productPrice: {
            get() {
                if (this.product.price === 0) return null;
                return this.product.price / 100;
            },
            set(value) {
                this.product.price = value * 100;
            },
        },
        selectedProductType() {
            var index = _.findLastIndex(this.productTypes, { id: this.product.stripe_product_id });
            if (index > -1) {
                return this.productTypes[index];
            }
            return null;
        },
    },
    watch: {
        uid() {
            this.bindData();
        },
    },
    created() {},
    mounted() {
        this.bindData();
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
</style>
