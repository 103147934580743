<template>
    <div>

        <!-- loading page  -->
        <loading-page :isLoading="!isLoaded"></loading-page>

        <template v-if="collections.length">
            <section class="section" v-for="collection in orderedCollections" :key="collection.id">
                <products-view :products="collection.products" :handle="collection.handle" :uid="collection.id" :title="collection.title"></products-view>
            </section>
        </template>
        <!-- loading page -->

        <!--<div class="hero is-large store-hero">
            <div class="hero-body">
                <div class="container">
                    <div class="subtitle has-text-black-ter">Drawings for you made with ❤️</div>
                </div>
            </div>
        </div>-->

        <!-- <section class="section">
            <div class="container" v-if="isLoaded">
                <products-view :products="originals" title="Originals"></products-view>
            </div>
        </section> -->
    </div>
</template>

<script>
import _ from "lodash";
import { StoreMixins, ProductMixins } from "@/mixins";
import ProductsView from "@/components/store/ProductsView";

export default {
    name: "StoreIndexPage",
    mixins: [StoreMixins, ProductMixins],
    props: {},
    data() {
        return {
            isLoaded: false,
            products: [],
            collections: [],
        };
    },
    components: {
        ProductsView,
    },
    methods: {},
    computed: {
        orderedCollections() {
            const order = ["holiday-cards", "digital-prints", "framed-digital-prints", "original-print"]; // **** HACK FOR NOW ****
            return this.collections
                .map((item) => {
                    var n = order.indexOf(item.handle);
                    return [n, item];
                })
                .sort()
                .map((i) => {
                    return i[1];
                });
        },
    },
    watch: {},
    created() {},
    async mounted() {
        try {
            var res = await this.$shopify.collection.fetchAllWithProducts();
            res.map((collection) => {
                collection.products.map((item) => {
                    item.collection_id = collection.id;
                });
            });
            this.$set(this.$data, "collections", res);
        } catch (error) {
            error.map((e) => {
                console.log(e.message);
            });
        }
        this.isLoaded = true;
        this.setDefaultMeta(this.storeMeta);
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.store-hero {
    background-color: #eee;
    .hero-body {
        background-size: cover;
        background-image: url("https://lh3.googleusercontent.com/9KtIzUCpJYc9gdLK7y8st-cQDNYwMHRN5asuHeVElyVFfbcqbouA-I9yjmGU63TqtnO0nokTduczxW9wsPmuUkvYB-tf=s3024");
    }
}
</style>
