<template>
    <section class="login-page hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns">
                    <div class="column is-6 is-offset-3 box login-box">

                        <!-- authed in user -->
                        <div class="field has-text-centered" v-if="authenticated">
                            <h2 class="title is-4">{{$profile.email}}</h2>
                            <div class="buttons is-centered">
                                <router-link class="button is-link" :to="{path: '/'}">Home</router-link>
                                <router-link class="button is-link" :to="{path: '/admin'}">Admin</router-link>
                                <router-link class="button is-link" :to="{path: '/logout'}">Sign Out</router-link>
                            </div>
                        </div>

                        <!-- email -->
                        <div class="field">
                            <label class="label">Email</label>
                            <div class="control has-icons-left">
                                <input class="input" placeholder="ben@franklin.com" type="text" name="email" v-model="email">
                                <span class="icon is-small is-left">
                                    <i class="fa fa-envelope"></i>
                                </span>
                            </div>
                        </div>

                        <!-- password -->
                        <div class="field">
                            <label class="label">Password</label>
                            <div class="control has-icons-left">
                                <input class="input" placeholder="********" type="password" name="password" v-model="password">
                                <span class="icon is-small is-left">
                                    <i class="fa fa-unlock-alt"></i>
                                </span>
                            </div>
                        </div>

                        <!-- submit -->
                        <div class="field">
                            <div class="control has-text-centered">
                                <button class="button is-primary" @click.prevent="login">Submit</button>
                            </div>
                        </div>

                        <!-- <div class="field">
							<div class="control has-text-centered">
								<button class="button is-primary" @click.prevent="test">TEST API</button>
							</div>
						</div> -->

                        <div class="field has-text-centered">
                            <a class="is-link is-size-7 is-muted" @click="sendPasswordReset">reset password</a>
                        </div>

                        <p class="help is-danger has-text-centered">{{error}}</p>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import auth from "@/auth";
export default {
    name: "LoginPage",
    props: {},
    data() {
        return {
            email: null,
            password: null,
            error: null,
        };
    },
    components: {},
    methods: {
        test() {
            this.$api.testPut();
        },
        login() {
            auth.loginWithEmailPassword(this.email, this.password)
                .catch((error) => {
                    this.error = error.message;
                    console.log(error);
                })
                .then((e) => {
                    console.log(e);
                    if (e) {
                        console.log("Logged In", e);
                        this.$router.push({ path: "/" });
                    }
                });
        },
        sendPasswordReset() {
            if (this.email) {
                auth.sendPasswordReset(this.email)
                    .then(() => {
                        this.$swal("Password reset email sent to " + this.email);
                    })
                    .catch((err) => {
                        this.error = err.message;
                    });
            } else {
                this.error = "please enter email to send a password reset.";
            }
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.login-page {
    min-height: 90vh;
}
.login-box {
    padding-top: 50px;
}
</style>
