<template>
    <div class="product-gallery columns is-gapless" ref="gallery">
        <div class="column">

            <figure class="image current-image" :class="{'is-loaded': isLoaded, 'is-zoomed': isPanning}" @touchstart="touchHandler" @touchend="touchHandler" @touchmove="touchHandler" @mouseup="mouseHandler" @mousedown="mouseHandler" @mouseover="mouseHandler" @mouseout="mouseHandler" @mousemove="mouseHandler">

                <transition mode="out-in" enter-active-class="fadeIn" leave-active-class="fadeOut" appear>
                    <icon icon="loading" class="animated loading-image" v-if="!isLoaded"></icon>
                </transition>

                <img :src="currentImage.src" alt="image-alt" v-if="isLoaded">
                <div class="image-zoomed">
                    <img :src="currentImageZoomed" :style="zoomTransform" alt="" v-if="isLoaded">
                </div>

            </figure>

            <div class="thumbnails">
                <div class="thumbnail" :class="{'is-active': image == currentImage.id}" v-for="image in images" :key="image.id">
                    <figure class="image is-64x64" @click.stop="setCurrentImage(image)">
                        <img :src="getShopifyImageURL(image, '100x100')" alt="photo-name">
                    </figure>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StoreMixins } from "@/mixins";
export default {
    name: "ProductGallery",
    mixins: [StoreMixins],
    props: {
        images: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            maxImageSize: 800,
            currentImage: {},
            isLoaded: false,
            crop: false,
            zoomTransform: null,
            imageWidth: 0,
            imageHeight: 0,
            isPanning: false,
            mouseIsDown: false,
        };
    },
    components: {},
    methods: {
        touchHandler(e) {
            if (e.type == "touchstart") {
                e.preventDefault();
                this.isPanning = true;
                this.mouseIsDown = true;
                var touch;
                if (e.touches.length) {
                    touch = e.touches[0];
                    this.zoomImage({ x: touch.clientX, y: touch.clientY });
                }
            } else if (e.type == "touchend") {
                this.isPanning = false;
                this.mouseIsDown = false;
            } else if (e.type == "touchmove") {
                if (e.touches.length) {
                    touch = e.touches[0];
                    this.zoomImage({ x: touch.clientX, y: touch.clientY });
                }
            }
        },
        mouseHandler(e) {
            if (e.type == "mouseover") {
                this.isPanning = true;
            } else if (e.type == "mouseout") {
                this.isPanning = false;
            } else if (e.type == "mousedown") {
                e.preventDefault();
                this.isPanning = true;
                this.mouseIsDown = true;
                this.zoomImage({ x: e.pageX, y: e.pageY });
            } else if (e.type == "mousemove") {
                this.zoomImage({ x: e.pageX, y: e.pageY });
            } else if (e.type == "mousemove") {
                this.zoomImage({ x: e.pageX, y: e.pageY });
            }
        },

        setCurrentImage(image) {
            if (image.id != this.currentImage.id) {
                var img = new Image();
                this.isLoaded = false;
                this.currentImage = image;
                img.onload = () => {
                    this.isLoaded = true;
                };
                img.src = this.getShopifyImageURL(this.currentImage, "");
            }
        },
        zoomImage(pos) {
            if (this.isLoaded && this.$refs.gallery) {
                var galleryEl = this.$refs.gallery;
                var rect = galleryEl.getBoundingClientRect();
                var x = parseFloat(pos.x - rect.left); //x position within the element
                var y = parseFloat(pos.y - rect.top); //y position within the element
                var px = parseFloat((parseFloat(x / rect.width) * 100.0).toFixed(2));
                if (px > 100) px = 100;
                if (px < 0) px = 0;
                var py = parseFloat((parseFloat(y / rect.width) * 100.0).toFixed(2));
                if (py > 100) py = 100;
                if (py < 0) py = 0;
                this.zoomTransform = `transform-origin: ${px}% ${py}% 0px`;
            }
        },
    },
    computed: {
        isCropped() {
            return this.crop ? "-c" : "";
        },
        currentImageSrcSet() {
            if (this.currentImage) {
                var s = parseInt(this.maxImageSize);
                var s1 = s * 1.5;
                var s2 = s * 2;
                var url = this.currentImage.serving_url;
                return `${url}=s${s1}${this.isCropped} 1.5x, ${url}=s${s2}${this.isCropped} 2x`;
            }
            return null;
        },
        currentImageZoomed() {
            if (this.currentImage) {
                return `${this.currentImage.src}`;
            }
            return null;
        },
    },
    watch: {},
    created() {},
    destroyed() {
        document.removeEventListener("mouseup", this.mouseUp);
    },
    mounted() {
        this.setCurrentImage(this.images[0]);
        document.addEventListener("mouseup", this.mouseUp);
    },
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";
@import "../../styles/variables";
.product-gallery {
    // background: yellow;
    .column {
        padding: 0;
    }
    .loading-image {
        // background-color: rgba($gray-color, 0.1);
        color: rgba($gray-color, 0.3);
        position: absolute;
        top: 0;
        @include flexCenter();
        @include fullSize();
        font-size: $size-3;
    }
}
.current-image {
    width: auto;
    max-width: 500px !important;
    display: block;
    // display: inline-block;
    &:before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    &.is-loaded:before {
        padding-bottom: 0;
    }
    transition-duration: 100ms;

    /*&:hover {
        cursor: zoom-in;
        .image-zoomed {
            opacity: 1;
        }
    }*/
    &.is-zoomed {
        cursor: zoom-in;
        .image-zoomed {
            opacity: 1;
        }
    }

    @include mobile() {
        max-width: 100%;
    }
}
.image-zoomed {
    transition: opacity 300ms $easing;
    transition-delay: 30ms;
    opacity: 0;
    z-index: 100;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
        transform: scale(2);
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
    }
}
.thumbnails {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    .thumbnail {
        margin-right: 5px;
        margin-left: 5px;
        position: relative;
        .image {
            overflow: hidden;
        }
        &.is-active {
            &:before {
                @include box-border($primary-color, 6px);
            }
        }
        &:before {
            @include beforeContent();
            @include box-border($primary-color, 0px);
            pointer-events: none;
            transition: all 200ms $easing;
            z-index: 1;
        }
        &:hover {
            &:before {
                @include box-border($primary-color, 4px);
            }
        }
        &:first-child {
            margin-left: 0;
        }
    }

    /*@include mobile() {
        flex-direction: row;
        margin-left: 0 !important;
        .thumbnail {
            margin: 5px 5px 5px 0;
            margin-bottom: 0px;
            &:first-child {
                margin: 5px 5px 5px 0px;
            }
        }
    }*/
}
</style>
