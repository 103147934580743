import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import auth from './auth'
import API from './api'
import DB from './db'
import axios from 'axios'
import moment from 'moment'
import filters from './filters'
import directives from './directives'
import components from './components'
import Toasted from 'vue-toasted';
import shortid from 'shortid'
import SocialSharing from 'vue-social-sharing'
import VueScrollTo from 'vue-scrollto'
import Buefy from 'buefy'
import { VueHammer } from 'vue2-hammer'
import slugify from 'slugify';
import firebase from 'firebase';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics'

import { DefaultMixins, HelperMixins, FormDataMixins, AuthMixins, ImageMixins } from './mixins/'

Vue.use(Buefy, {
	defaultIconPack: 'fa'
})

Vue.use(VueAnalytics, {
	id: 'UA-243487-1',
	router
})

Vue.use(VueScrollTo)
Vue.use(SocialSharing);
Vue.use(Toasted)
Vue.use(VueHammer)
Vue.use(VueMeta, {
	refreshOnceOnNavigation: true
})

// register components
for (let key in components) Vue.component(key, components[key])

// filters
Object.keys(filters).forEach((name) => {
	Vue.filter(name, filters[name]);
})

// directive
Object.keys(directives).forEach((name) => {
	Vue.directive(name, directives[name]);
})

// mixins
Vue.mixin(AuthMixins)
Vue.mixin(DefaultMixins)
Vue.mixin(HelperMixins)
Vue.mixin(FormDataMixins)
Vue.mixin(ImageMixins)

Vue.config.productionTip = false
Vue.prototype.$auth = auth
Vue.prototype.$api = API
Vue.prototype.$db = DB
Vue.prototype.$http = axios
Vue.prototype.$slugify = slugify;
Vue.prototype.$moment = moment
Vue.prototype.$shortid = shortid;
Vue.prototype.$filters = filters;
Vue.prototype.$storage = firebase.storage().ref();

auth.init().then(() => {
	new Vue({
		store,
		router,
		...App
	}).$mount('#app')
})
