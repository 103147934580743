import isStoreRoot from '../utils/is-store-root'
import Vue from 'vue'
import store from '../store'
import auth from '../auth'
import Router from 'vue-router'

// home
import IndexPage from '@/pages/IndexPage'

// post / pages
import Page from '@/pages/page/Page'

// projects
import ProjectsPage from '@/pages/ProjectsPage'

// auth
import LoginPage from '@/pages/admin/general/LoginPage'

// admin
import AdminRouterView from '@/pages/admin/AdminRouterView'

// admin - general
import AdminSettingsPage from '@/pages/admin/general/AdminSettingsPage'
import AdminContentPage from '@/pages/admin/general/ContentPage'
import AdminFilesPage from '@/pages/admin/general/FilesPage'

// admin - products
import AdminProductsRouterView from '@/pages/admin/products/ProductsRouterView' 			  		  // root view for products
import AdminProductsTypesPage from '@/pages/admin/products/ProductsTypesPage' 	  					  // types of products prints, drawings, pins, etc
import AdminProductsTypesEditPage from '@/pages/admin/products/ProductsTypesEditPage' 	  			  // edit types of products prints, drawings, pins, etc
import AdminProductsIndexPage from '@/pages/admin/products/ProductsIndexPage'	                      // products index page
import AdminEditProductPage from '@/pages/admin/products/EditProductPage'	                          // edit/create product

// store
import StoreRouterView from '@/pages/store/StoreRouterView'
import ProductPage from '@/pages/store/ProductPage'
import StoreIndexPage from '@/pages/store/StoreIndexPage'
import StoreCollectionsPage from '@/pages/store/CollectionsPage'
import ShoppingCartPage from '@/pages/store/ShoppingCartPage'
import CheckoutPage from '@/pages/store/CheckoutPage'

// misc
import PageNotFound from '@/pages/PageNotFound'

Vue.use(Router)

// we have a root switch - Store / Portfolio
var IS_STORE_ROOT = isStoreRoot()
console.log('Store Site Root:', IS_STORE_ROOT);

// auth guard
const authGuad = (to, from, next) => {
	if (!auth.currentUser()) {
		next('/login')
	} else {
		next()
	}
}

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to) {
	if (to.hash) {
		const position = {}
		position.selector = to.hash

		if (document.querySelector(to.hash)) {
			return position
		}
		// if the returned position is falsy or an empty object,
		// will retain current scroll position.
		return false
	}
	return { x: 0, y: 0 }
}

// store routes
const storeRoutes = [
	{
		path: '/',
		// name: 'store-home-page',
		component: StoreIndexPage,
		beforeEnter: (to, from, next) => {
			next()
		}
	},
	{
		path: 'collections/:id',
		component: StoreCollectionsPage,
	},
	{
		path: 'cart',
		// name: 'store-shopping-cart-page',
		component: ShoppingCartPage,
	},
	{
		path: 'checkout',
		// name: 'store-checkout-page',
		component: CheckoutPage,
	},
	{
		path: 'products/:id',
		// name: 'store-product-page',
		component: ProductPage,
	},
]

// admin routes
const adminRoutes = [
	{
		path: '/',
		name: 'admin-index-page',
		component: AdminSettingsPage,
	},
	{
		path: 'files',
		name: 'admin-files-page',
		component: AdminFilesPage,
	},
	{
		path: 'pages',
		name: 'admin-content-page',
		component: AdminContentPage,
	},
	{
		path: 'settings',
		name: 'admin-settings-pages',
		component: AdminSettingsPage,
	},
	{
		path: 'products',
		component: AdminProductsRouterView,
		children: [
			{
				path: '/',
				name: 'admin-products-index-page',
				component: AdminProductsIndexPage,
			},
			{
				path: 'types',
				name: 'admin-products-types-page',
				component: AdminProductsTypesPage,
			},
			{
				path: 'types/:id',
				name: 'admin-products-edit-types-page',
				component: AdminProductsTypesEditPage,
			},
			{
				path: 'create',
				name: 'admin-products-create-page',
				component: AdminEditProductPage,
			},
			{
				path: ':id',
				name: 'admin-products-edit-page',
				component: AdminEditProductPage,
			},
		]
	},


]

// the main router
const router = new Router({
	mode: 'history',
	base: '/',
	linkActiveClass: 'is-active',
	scrollBehavior: scrollBehavior,
	routes: [
		{
			path: '/',
			component: IS_STORE_ROOT ? StoreRouterView : IndexPage,
			children: IS_STORE_ROOT ? storeRoutes : []
		},
		{
			path: '/projects',
			name: 'projects',
			component: ProjectsPage
		},
		{
			path: '/page/:slug',
			name: 'page',
			component: Page
		},
		{
			path: '/page/:slug/edit',
			name: 'edit-page',
			component: Page
		},


		// admin
		// -------------------------------------
		{
			path: '/admin',
			component: AdminRouterView,
			beforeEnter: authGuad,
			children: adminRoutes
		},

		// store
		// -------------------------------------
		{
			path: '/store',
			component: StoreRouterView,
			children: storeRoutes
		},

		// auth
		// -------------------------------------
		{
			path: '/login',
			name: 'login',
			component: LoginPage
		},
		{
			path: '/logout',
			name: 'logout',
			component: IndexPage,
			beforeEnter: () => {
				auth.signout()
			}
		},

		// 404
		// -------------------------------------
		{
			path: '*',
			name: '404',
			component: PageNotFound
		}

	]
})

router.beforeEach((to, from, next) => {
	store.commit('menuActive', false)
	store.commit('sideMenuActive', false)
	store.commit('showCartPopover', false)
	next()
})

export default router;
