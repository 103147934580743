<template>
    <div class="control">
        <div class="file">
            <label class="file-label">
                <!-- <input class="file-input" v-if="!uid" v-validate="'required|image|ext:jpg,JPG,jpeg,JPEG,PNG,png,SVG,svg'" accept="image/*" multiple="true" type="file" name="images" @change="handleImages"> -->
                <input class="file-input" accept="image/*" :v-validate="validate" :multiple="multiple" type="file" :name="name" @change="(e)=> {this.$emit('handleImages', e)}">
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="fa fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Choose images…
                    </span>
                </span>
            </label>
        </div>
        <span v-show="formErrors && formErrors.has('images')" class="help is-danger">{{ formErrors.first('images') }}</span>
    </div>
</template>

<script>
export default {
    name: "ImageUploadButton",
    props: {
        name: {
            default: "images",
        },
        validate: {
            default: "required|image|ext:jpg,JPG,jpeg,JPEG,PNG,png,SVG,svg",
        },
        multiple: {
            Type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
</style>
