<template>
    <admin-layout :isLoaded="isLoaded" :crumbs="crumbs">

        <div class="columns is-multiline" v-if="isLoaded">
            <div class="column is-12">
                <div class="field">
                    <label class="label">Name</label>
                    <div class="control">
                        <input class="input" v-validate="'required'" name="name" type="text" v-model="productType.name" placeholder="Name of product">
                        <span v-show="formErrors.has('name')" class="help is-danger">{{ formErrors.first('name') }}</span>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Caption</label>
                    <div class="control">
                        <input class="input" name="caption" type="text" v-model="productType.caption" placeholder="One line description (optional)">
                    </div>
                </div>

                <!-- images -->
                <div class="field">
                    <label class="label">Images</label>
                    <div class="preview-images">
                        <figure class="image" v-for="(image, i) in productType.images">
                            <img :src="`${image}=s100`" alt="">
                            <a class="is-link is-round-icon-button" @click.prevent="removeProductTypeImage(image, i)">
                                 <span class="icon">
                                    <i class="fa fa-trash"></i>
                                </span>
                            </a>
                        </figure>
                    </div>
                    <div class="preview-images">
                        <figure class="image" v-for="(image, i) in previewImages">
                            <img :src="image.src" alt="">
                            <a class="is-link is-round-icon-button" @click.prevent="removePreviewImage(image, i)">
                                 <span class="icon">
                                    <i class="fa fa-times"></i>
                                </span>
                            </a>
                        </figure>
                    </div>
                    <div class="control">
                        <div class="file">
                            <label class="file-label">
                                <input class="file-input" accept="image/*" multiple="true" type="file" name="images" @change="handleImages">
                                <span class="file-cta">
                                    <span class="file-icon">
                                        <i class="fa fa-upload"></i>
                                    </span>
                                    <span class="file-label">
                                    Choose a images…
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <!-- end images -->
                <div class="field">
                    <label class="label">Attributes</label>
                    <tagger
                        v-model="productTypeAttributes"
                        placeholder="Add attributes for product type"
                        :multiple="true">
                    </tagger>
                </div>
                <div class="field">
                    <label class="label">Description</label>
                    <div class="control">
                        <textarea class="textarea" name="description" v-validate="'required'" v-model="productType.description" placeholder="Products description"></textarea>
                        <span v-show="formErrors.has('description')" class="help is-danger">{{ formErrors.first('description') }}</span>
                    </div>
                </div>

                <div class="field">
                    <div class="control has-text-centered">
                        <button class="button" @click.prevent="updateProductType">{{`${uid ? 'Update' : 'Create'}`}}</button>
                    </div>
                    <div class="control">
                        <span class="help has-text-centered">{{productType.updated | fromnow}}</span>
                    </div>
                </div>

                <errors :errors="errors"></errors>

            </div>
        </div>

    </admin-layout>
</template>

<script>
import {ImageUploaderMixins} from '@/mixins'
export default {
    name: 'AdminProductEditTypesPage',
    mixins: [ImageUploaderMixins],
    props: {},
    data() {
        return {
            isLoaded: false,
            errors: null,
            crumbs: [
                {name: 'Admin', path: '/admin'},
                {name: 'Products', path: '/admin/products'},
                {name: 'Types', path: '/admin/products/types'}
            ],
            previewImages: [],
            productType: null
        }
    },
    components: {
    },
    methods: {
        removePreviewImage(image, index) {
            this.previewImages.splice(index, 1);
        },
        removeProductTypeImage(serving_url, index) {
            this.$api.get(`assets?serving_url=${serving_url}`).then(e => {
                if(e.data.error) {

                } else if(e.data.length) {
                    // remove it here
                    console.log(e.data);
                }
                else {
                    this.$delete(this.productType.images, index)
                    this.updateProductType()
                }
            })
        },
        handleImages(e) {
            this.hasFiles(e).then(files => {
                for (var i = 0; i < files.length; i++) {
                    this.readFile(files[i]).then(obj => {
                        this.previewImages.push(obj)
                    })
                }
            })
        },
        updateProductType(event) {
            var target = event?event.target:null
            var files = _.map(this.previewImages, (i) => { return i.file; })
            var fd = this.objectToFormData({...this.productType, ...files})

            if(target) {
                target.classList.add('is-loading')
            }

            this.$api.put(`products/types/${this.uid}`, fd).then(e => {
                if(e.data.error) {
                    this.errors = e.data.error
                } else {
                    console.log(e.data);
                }
                if(target) {
                    target.classList.remove('is-loading')
                }
            })
        }
    },
    computed: {
        uid() {
            return this.$route.params.id;
        },
        productTypeAttributes: {
            get() {
                return this.productType.attributes
            },
            set(results) {
                if (results.remove) {
                    var index = this.productType.attributes.indexOf(results.tag)
                    if (index > -1) {
                         this.productType.attributes.splice(index, 1)
                    }
                }
                else if (results.add) {
                    this.productType.attributes.push(results.tag)
                }
            }
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
        this.$api.get(`products/types/${this.uid}`).then((e) => {
            if (e.data.error) {
                this.errors = e.data.error;
            } else {
                this.isLoaded = true;
                this.productType = e.data
                this.crumbs.push({name: this.productType.id, path: null, active: true})
            }
        })
    }
}
</script>

<style scoped lang="scss">

</style>
