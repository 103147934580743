<template>
    <div class="store">
        <store-nav></store-nav>
        <store-sub-nav :collections="collections"></store-sub-nav>
        <cart-popover></cart-popover>
        <div class="store-wrapper">
            <transition name="page" mode="out-in" appear>
                <router-view class="animated"></router-view>
            </transition>
        </div>
        <store-footer :social="$profile.store_social"></store-footer>
    </div>
</template>

<script>
import StoreNav from "@/components/store/StoreNav";
import StoreSubNav from "@/components/store/StoreSubNav";
import StoreFooter from "@/components/store/StoreFooter";
import CartPopover from "@/components/store/CartPopover";
import { StoreMixins } from "@/mixins";
export default {
    name: "StoreRouterView",
    mixins: [StoreMixins],
    props: {},
    data() {
        return {
            collections: [],
        };
    },
    components: {
        StoreNav,
        StoreSubNav,
        StoreFooter,
        CartPopover,
    },
    methods: {
        async initCheckout() {
            let existingCheckoutID = await localStorage.getItem("shopify_checkout_id");

            if (existingCheckoutID) {
                try {
                    const checkout = await this.$shopify.checkout.fetch(existingCheckoutID);
                    if (!checkout.completedAt) {
                        this.$store.commit("checkout", checkout);
                        return;
                    }
                } catch (error) {
                    localStorage.setItem("shopify_checkout_id", null);
                }
            }

            console.log("create new checkout");
            const checkout = await this.$shopify.checkout.create();
            this.$store.commit("checkout", checkout);
            localStorage.setItem("shopify_checkout_id", checkout.id);
        },
    },
    computed: {},
    watch: {},
    created() {},
    async mounted() {
        await this.initCheckout();
        this.collections = await this.$shopify.collection.fetchAll();
    },
};
</script>

<style lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
</style>
