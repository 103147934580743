<template>
    <div class="field">
        <label class="label" v-if="title">{{title}}</label>
        <div class="control">
            <div class="select">
                <select v-bind:value="value" @change="onChange($event.target.value)">
                    <option v-for="option in options" :value="getValue(option)">{{getName(option)}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectControl',
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: ''
        },
        valueKey: {
            default: null,
        },
        nameKey: {
            default: null,
        }
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        getValue(option) {
            if (this.valueKey) {
                return option[this.valueKey];
            }
            return option;
        },
        getName(option) {
            if (this.nameKey) {
                return option[this.nameKey];
            }
            return option;
        },
        onChange(value) {
            this.$emit('input', value)
        }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
