<template>
    <div :class="{'sidebar-open': openSidebar}">
        
        <transition  mode="out-in" enter-active-class="fadeIn" leave-active-class="fadeOut" appear>
            <loading-page class="animated" :isLoading="!isLoaded"></loading-page>
        </transition>

        <transition  mode="out-in" enter-active-class="fadeIn" leave-active-class="fadeOut" appear>
            <div class="animated page-wrapper" v-if="isLoaded">
                
                <div class="page-sidebar" :class="{'is-active': openSidebar}">
                    <slot name="sidebar"></slot>
                </div>
                
                <div class="page-container">
                    <div class="page-shadow"></div>
                    <slot name="page"></slot>
                </div>

            </div>
        </transition>
        
    </div>   
</template>

<script>
export default {
    name: 'PageLayout',
    props: {
        openSidebar: {
            type: Boolean,
            default: false,
        },
        isLoaded: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';

</style>