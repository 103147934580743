<template>
    <div class="page-link">
    	<template v-if="isEmail">
    		<a class="is-link" @click="emailMe">{{title}}</a>
    	</template>
    	<template v-else-if="isExternal">
    		<a class="is-link" :href="url">{{title}}</a>
    	</template>
    	<template v-else-if="isRelative">
    		<router-link class="is-link" :to="{'path': url}">{{title}}</router-link>
    	</template>
    	<template v-else>
    		<a class="is-link" v-scroll-to="`${url}`">{{title}}</a>
    	</template>
    	<!-- <router-link v-for="(link, i) in profile.home_links" class="is-link" :to="{'path': link.url}">{{link.title}}</router-link><a class="is-link" @click.stop="emailMe()" v-if="profile.show_email_home">Email me</a> -->
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    name: 'PageLink',
    props: {
		url: {
			required: true
		},
		title: {
			required: true
		}
	},
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    	isEmail() {
    		return _.includes(this.url, 'mailto:')
    	},
    	isRelative() {
    		return !this.isEmail && !_.includes(this.url, 'http') && !_.includes(this.url, '#')
    	},
    	isExternal() {
            // && !_.includes(this.url, '#')	&&
    		return !this.isEmail && _.includes(this.url, 'http')
    	}
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
b {
	color: red;
}
</style>
