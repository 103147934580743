import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		menuActive: true,
		authenticated: false,
		sideMenuActive: true,
		images: {},
		categories: [],
		profile: {
			intro: 'Hello world',
			email: 'email@gmail.com',
			store_name: 'Nilrednav',
			show_email_home: true,
			home_links: [],
			social: [],
			store_social: [],
			locationName: 'Home',
			homeColor: {
				rgba: {
					r: 0,
					g: 0,
					b: 0,
					a: 1
				},
			}
		},

		// store
		showCartPopover: true,
		checkout: {
			lineItems: []
		},
	},

	mutations: {
		images(state, payload) {
			state.images = payload;
		},

		profile(state, payload) {
			state.profile = { ...state.profile, ...payload };
		},

		updateProfile(state, payload) {
			state.profile = payload;
		},

		authenticated(state, payload) {
			state.authenticated = payload
		},

		menuActive(state, payload) {
			state.menuActive = payload
		},
		sideMenuActive(state, payload) {
			state.sideMenuActive = payload
		},

		categories(state, payload) {
			state.categories = payload;
		},
		addCategory(state, payload) {
			state.categories.push(payload)
		},


		// store
		checkout(state, payload) {
			state.checkout = { ...state.checkout, ...payload };
		},
		showCartPopover(state, payload) {
			state.showCartPopover = payload
		}
	}
});
