<template>
    <router-view class="animated"></router-view>
</template>

<script>
export default {
    name: 'AdminProductsRouterView',
    props: {},
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
