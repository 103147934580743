export default {
    item: {
    	'full': 'is-12',
	    'half': 'is-6',
	    '1/3': 'is-3',
	    '1/4': 'is-4',
	},
	section: {
		'normal':'column group is-10 is-offset-1 is-8-desktop is-offset-2-desktop',
        'wide':'column group is-12',
        // 'full':'column group is-12',
	},
	hero: {
		'full': 'is-fullheight',
        'large': 'is-large',
        'medium': 'is-medium',
        'small': 'is-small',
    },
    gallery: {
        '1by': 'is-12',
        '2by': 'is-6-mobile is-6-tablet',
        '3by': 'is-6-mobile is-4-tablet',
        '4by': 'is-6-mobile is-3-tablet',
    }
}
