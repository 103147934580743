<template>
    <admin-layout :isLoaded="isLoaded">
        <b-tabs v-model="activeTab" class="section" @change="clickTab">
            <b-tab-item label="Published">
                <div class="columns is-multiline">
                    <div class="column is-12-tablet list">
                        <content-page-post-item class="list-item is-mobile level" v-for="post in sortedContent" :key="post._id" :post="post">
                        </content-page-post-item>
                        <div v-if="sortedContent.length==0" class="has-text-centered">
                            No Posts
                        </div>
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="Drafts">
                <div class="columns is-multiline">
                    <div class="column is-12-tablet list">
                        <content-page-post-item class="list-item is-mobile level" v-for="post in drafts" :key="post._id" :post="post" @trashed="(post) => {post.status = 'trash'}">
                        </content-page-post-item>
                        <div v-if="drafts.length==0" class="has-text-centered">
                            No Posts
                        </div>
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="Trash">
                <div class="columns is-multiline">
                    <div class="column is-12-tablet list">
                        <content-page-post-item class="list-item is-mobile level" v-for="post in trashed" :key="post._id" :post="post">
                        </content-page-post-item>
                        <div v-if="trashed.length==0" class="has-text-centered">
                            No Posts
                        </div>
                    </div>
                </div>
            </b-tab-item>

            <!-- add new page -->
            <b-tab-item label="Add" icon="plus"></b-tab-item>
            <!-- end add new page -->

        </b-tabs>
    </admin-layout>
</template>

<script>
import _ from "lodash";
import { ImageUploaderMixins, PageMixins } from "@/mixins";
import ContentPagePostItem from "@/components/content-page/ContentPagePostItem";
export default {
    name: "CreateItemPage",
    mixins: [ImageUploaderMixins, PageMixins],
    props: {},
    data() {
        return {
            tabNames: ["Published", "Drafts", "Trash", "Add"],
            activeTab: null,
            content: null,
        };
    },
    components: {
        ContentPagePostItem,
    },
    methods: {
        clickTab(e) {
            let name = this.tabNames[e].toLowerCase();
            if (name === "add") {
                this.addContent();
                //this.$swal({title: 'need to build this', icon: 'info'})
            } else {
                this.$router.push(`#${name}`);
            }
        },
        updateTabFromHash() {
            if (this.$route.hash == "#drafts") {
                this.activeTab = 1;
            } else if (this.$route.hash == "#published") {
                this.activeTab = 0;
            } else {
                this.activeTab = 0;
            }
        },
        thumbnail(item) {
            if (item.image) {
                return this.servingImage(item.image, "s100-c");
            }
            return `https://placehold.it/100x100?text=+${item[".key"]}`;
        },
        addContent() {
            var doc = this.$db.collection("posts").doc();
            var basePage = this.defaultPage(doc.id);
            doc.set(basePage).then(() => {
                this.$router.push({ path: "/page/" + basePage.id + "/edit" });
            });
        },
        openItem(item) {
            this.$router.push({ path: "/page/" + item.slug });
        },
        shouldRemove(item) {
            console.log(item);
            var options = {
                title: "Are you sure you want to delete this page",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonClass: "is-danger",
            };
            this.swal(options)
                .then((e) => {
                    if (e.value === true) {
                        this.isAuth().then(() => {
                            this.deletePosts(item);
                        });
                    }
                })
                .catch(() => {});
        },

        // this function needs work
        deletePosts(item) {
            return new Promise((resolve) => {
                if (!item) {
                    resolve();
                    return;
                }
                var contentKey = item[".key"];
                var k;
                for (k in item.sections) {
                    for (var kk in item.sections[k].items) {
                        if (item.sections[k].items[kk].type == "image") {
                            var image = this.getImage(item.sections[k].items[kk].image);
                            this.removeImage(image);
                        }
                    }
                }

                for (k in this.images) {
                    if (this.images[k]["content_id"] === contentKey && this.images[k][".key"]) {
                        console.log("remove", this.images[k][".key"]);
                        this.removeImage(this.images[k]);
                    }
                }

                this.$db
                    .postRef(contentKey)
                    .delete()
                    .then(() => {
                        console.log("post delete");
                        resolve();
                    });
            });
        },
    },
    computed: {
        isLoaded() {
            return this.images !== null && this.content !== null;
        },
        drafts() {
            return _.orderBy(this.content, ["order"]).filter((item) => {
                return item.status == "draft";
            });
        },
        trashed() {
            return _.orderBy(this.content, ["order"]).filter((item) => {
                return item.status == "trash";
            });
        },
        sortedContent: {
            get() {
                return _.orderBy(this.content, ["order"]).filter((item) => {
                    return item.status != "draft" && item.status != "trash";
                });
            },
            set(items) {
                var payload = {};
                for (var i = 0; i < items.length; i++) {
                    var key = items[i][".key"];
                    payload[`${key}/order`] = i;
                }
                this.$db.ref("content").update(payload);
            },
        },
    },
    watch: {
        "$route.hash"() {
            this.updateTabFromHash();
        },
    },
    created() {},
    async mounted() {
        this.updateTabFromHash();
        var results = await this.$db.collection("posts").get();
        this.content = results.docs.map((item) => {
            return item.data();
        });
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.add-content {
    margin-top: 40px;
    .plus-button {
        margin: 0 auto;
    }
}
.title {
    width: 100%;
    font-family: $heading-font;
}
.page-status {
    color: $medium-gray;
    text-transform: capitalize;
}
.content-group {
    margin-top: 40px;
}
</style>
