import moment from 'moment'
import showdown from 'showdown'
import autosize from 'autosize'
import Vue from 'vue'
import mediumZoom from 'medium-zoom'

showdown.setFlavor('github');
var converter = new showdown.Converter()

export default {
	markdown: {
		bind(el, binding, vnode) {
			el.innerHTML = binding.value ? converter.makeHtml(binding.value) : null
		},
		componentUpdated(el, binding, vnode) {
			el.innerHTML = binding.value ? converter.makeHtml(binding.value) : null
		}
	},
	'textarea-autoresize': {
		bind(el, binding, vnode) {
			Vue.nextTick(function() {
				autosize(el);
			});
            el.addEventListener('focus', () => {
				autosize(el);
			});

		},
		unbind(el) {
			autosize.destroy(el);
		},
		componentUpdated(el, binding, vnode) {
			// this may not be needed
			// autosize(el);
		}
	},
	keyupDebounce: {
		bind(el, binding, vnode) {
			var timeout = null
			el.onkeyup = () => {
				clearTimeout(timeout)
				timeout = setTimeout(() => {
					binding.value(el.value || el.innerHTML);
				}, 500)
			}
		}
	},
	droppable: {
		bind(el, binding, vnode) {
			if(binding.value) {
				el.classList.add('is-droppable')
				vnode.dragOver = (e) => {
					e.preventDefault();
	                e.dataTransfer.dropEffect = 'copy';
	                el.classList.add('is-drag-over')
				}
				vnode.dragExit = (e) => {
					el.classList.remove('is-drag-over')
					e.stopPropagation();
					e.preventDefault();
				}
				el.addEventListener('drop', (e) => {
					e.stopPropagation();
					e.preventDefault();
					el.classList.remove('is-drag-over')
					el.classList.add('is-droppable')
				}, false)
				el.addEventListener('dragover', vnode.dragOver, false)
				el.addEventListener('dragexit', vnode.dragExit, false)
				el.addEventListener('dragleave', vnode.dragExit, false)
			}
		},
		componentUpdated(el, binding, vnode) {
			if(binding.value) {
				el.classList.add('is-droppable')
			}
		}
	},
	'image-zoom': {
		bind(el, binding, vnode) {
			if (binding.value) {
				mediumZoom(el, {
					margin: 24,
					background: 'rgba(0, 0, 0, 0.98)',
					scrollOffset: 0,
				})
			}
		},
		unbind(el) {
			const zoom = mediumZoom(el)
			zoom.detach()
		},
	},
}
