module.exports = {
	env: 'production', //process.env.NODE_ENV,
	app_name: process.env.VUE_APP_APP_NAME,
	firebase: {
		apiKey: "AIzaSyC2abpaBFbj3ny091T1kFy7OLps0F2w9l4",
		authDomain: "vanderlin-c882c.firebaseapp.com",
		databaseURL: "https://vanderlin-c882c.firebaseio.com",
		projectId: "vanderlin-c882c",
		storageBucket: "vanderlin-c882c.appspot.com",
		messagingSenderId: "811465514962",
		appId: "1:811465514962:web:070af7b658b71e8defb20b",
		measurementId: "G-QGHTMG7968"
	}
}