<template>
    <div>
		<template v-if="authenticated && isEditable">
			<draggable-items v-bind:value="value" :class="classNames" :options="options" @input="inputChange" @end="end">
	    		<slot></slot>
	    	</draggable-items>
		</template>
		<template v-else>
    		<div :class="classNames">
                <slot></slot>
            </div>
		</template>
    </div>
</template>

<script>
import DraggableItems from 'vuedraggable'
export default {
    name: 'Draggable',
    props: {
        value: {
            default: null
    	},
    	classNames: {
    		default: ''
    	},
        isEditable: {
            default: false
        },
    	options: {
    		default: () => {
    			return {}
    		}
    	}
    },
    data() {
        return {
        }
    },
    components: {
    	DraggableItems
    },
    methods: {
    	end(e) {
    		this.$emit('end', e)
    	},
    	inputChange(e) {
    		this.$emit('input', e)
    	}
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
