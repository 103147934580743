<template>
    <section class="section">
        <div class="admin-container">

            <!-- loading page  -->
            <loading-page :isLoading="!isLoaded"></loading-page>
            <!-- loading page -->

            <template v-if="isLoaded">

                <div class="crumbs-section" v-if="crumbs.length">
                    <div class="columns is-multiline">
                        <div class="column is-12 list">
                            <bread-crumbs :links="crumbs"></bread-crumbs>
                        </div>
                    </div>
                </div>

                <slot></slot>

                <errors :errors="errors"></errors>

            </template>

        </div>
    </section>

</template>

<script>
export default {
    name: "AdminLayout",
    props: {
        isLoaded: {
            default: false,
        },
        crumbs: {
            type: Array,
            default: () => {
                return [];
            },
        },
        errors: null,
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.crumbs-section {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
