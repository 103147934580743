<template>
    <div class="columns is-multiline gallery-items">
        <div class="column is-full" v-if="title">
            <div class="collection-title ">
                <router-link :to="getStoreLink('collections/'+handle)">
                    <div class="title is-6 has-text-weight-normal">{{title}}</div>
                </router-link>
            </div>
        </div>
        <div class="column is-4 product item" :class="{'is-sold-out':product.availableForSale}" :key="product.id" v-for="product in products">

            <router-link :to="{path: `/store/products/${product.handle}`}">
                <figure class="image">
                    <shopify-image :image="product.images[0]" small="100x100" large="1024x1024" />
                    <div class="is-sold-out" v-if="!product.availableForSale">
                        <span>Sold</span>
                    </div>
                    <!-- <a class="edit-item" :href="`https://vanderlin.myshopify.com/admin/products?selectedView=all&query=${product.title}`">
                        <span class="fa fa-pencil"></span>
                    </a> -->
                </figure>
            </router-link>

            <div class="details">
                <div class="title">{{product.title}}</div>
                <div class="category">{{product.caption}}{{product.is_orignal?'(Original)':''}}</div>
                <!-- <div class="category">{{getProductDimensionString(product)}}</div> -->
                <div class="price">{{getProductPrice(product)}}</div>
                <div class="category" v-html="product.descriptionHtml"></div>
            </div>

        </div>
    </div>
</template>

<script>
import ShopifyImage from "./ShopifyImage";
import { StoreMixins, ProductMixins } from "@/mixins";
export default {
    name: "ProductsView",
    mixins: [StoreMixins, ProductMixins],
    props: {
        products: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        uid: {
            default: () => {
                return null;
            },
        },
        handle: {
            default: () => {
                return null;
            },
        },
        title: {
            default: "",
        },
    },
    data() {
        return {};
    },
    components: { ShopifyImage },
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.product.item {
    &:hover {
        .edit-item {
            opacity: 1;
        }
    }
}
.edit-item {
    z-index: 100;
    opacity: 0;
    transition: all 200ms;
    position: absolute;
    top: 5px;
    left: 5px;
    background: rgba($color: #000000, $alpha: 0.8);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        color: rgb(252, 94, 229);
    }
}
.collection-title {
    background: rgb(255, 223, 228);
    display: flex;
    max-width: 100px;
    padding: 10px;
}
</style>
