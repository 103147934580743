<template>
    <div class="app-sidebar" :class="{'is-active':$store.state.sideMenuActive, 'is-admin-section':isAdminPage}" v-if="authenticated">
        <aside class="menu">
            <ul class="menu-list">
                <li>
                    <router-link class="navbar-item" :to="{path: '/'}">
                        <icon icon="fa-home"></icon> Home
                    </router-link>
                </li>
                <li>
                    <router-link class="navbar-item" :to="{path: '/store'}">
                        <icon icon="fa-shopping-cart"></icon> Store
                    </router-link>
                </li>
            </ul>
            <p class="menu-label">Admin</p>
            <ul class="menu-list">
                <li>
                    <router-link class="navbar-item" :to="{path: '/admin'}">Admin</router-link>
                </li>
                <li>
                    <router-link class="navbar-item" :to="{path: '/admin/files'}">Files</router-link>
                </li>
            </ul>

            <p class="menu-label">Products</p>
            <ul class="menu-list">
                <!-- <li><router-link class="navbar-item" :to="{path: '/admin/products/types'}">Product (types)</router-link></li> -->
                <li>
                    <router-link class="navbar-item" :to="{path: '/admin/products'}">Products</router-link>
                </li>
                <!-- <li><router-link class="navbar-item" :to="{path: '/admin/products'}">SKUs</router-link></li> -->
            </ul>

            <p class="menu-label">Post</p>
            <ul class="menu-list">
                <li>
                    <router-link class="navbar-item" :to="{path: '/admin/pages'}">Pages</router-link>
                </li>
                <li>
                    <router-link class="navbar-item" :to="{path: '/admin/pages#drafts'}">Drafts</router-link>
                </li>
            </ul>

            <hr class="divider">
            <ul class="menu-list">
                <li>
                    <router-link class="navbar-item" :to="{path: '/logout'}">
                        <icon icon="fa-sign-out"></icon> Sign out
                    </router-link>
                </li>
            </ul>

        </aside>

    </div>
</template>

<script>
export default {
    name: "AdminAppSidebar",
    props: {},
    data() {
        return {};
    },
    components: {},
    methods: {
        toggleMenu() {
            this.$store.commit("menuActive", !this.$store.state.menuActive);
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/fonts";
@import "../../styles/variables";
@import "../../styles/mixins";
</style>
