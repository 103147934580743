export default {
	data() {
		return {
		}
	},
	computed: {
		productStatusOptions() {
			return ['published', 'draft', 'trash']
		},

	},
	methods: {

		defaultProduct() {
			return {
				name: null,
				caption: null,
				price: null,
				status: 'draft',
				quantity: 1,
				width: null,
				height: null,
				description: null,
				medium: null,
				is_orignal: false,
				images: [],
			};
		},

		// -------------------------------------
		getProductDefaultImage(product) {
			if (product.images.length > 0) {
				return product.images[0].src;
			}
			return null;
		},

		// -------------------------------------
		getProductURL(product, absolute = false) {
			let rootPath = this.$isStoreRoot ? '' : '/store'
			return `${(absolute ? document.location.origin : '')}${rootPath}/products/${product.id}`
		},

		// -------------------------------------
		getProductPrice(product) {
			if (product.variants.length > 1) {
				var prices = product.variants.map(v => {
					return v.price
				}).sort()
				return `$${prices[0]} - $${prices[prices.length - 1]}`
			}
			return `$${product.variants[0].price}`
		},

		// -------------------------------------
		getProductEditURL(product) {
			return `/admin/products/${product._id}`
		},

		// -------------------------------------
		getProductDimensionString(product) {
			return `${product.width} x ${product.height} in`
		},

		// -------------------------------------
		createProduct(payload) {
			return new Promise((resolve, reject) => {
				this.$api.post(`products/`, payload).then(e => {

					console.log('product created');
					console.log(e);

					if (e.data.error) {
						reject({ error: e.data.error })
					} else {
						resolve(e.data)
					}
				}).catch(err => { reject({ error: err }) })
			})
		},

		// -------------------------------------
		updateProduct(uid, payload) {
			return new Promise((resolve, reject) => {

				// If we want to for payload to be a FormData
				// if(payload instanceof FormData == false) {
				//     console.error('Payload must be a FormData Object');
				//     reject({error: 'Payload must be a FormData Object'})
				//     return;
				// }

				if (!uid) {
					console.error('Missing uid');
					reject({ error: 'missing uid' })
					return
				}

				this.$api.put(`products/${uid}`, payload).then(e => {
					if (e.data.error) {
						console.error(e.data.error);
						reject({ errors: e.data.error })
					} else {
						console.log('product update', e);
						resolve(e.data)
					}
				}).catch(err => {
					reject({ error: err })
				})

			})
		},

		// -------------------------------------
		setProductStatus(uid, status) {
			if (!uid) {
				console.error('missing uid');
				return;
			}
			return new Promise((resolve, reject) => {
				let statusOptions = ['published', 'draft', 'trash']
				if (statusOptions.indexOf(status) == -1) {
					console.error('Status is not a option for product', status);
					return;
				}
				if (status == 'trash') {
					let options = {
						title: 'Are you sure you want to delete this product?',
						buttons: true,
						confirmButtonClass: 'is-danger'
					}
					this.$swal(options).then(e => {
						if (e === true) {
							this.isAuth().then(() => {
								this.updateProduct(uid, { status: status }).then(() => {
									resolve(true)
								})
							})
						}
					}).catch(e => {
						reject(e)
					})
				} else {
					this.updateProduct(uid, { status: status }).then(() => {
						resolve(true)
					})
				}
			})
		},

		// -------------------------------------
		emailMeAboutProduct(product) {
			var email = this.$profile.email;
			let url = `mailto:${email}?subject=I'm interested in ${product.title}&body=Hi,\nI'm interested in this ${this.getProductURL(product, true)}`;
			window.open(url, '_blank');
		},

	}
}
