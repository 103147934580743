var env = process.env.NODE_ENV;
import isStoreRoot from './utils/is-store-root'
var API_BASE_URL = process.env.VUE_APP_API_BASE_URL
console.log('Store Site Root', isStoreRoot());
console.log('API Env', env);
console.log('API Base URL', API_BASE_URL);

import _ from 'lodash'
import auth from './auth'
import axios from 'axios'
import shortid from 'shortid'
import moment from 'moment'
import queryString from 'query-string'

var baseURL = API_BASE_URL
var apiURL = `${baseURL}/api/`
var authURL = `${baseURL}/auth/`

// we need to work on this, right now
// if we query on categories we do not 
// do a categories[] request... (FIX THIS)
var toQueryObject = function (obj) {
	var q = []
	var o = {}
	for (var k in obj) {
		var v = obj[k]
		if (v) {
			if (v instanceof Array && k != 'categories') {
				o[k] = v
				for (let i = 0; i < v.length; i++) {
					q.push(`${k}[]=${v[i]}`)
				}
			} else {
				o[k] = v
				q.push(`${k}=${v}`)
			}
		}
	}
	return {
		q: q.join('&'),
		obj: o
	}
};

var HTTP = axios.create({
	baseURL: apiURL,
	maxContentLength: 10000,
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
	}
})

// request interceptor
HTTP.interceptors.request.use(function (config) {
	if (['put', 'post', 'delete'].indexOf(config.method) != -1) {
		config.headers.Authorization = `Bearer ${auth.idToken()}`;
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});

var API = {
	$http: HTTP,
	post: HTTP.post,
	get(url, params = false) {
		if (params) {
			let query = toQueryObject(params)
			return HTTP.get(`${url}?${query.q}`)
		}
		return HTTP.get(url)
	},
	put: HTTP.put,
	delete: HTTP.delete,

	queryString: queryString,
	toQueryObject: toQueryObject,
	now() {
		return new Date()
	},
	uid() {
		return shortid()
	},
	auth(params) {
		return HTTP.post(authURL, params)
	},
}
export default API
