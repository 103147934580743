export default {
	data() {
		return {
		}
	},
	computed: {

	},
	methods: {

		updatePost(uid, payload) {
			if (!uid) {
				console.error('missing uid');
				return;
			}
			return new Promise((resolve, reject) => {
				this.$api.put(`posts/${uid}`, payload).then((e) => {
					resolve(e)
				}).catch(err => {
					reject(err)
				})
			})
		},

		setPostStatus(uid, status) {
			if (!uid) {
				console.error('missing uid');
				return;
			}
			return new Promise((resolve, reject) => {
				let statusOptions = ['published', 'draft', 'trash']
				if (statusOptions.indexOf(status) == -1) {
					console.error('Status is not a option', status);
					return;
				}
				let options = {
					title: 'Are you sure you want to delete this page',
					buttons: true,
					confirmButtonClass: 'is-danger'
				}
				this.$swal(options).then(e => {
					if (e === true) {
						this.isAuth().then(() => {
							this.updatePost(uid, { status: status }).then(() => {
								resolve()
							})
						})
					}
				}).catch(e => {
					reject(e)
				})
			})
		},

		// -------------------------------------
		getPostTitle(post) {
			return post.title.replace(/<(?:.|\n)*?>/gm, '');
		},

		// -------------------------------------
		defaultPage(uid) {
			uid = uid || this.$db.uid()
			var title = `${uid}`
			return {
				image: null,
				thumbnail: null,
				title: title,
				slug: this.$slugify(title),
				status: 'draft',
				order: -1,
				categories: [],
				sections: {},
				description: null,
				id: uid,
			}
		},

		// -------------------------------------
		baseSection(order, key) {
			return {
				name: 'BaseSection',
				size: 'normal',
				type: 'section',
				order: order == undefined ? -1 : order,
				uid: key,
				items: {}
			}
		},

		// -------------------------------------
		baseGallery(order) {
			var id = this.$db.uid();
			return {
				name: 'BaseGallery',
				size: 'normal',
				type: 'gallery',
				uid: id,
				id: id,
				order: order == undefined ? -1 : order,
				items: {}
			}
		},

		// -------------------------------------
		baseHero(order) {
			var id = this.$db.uid();
			return {
				name: 'BaseHero',
				size: 'large',
				image: null,
				type: 'hero',
				titleClass: 'light',
				video: null,
				uid: id,
				id: id,
				order: order == undefined ? -1 : order,
				color: {
					rgba: {
						r: 0,
						g: 0,
						b: 0,
						a: 0
					},
				}
			}
		},

		// create new post
		createNewPost() {
			var basePage = this.defaultPage()
			this.$api.post('posts', basePage).then((e) => {
				if (e.data.error) {
					this.$swal({
						title: 'Opps Soemthing went wrong',
						text: e.data.error,
						icon: 'warning'
					})
				} else {
					this.$router.push({ path: `/page/${e.data.slug}/edit` })
				}
			})
			//
			// console.log('Create new Post');
		},

		gotoEditPage(item) {
			var slug = item.id
			if (slug) {
				var url = '/page/' + slug + '/edit'
				this.$router.push({ path: url })
			}
		},

		gotoPage(item) {
			if (item.external_url) {
				document.location = item.external_url
			}
			else {
				var slug = item.id
				if (slug) {
					var url = '/page/' + slug
					this.$router.push({ path: url })
				}
			}
		},

		// page updates
		// -------------------------------------
		setPageSlugFromTitle() {
			var slug = this.slugify(this.page.title)
			this.page.slug = slug
			this.save().then(() => {
				var url = '/page/' + slug + '/edit'
				this.$router.replace({ path: url })
			})
		}
	}
}
