import moment from 'moment'
import contentSizes from '../content-sizes'
import isStoreRoot from '../utils/is-store-root'

export default {
	data() {
		return {
			heroSizes: contentSizes.hero,
			itemSizes: contentSizes.item,
			sectionSizes: contentSizes.section,
			gallerySizes: contentSizes.gallery,
			siteMeta: require('../../site-meta'),
		}
	},
	computed: {
		$categories() {
			return this.$store.state.categories
		},
		$isStoreRoot() {
			return isStoreRoot();
		},
		isStorePage() {
			console.log(this.$route.name);
			return 1
		},
		isHomePage() {
			return this.$route.name == 'index'
		},
		isPostPage() {
			return this.$route.name == 'page' || this.$route.name == 'edit-page'
		},
		isAdminPage() {
			return this.$route.name ? this.$route.name.indexOf('admin') > -1 : false
		},
		isLoginPage() {
			return this.$route.name == 'login'
		}
	},
	methods: {
		log(e) {
			console.log(e)
		},
		emailMe() {
			var email = this.profile.contact_email || this.profile.email;
			document.location = "mailto:" + email;
		},
		columnSize(size, sizes) {
			if (!sizes[size]) {
				console.error('Error in column size', size);
				return sizes[0];
			} else {
				return sizes[size]
			}
		},
		defaultDate() {
			return moment().format('MMM D Y')
		},
		getCategory(key) {
			var cat = this.$categories[key];
			if (cat) {
				cat['.key'] = key
			}
			return cat
		},
		imageURL(filename, options) {
			return `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.web.app/cdn/image/${filename}${options ? '?size=' + options : ''}`;
		},

		setMetaAttribute(key, value) {
			var tags = document.querySelectorAll(`[${key}]`);
			if (tags.length) {
				tags[0].setAttribute("content", value);
			}
		},

		setSocialMetaAttribute(key, value) {
			var tags = Array.from(document.getElementsByTagName("meta")).filter((item) => {
				return item.getAttribute("property") === key;
			});
			if (tags.length) {
				tags[0].setAttribute("content", value);
			}
		},
		setDefaultMeta(meta) {
			document.getElementsByTagName("title")[0].innerHTML = meta.title;
			this.setMetaAttribute("name=title", meta.title);
			this.setMetaAttribute("name=description", meta.description);
			this.setMetaAttribute("name=keywords", meta.keywords);
			this.setSocialMetaAttribute("og:url", meta.url);
			this.setSocialMetaAttribute("og:title", meta.title);
			this.setSocialMetaAttribute("og:description", meta.description);
			this.setSocialMetaAttribute("og:image", meta.image);

			this.setSocialMetaAttribute("twitter:url", meta.url);
			this.setSocialMetaAttribute("twitter:title", meta.title);
			this.setSocialMetaAttribute("twitter:description", meta.description);
			this.setSocialMetaAttribute("twitter:image", meta.image);
		}
	}
}
