<template>
    <nav class="nav-bar">
        <div class="container">
            <div class="nav-bar-container">

                <div class="nav-bar-side nav-bar-left">
                    <!-- <router-link class="nav-bar-item site-nav-link" :to="getStoreLink('')"><b>Nilrednav Store</b></router-link> -->
                </div>

                <div class="nav-bar-side nav-bar-center">
                    <router-link class="store-title nav-bar-item site-nav-link" :to="getStoreLink('')">{{$profile.store_name}}</router-link>
                </div>

                <div class="nav-bar-side nav-bar-right">
                    <router-link class="nav-bar-item" :to="getStoreLink('cart')">
                        <span class="number-of-items" :class="{'is-empty': totalProductsInCart==0}">
                            <span>{{totalProductsInCart}}</span>
                        </span>
                        <span class="icon">
                            <img src="@/assets/img/cart.svg" alt="Shopping Cart">
                        </span>
                    </router-link>
                    <span class="currency">USD</span>
                </div>

            </div>
        </div>
    </nav>
</template>

<script>
import { StoreMixins } from "@/mixins";
export default {
    name: "StoreNav",
    mixins: [StoreMixins],
    props: {},
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.number-of-items {
    font-size: $size-7;
    @include square(20px);
    @include flexCenter;
    border-radius: 50%;
    border: 2px solid $lighter-gray;
    top: 1px;
    position: relative;
    span {
        position: relative;
        top: -1px;
    }
    &.is-empty {
        opacity: 0;
    }
}
.currency {
    font-family: $heading-font;
    margin-left: 5px;
}
// .navbar-center {
//     background-color: blue;
//     justify-content: center;
// }

a.store-title {
    text-transform: uppercase;
    font-family: $heading-font-2 !important;
    letter-spacing: 0.1rem;
    font-weight: 600 !important;
}
</style>
