export default {
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        servingImage(obj, q) {
            var p = q ? '=' : ''
            q = q || ''

            if (obj instanceof Object) {
                var isSVG = obj.type.indexOf('svg') > -1
                if (isSVG) {
                    p = ''
                    q = ''
                }
                return `${obj.serving_url}?size=${p}${q}`
            }
            return `${obj}${p}${q}`
        }
    }
}
