<template>
	<a class="plus-button" @click.prevent="click">
	    <span class="icon">
	        <i class="fa fa-plus" aria-hidden="true"></i>
	    </span>
	</a>
</template>

<script>
export default {
    name: 'PlusButton',
    props: {
    	click: {
    		type: Function,
    		default: () => {}
    	}
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
@import '../../styles/variables';
.plus-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: $light-gray;
	transition: all 200ms;
	color: $dark-gray;
	&:hover {
		background-color: $dark-gray;
		color: white;
	}
}
</style>