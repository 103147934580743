<template>
    <div class="cart-popover" :class="{'is-active': show}" v-if="item">
    
        <nav class="nav">
            <div class="title is-size-7">Just added to your cart</div>
            <a class="button is-remove-button is-remove-button-left" @click.prevent="() => {$store.commit('showCartPopover', false)}">
                <span class="icon">
                    <i class="fa fa-remove"></i>
                </span>
            </a>
        </nav>
        <div class="items">
            <div class="item">
            <div class="product-image">
                <img :src="item.variant.image.src" :alt="item.variant.image.alt">
            </div>
            <div class="details">
                <div class="product-title">{{item.title}}</div>
                <div class="product-details">Qty: {{item.quantity}}</div>
            </div> 
        </div>
        </div>
        <div class="cart-footer">
            <router-link :to="getStoreLink('cart')" class="button">View Cart ({{totalItems}})</router-link>
            <a href="" class="shop-link title is-size-7">Continue Shopping</a>
        </div>
  
    </div>
</template>

<script>
import { StoreMixins } from "@/mixins";
export default {
    name: "",
    props: {},
    mixins: [StoreMixins],
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {
        show() {
            return this.$store.state.showCartPopover
        },
        totalItems() {
            return this.$store.state.checkout.lineItems.length
        },
        item() {
            return this.$store.state.checkout.lineItems.length ? this.$store.state.checkout.lineItems[0] : false
        }
    },
    watch: {},
    created() {},
    destroyed() {},
    mounted() {},
};
</script>
<style lang="scss" scoped>
@import '~@/styles/mixins';
@import '~@/styles/variables';
.cart-popover {
    z-index: 1000;
    position: fixed;
    background: white;
	box-shadow: 0 0 9px rgba(0, 0, 0, 0.2) ; 
    right: 10px;
    top: -1000px;
    width: 400px;
    transition: all 300ms;
    &.is-active {
        top: 0
    }
    .nav {
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            margin-bottom: 0;
        }
        .button {
            background: none;
            border: none;
        }
    }
   
    .item {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
        padding: 0 10px;
        .details {
            margin-bottom: 10px;
        }
        .product-image {
            height: 120px;
            width: 120px;
            margin-right: 12px;
            overflow: hidden;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
        }
    }
    .cart-footer {
        padding: 20px;
        width: 100%;
        background: rgb(216, 216, 216);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        .button {
            margin-bottom: 20px;
        }
        a.shop-link {
            text-decoration: underline;
        }
    }

    @include mobile() {
        width: 100%;
        right: inherit;
    }
}
</style>