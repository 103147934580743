<template>
	<div class="page-image-loader">
		<img v-show="!isLoaded" :src="lowResURL" alt="" key="low-res" class="preload-image" @dragstart.prevent="handleDrag">
		<img v-show="isLoaded" :src="highResURL" :srcset="srcSet" alt="" key="high-res" class="page-image" @dragstart.prevent="handleDrag" v-image-zoom="doesZoom">
	</div>
</template>

<script>
export default {
    name: 'PageImage',
    props: {
    	isEditable: {
    		default: false
    	},
    	lowResSize: {
    		default: 's5'
    	},
    	highResSize: {
            type: Number,
    		default: 1024
    	},
    	image: {
            type: String,
    		required: true
    	},
        doesZoom: {
            default: true
        }
    },
    data() {
        return {
        	isLoaded: false
        }
    },
    components: {
    },
    methods: {
        handleDrag(e) {
            return false
            console.log(e);
        }
    },
    computed: {
    	lowResURL() {
    		return this.imageURL(this.image, this.lowResSize);
    	},
    	highResURL() {
    		return this.imageURL(this.image, `s${this.highResSize}`);
    	},
        srcSet() {
            var s1 = parseInt(this.highResSize) * 1.5;
            var s2 = parseInt(this.highResSize) * 2;
            var url = this.imageURL(this.image)
            var c = `${url}=s${s1} 1.5x, ${url}=s${s2} 2x`;
            return null
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    	var image = new Image();
    	image.onload = (e) => {
    		setTimeout(() => {
    			this.isLoaded = true
    		}, 300);
    	}
    	image.src = this.highResURL
    }
}
</script>

<style scoped lang="scss">
.page-image-loader {
    .preload-image {
        width: 100%;
    }
    .page-image {
    }
}
</style>