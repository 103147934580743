<template>
    <div class="modal" :class="{'is-active': show}" v-show="show">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <template v-if="videoID">
                <div v-if="title" class="title has-text-white">{{title}}</div>
                <video-player :id="videoID" :autoplay="autoplay"></video-player>
            </template>
            <template v-if="!videoID">
                <slot></slot>
            </template>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
</template>

<script>
import ModalMixin from "./ModalMixin";
export default {
    name: "Modal",
    mixins: [ModalMixin],
    props: {},
    data() {
        return {
            videoID: null,
            title: null,
        };
    },
    components: {},
    methods: {
        cleanup() {},
        close() {
            this.show = false;
            this.$nextTick(() => {
                this.videoID = null;
            });
            this.$emit("close");
            console.log("modal close");
            this.cleanup();
        },
        open(options) {
            options = options || {};
            this.videoID = options.videoID;
            this.autoplay = options.autoplay;
            this.title = options.title;
            this.show = true;
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        console.log("modal mounted");
    },
};
</script>

<style scoped lang="scss">
// .modal-mask {
//     position: fixed;
//     z-index: 9998;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, .9);
//     transition: opacity .2s ease;
// }

// .modal-container {
//     height: 100vh;
//     margin: 40px auto 0;
//     display: flex;
//     align-items: center;
//     .modal-body {
//         width: 100%;
//         padding: 10px;
//         background-color: #fff;
//         border-radius: 2px;
//         box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
//         transition: all .3s ease;
//     }
// }

// .modal-header h3 {
//     margin-top: 0;
//     color: #42b983;
// }

// .modal-body {
//     margin: 20px 0;
// }

// .text-right {
//     text-align: right;
// }

// .form-label {
//     display: block;
//     margin-bottom: 1em;
// }

// .form-label > .form-control {
//     margin-top: 0.5em;
// }

// .form-control {
//     display: block;
//     width: 100%;
//     padding: 0.5em 1em;
//     line-height: 1.5;
//     border: 1px solid #ddd;
// }

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
// .modal-enter {
//   opacity: 0;
// }

// .modal-leave-active {
//   opacity: 0;
// }

// .modal-enter .modal-container,
// .modal-leave-active .modal-container {
//   -webkit-transform: scale(1.1);
//   transform: scale(1.1);
// }
</style>
