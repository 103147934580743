<template>
    <footer class="store-footer footer">
        <div class="container">
            <div class="columns is-gapless">
                <div class="column is-4">
                    <div class="text" v-html="$profile.store_footer_message"></div>
                    <div class="social-links">
                        <a :href="item.url" class="social-link" target="_blank" v-for="(item, name) in activeSocial" :key="name">
                            <icon :icon="item.icon"></icon>
                        </a>
                    </div>
                    <div class="text">
                        Handmade with <img class="heart-image" src="@/assets/img/heart.png" alt="❤️"> in {{$profile.location_name}}
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import _ from "lodash";
export default {
    name: "StoreFooter",
    props: {
        social: {
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {
        activeSocial() {
            return _.filter(this.social, function (obj) {
                if (obj.url) {
                    return true;
                }
            });
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";
@import "../../styles/mixins";
.social-links {
    margin-top: 10px;
}
.social-link {
    padding: 2px;
    color: rgba(177, 177, 177, 0.8);
    transition: all 200ms;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-left: 0px;
    margin-right: 25px;
    :first-child {
        margin-left: -7px;
    }
    &:hover {
        color: $white-color;
    }
    @include mobile() {
        margin-left: 2px;
        margin-right: 2px;
        font-size: 22px;
    }
}
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;
    padding-top: 100px;
    .main-footer {
        width: 100%;
    }
}
.footer-note {
    padding-top: 50px;
}
.heart-image {
    width: 15px;
    position: relative;
    top: 2px;
}
.footer-store-logo {
    width: 20px;
}
</style>
