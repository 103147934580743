var env = process.env.NODE_ENV;

import Vue from 'vue';
import config from './config';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';

import api from './api'
import store from './store'
import shortid from 'shortid'
import db from './db';

const loginWithGoogle = () => {
	var provider = new firebase.auth.GoogleAuthProvider();
	provider.addScope('https://www.googleapis.com/auth/plus.login');
	provider.addScope('profile');
	provider.addScope('email');
	firebase.auth().signInWithRedirect(provider);
}

const loginWithEmailPassword = (email, password) => {
	return new Promise((resolve, reject) => {
		return firebase.auth().signInWithEmailAndPassword(email, password).catch(error => {
			reject(error);
		})
	})
}

const defaultProfile = {
	name: 'A Boy',
	shortBio: 'This is a sort story about a boy and a bucket of peanut butter.',
	longBio: '',
	image: null
}

function getIdToken() {
	return new Promise((resolve, reject) => {
		firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
			// console.log('idToken', idToken);
			resolve(idToken)
		}).catch(function (error) {
			// console.log('idToken error', error);
			reject(null)
		});
	})
}

export default {
	loginWithEmailPassword: loginWithEmailPassword,
	currentUser() {
		return firebase.auth().currentUser;
	},
	sendPasswordReset(email) {
		return new Promise((resolve, reject) => {
			var auth = firebase.auth();
			auth.sendPasswordResetEmail(email).then(() => {
				resolve(`Email sent to ${email}`)
			}).catch(function (error) {
				reject(error.message)
			});
		})
	},
	signout() {
		localStorage.removeItem('id_token')
		firebase.auth().signOut().then(() => {
			document.location = '/'
		})
	},
	isAuth() {
		var user = firebase.auth().currentUser;
		var jwt = localStorage.getItem('id_token')
		if (jwt && user) {
			return true
		}
		else {
			return false
		}
	},
	idToken() {
		return localStorage.getItem('id_token')
	},
	getAuthHeader() {
		return {
			'Authorization': 'Bearer ' + localStorage.getItem('id_token')
		}
	},
	async init() {
		return new Promise(async (resolve, reject) => {

			let profile = await (await db.doc('profile').get()).data()

			db.collection('categories').onSnapshot(snap => {
				var categories = snap.docs.map(doc => {
					return { id: doc.id, ...doc.data() }
				});
				store.commit('categories', categories)
			});

			store.commit('profile', profile)

			firebase.auth().onAuthStateChanged((user) => {

				// if we have a user we want to update the profile
				// with the doc in firestore
				if (user) {

					console.log('Auth state change: ', user.email);

					getIdToken().then((idToken) => {
						localStorage.setItem('id_token', idToken)
						store.commit('authenticated', true);
						// console.log('auth id token', idToken);
						resolve(true)
					}).catch(err => {
						console.log('ID Token Error: ', err);
					})

				} else {
					console.log('no user authed');
					resolve(null)
				}
			})
		})
	}

}
