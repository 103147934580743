import moment from 'moment'
import accounting from 'accounting'

export default {
	capitalize(str) {
		return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
	},
	normalcase(str) {
		if (!str) {
			return ''
		}
		var s = str.toLowerCase()
		return s.charAt(0).toUpperCase() + s.slice(1)
	},
	fromnow(time, format) {
		if (time) {
			return moment(time, format).fromNow()
		} else {
			return '';
		}
	},
	dateFormat(time, format) {
		format = format || 'MMM YYYY'
		if (time) {
			return moment(time).format(format)
		} else {
			return '';
		}
	},
	currency(val) {
		return accounting.formatMoney(val).replace(/\.00$/, '');
	},
	price(val) {
		var v = parseFloat(val)
		return accounting.formatMoney(v).replace(/\.00$/, '');
	},
}
