<template>
    <div class="page-gui item-gui" v-if="isEditable">
        <nav class="navbar gui-controls">

            <item-sizes v-model="item.size" :sizes="itemSizes" @input="(name) => {$emit('setItemSize', {item, name})}"></item-sizes>

			<!-- remove item -->
            <a class="navbar-item" @click="() => { $emit('removeItem', item) }">
                <icon size="is-small" icon="fa-trash"></icon>
            </a>

			<!-- video input -->
            <div class="video-gui" v-if="item.type == 'video'">
                <div class="field has-addons">
                    <p class="control">
                        <input class="input" type="text" :value="item.video" v-keyupDebounce="saveVimeoID" placeholder="Vimeo video ID">
                    </p>
                    <p class="control">
                        <a class="button is-primary">
                            <icon size="is-small" icon="fa-check"></icon>
                        </a>
                    </p>
                </div>
            </div>
            <!-- end video input -->
            <!-- <add-item :section="section" @addItem="addItemToSection" :sectionKey="sectionKey" :fromItem="item"></add-item> -->
            <a class="navbar-item draggable-handle" @click.prevent="getInfo">
                <icon size="is-small" icon="fa-info"></icon>
            </a>

            <!-- draggable handle -->
            <!--<a class="navbar-item draggable-handle" @click.prevent="()=>{}">
                <icon size="is-small" icon="fa-arrows"></icon>
            </a>-->
            <!-- move up section -->
            <a class="navbar-item" @click.prevent="moveItem(item.uid, -1)">
                <icon icon="fa-arrow-up" size="is-small"></icon>
            </a>

            <!-- move down section -->
            <a class="navbar-item" @click.prevent="moveItem(item.uid, 1)">
                <icon icon="fa-arrow-down" size="is-small"></icon>
            </a>

        </nav>
    </div>
</template>

<script>
import ItemSizes from './ItemSizes'
export default {
    name: 'ItemGui',
    props: {
    	// itemRef: {
    	// 	required: true,
    	// },
    	setItemSize: {
    		default: () => {}
    	},
    	isEditable: {
    		default: false
    	},
    	item: {
    		default: () => {
    			return {}
    		}
    	},
        moveItem: {
            type: Function,
            default: () => {

            }
        },
        updateVideoID: {
            type: Function,
            default: () => {
            }
        }
    },
    data() {
        return {
        }
    },
    components: {
        ItemSizes
    },
    methods: {
    	saveVimeoID(value) {
    		this.item.video = value
            this.$emit('updateVideoID', this.item)
    	},
    	getInfo() {
    		console.log(this.item);
    	}
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">

</style>
