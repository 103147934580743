<template>
    <div class="control tagger">
        <div class="field is-grouped is-grouped-multiline tag-list">
            <div class="control" v-for="item in value" :key="item">
                <div class="tags has-addons">
                    <a :class="tagSize" class="tag is-link">{{tagName(item)}}</a>
                    <a :class="tagSize" class="tag is-delete" @click.prevent="removeTag(item)"></a>
                </div>
            </div>
        </div>
        <div class="field">
            <p class="control has-icons-right">
                <input class="input" type="text" :placeholder="placeholder" @focus="focus" @blur="blur" @keypress.enter="handleEnter" v-model="inputValue" @input="handleInput($event.target.value)">
                <span class="icon is-small is-right">
                    <i class="fa" :class="{'fa-plus': !doesExist && inputValue, 'fa-check': doesExist && inputValue}"></i>
                </span>
            </p>
            <div class="dropdown is-active" :class="{'is-empty': filtered.length==0}">
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <a :href="`#${item.name}`" class="dropdown-item" v-for="item in filtered" @click.prevent="addTag(item)" :key="item.name">
                            {{item.name}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <p class="help" v-if="!doesExist && inputValue">press enter to add new tag <b><i>{{inputValue}}</i></b></p> -->
    </div>
</template>

<script>
import _ from "lodash";
var blurTimeout;
export default {
    name: "Tagger",
    props: {
        tagSize: {
            default: "is-medium",
        },
        field: {
            default: "name",
        },
        options: {
            type: Array,
            default: () => {
                return [];
            },
        },
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        openOnFocus: {
            default: true,
        },
        keepFirst: {
            default: true,
        },
        placeholder: {
            type: String,
            default: "Enter text",
        },
    },
    data() {
        return {
            inputValue: null,
            filtered: [],
            doesExist: false,
        };
    },
    components: {},
    methods: {
        focus() {
            this.filtered = this.filteredAlreadySelected();
        },
        blur() {
            if (blurTimeout) {
                clearTimeout(blurTimeout);
            }
            blurTimeout = setTimeout(() => {
                this.filtered = [];
            }, 200);
        },
        filterBy(name, search) {
            return (name || "").toLowerCase().indexOf(search.toLowerCase()) > -1;
        },
        clear() {
            this.filtered = [];
            this.inputValue = null;
        },
        tagExists(e) {
            return _.findLastIndex(this.options, { name: e.name }) > -1;
        },
        tagIndex(e) {
            return _.findLastIndex(this.options, { name: e.name });
        },
        filteredAlreadySelected() {
            return _.filter(this.options, (item) => {
                var hasItem = false;
                for (var i = 0; i < this.value.length; i++) {
                    if (this.value[i].name == item.name) {
                        hasItem = true;
                        break;
                    }
                }
                if (!hasItem) {
                    return item;
                }
            });
        },

        parse(input) {
            return new Promise((resolve) => {
                // // do we have a value
                if (input == "") {
                    this.filtered = [];
                    resolve(null);
                    return;
                }

                // does this tag exist in our value ?
                var index = this.value.indexOf(input);

                if (index == -1) {
                    resolve({ add: true, tag: input });
                }

                resolve(null);

                //
                // // create a slug and name
                // var slug = this.slugify(value)
                // var name = value
                //
                // // create a results obj
                // var results = {slug: slug, name: name, exists: false, tag: null}
                //
                // this.filtered = _.filter(this.filteredAlreadySelected(), (item) => {
                // 	if (this.filterBy(item.name, name)) {
                // 		return item
                // 	}
                // });
                //
                // results['filtered'] = this.filtered;
                // var tagIndex = this.tagIndex(results)
                //
                // var filterIndex = _.findLastIndex(this.filtered, {name: name})
                // results['tag'] = filterIndex > -1 ? this.filtered[filterIndex] : null
                //
                // results['exists'] = tagIndex > -1
                //
                // console.log(results);
                // resolve(results)
            });
        },
        tagName(tag) {
            if (typeof tag == "string") {
                return tag;
            }
            return tag[this.field];
        },

        removeTag(tag) {
            this.$emit("remove", tag);
            this.$emit("input", { remove: true, tag: tag });
        },

        addTag(item) {
            this.$emit("add", item);
            this.clear();
        },

        handleEnter() {
            if (this.inputValue) {
                this.parse(this.inputValue).then((results) => {
                    if (results) {
                        this.$emit("results", results);
                        this.$emit("input", results);
                    }
                    this.clear();
                });
            }
        },

        handleInput() {
            this.parse(this.inputValue).then((results) => {
                if (results) {
                    this.doesExist = results.exists;
                }
            });
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables";
@import "~@/styles/mixins";
</style>
