<template>
    <div>
    	<figure>
    		<img :src="src" alt="">
	    	<input class="image-input-file" type="file" accept="image/*" :multiple="false" @change.prevent="handleImage($event)">
	    	<span class="file-help-text">Click to replace</span>
    	</figure>
    </div>
</template>

<script>
import {ImageUploaderMixins} from '@/mixins'
export default {
    name: 'ImageInput',
    mixins: [ImageUploaderMixins],
    props: {
        firebaseRef: {
            default: null
        },
    	contentID: {
    		required: true
    	},
    	src: {
    		required: true
    	}
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        handleImage(e) {
            this.hasSingleFile(e).then(file => {
                this.uploadImage({file:file, content_id:this.contentID}).then(image => {
                    this.$emit('imageUploaded', image)
                })
            })
        }
    },
    computed: {
    	imageURL() {
    		return this.hasImage ? this.images[this.contentID] : 'http://www.infozonelive.com/styles/FLATBOOTS/theme/images/user4.png'
    	},
    	hasImage() {
    		return  (this.images && this.contentID) ? this.images[this.contentID] : false
    	}
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
figure {
	position: relative;
	&:hover {
		.file-help-text {
			opacity: 1;
		}
	}
}
.image-input-file {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: red;
	opacity: 0;
	&:focus {
		outline: none;
	}
}
.file-help-text {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	font-size: 13px;
	background-color: rgba(0, 0, 0, 0.4);
	justify-content: center;
	align-items: center;
	color: white;
	pointer-events: none;
}
</style>
