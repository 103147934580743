<template>
    <div :contenteditable="editable" @input="updateValue($event.target.innerHTML)" v-html="text"></div>   
</template>

<script>
export default {
    name: 'Editable',
    props: {
        text: {
            required: true,
            default: ''
        },
        editable: {
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        updateValue:function(value) {
            this.$emit('edit', value)
        }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    }
}
</script>
<style scoped lang="scss">
</style>
<!-- Vue.component('editable',{
  template:'<div contenteditable="true" @input="update"></div>',
  props:['content'],
  mounted:function(){
    this.$el.innerText = this.content;
  },
  methods:{
    update:function(event){
      this.$emit('update',event.target.innerText);
    }
  }
})

var example = new Vue({
  el: '#example',
  data: {
    text:"This text is editable!"
  }
}); -->