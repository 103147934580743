<template>
    <div class="main-content home-page">

        <modal ref="modal"></modal>

        <!-- home page hero -->
        <section class="hero is-fullheight home-hero" :style="{'background-color' : toColor($profile.homeColor)}">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-multiline">
                        <div class="column is-10-desktop">
                            <text-input v-model="$profile.intro" textClass="site-intro" placeholder="Hello I am a person and I like to make things." :useRichEditor="true" :isEditable="editPage" @input="saveIntro">
                            </text-input>
                        </div>
                        <div class="column is-12">
                            <div class="home-hero-nav">
                                <page-link v-for="(link, i) in $profile.home_links" :url="link.url" :title="link.title" :key="i"></page-link>
                                <!-- <page-link v-if="$profile.show_email_home" :url="`mailto:${$profile.email}`" title="Email me" key="email"></page-link> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- color picker dropdown -->
            <div v-if="editPage" class="dropdown is-right is-hoverable home-color-picker" :style="{'background-color' : toColor($profile.homeColor)}">
                <div class="dropdown-trigger">
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content ">
                        <color-picker v-model="$profile.homeColor" @input="updateHomeColor"></color-picker>
                        <button class="color-clear-button vc-sketch-presets-color" @click.prevent="update({color: null})">
                            <span class="icon is-small">
                                <i class="fa fa-close"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

        </section>
        <!-- end home page hero -->

        <loading-page :isLoading="!isLoaded"></loading-page>
        <div class="section" v-if="isLoaded">
            <div class="container">

                <h1 class="section-title" id="projects">Projects</h1>

                <!-- project list -->
                <post-grid :isEditable="editPage" :posts="projects"></post-grid>
                <!-- end project list -->

                <!-- add content -->
                <div class="columns is-mobile is-multiline" v-if="authenticated">
                    <div class="column is-12 add-content-navbar">
                        <!--<a class="is-link" @click.prevent="() => {$refs.addLinkModal.open()}">
                             <span class="icon">
                                <i class="fa fa-link"></i>
                            </span>
                        </a>
                        <a class="is-link" @click.prevent="() => {$refs.addVideoModal.open()}">
                             <span class="icon">
                                <i class="fa fa-vimeo"></i>
                            </span>
                        </a>-->
                        <a class="is-link is-round-icon-button" @click.prevent="createNewPost">
                            <span class="icon">
                                <i class="fa fa-plus"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <!-- end add content -->

            </div>
        </div>

        <edit-page-button :isActive="editPage" @click="() => {editPage = !editPage}"></edit-page-button>
        <!-- edit home page button -->

        <main-footer :social="$profile.social"></main-footer>

    </div>
</template>

<script>
import { PageMixins } from "@/mixins";
import PageLink from "@/components/ui/PageLink";
import { Sketch as ColorPicker } from "vue-color";
export default {
    name: "IndexPage",
    props: {},
    mixins: [PageMixins],
    data() {
        return {
            editPage: false,
            postsRef: null,
            showEditModal: false,
            projects: [],
            isLoaded: false,
        };
    },
    components: {
        PageLink,
        ColorPicker,
    },
    methods: {
        async updateHomeColor(e) {
            await this.$db.doc("profile").update({ homeColor: e });
        },
        saveContentOrder() {},
        openEditPage(item) {
            if (item.isLink) {
                this.$refs.linkModal.open({ uid: item[".key"] });
            } else {
                this.$router.push({ path: `/page/${item[".key"]}/edit` });
            }
        },
        openPage(item) {
            // we are just a simple link
            if (item.isLink && item.url) {
                window.open(item.url, "_blank");
            } else if (item.isVideo && item.videoID) {
                this.$refs.modal.open({
                    videoID: item.videoID,
                    autoplay: true,
                    title: item.title,
                });
            } else {
                this.$router.push({ path: "/page/" + item.slug });
            }
        },
        toggleEdit() {
            if (this.isEditable) {
                this.saveProfile();
            }
            this.isEditable = !this.isEditable;
        },
        handleProfileImage(image) {
            console.log(image);
            this.profile.image = image[".key"];
            this.saveProfile();
        },
        saveProfile() {
            //  var payload = this.parsePayload(this.profile)
            //this.$firebaseRefs.profile.update(payload)
            //console.log('Save Profile', payload);
        },
        async createNewPost() {
            var doc = this.$db.collection("posts").doc();
            var basePage = this.defaultPage(doc.id);
            await doc.set(basePage);
            this.$router.push({ path: "/page/" + basePage.id + "/edit" });
        },
        async saveIntro(e) {
            await this.$db.doc("profile").update({ intro: e });

            // var payload = {
            //     intro: this.profile.intro
            // }
            // this.$api.put('profile', payload).then((e) => {
            //     console.log('save the profile intro');
            // })
        },
        save() {
            if (this.authProfile) {
                this.$db
                    .ref(`users/${this.authProfile.uid}`)
                    .update({
                        github: this.parseValue(this.authProfile.github),
                        color: this.parseValue(this.authProfile.color),
                    })
                    .then(() => {
                        this.showEditModal = false;
                    });
            }
        },
    },
    computed: {},
    watch: {},
    created() {},
    async mounted() {
        var results = await this.$db
            .collection("posts")
            .where("status", "==", "published")
            .where("categories", "array-contains-any", ["projects"])
            .get();
        this.projects = results.docs.map((item) => {
            return item.data();
        });
        this.isLoaded = true;
        this.setDefaultMeta(this.siteMeta);
    },
    destroyed() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
.sidebar {
    background: #eee;
}

.edit-button {
    margin-top: 30px;
}

.add-content-navbar {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.other-items {
    margin-bottom: 250px;
    a {
        text-decoration: underline;
    }
}
.home-color-picker {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    border-radius: 50%;
    border: 1px solid #444;
    .vc-sketch:after {
        right: 6px !important;
        left: inherit;
    }
    .dropdown-content {
        background: none;
        box-shadow: none;
        padding-bottom: 0;
        padding-top: 0;
    }
}
</style>
