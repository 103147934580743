<template>
    <div class="modal" :class="{'is-active': show}" v-if="show">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Add Video</p>
                    <button class="delete" aria-label="close" @click="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field content-file-thumbnail">
                        <figure class="has-file" v-droppable="authenticated" @drop="handleLinkThumbnail">
                            <img v-if="item.thumbnail" :src="thumbnailImage" alt="">
                            <icon class="page-thumbnail-icon" v-if="!item.thumbnail && !isLoadingThumbnail" icon="fa-photo" size="is-large"></icon>
                            <icon class="page-thumbnail-icon" v-if="isLoadingThumbnail" icon="fa fa-cog fa-spin" size="is-large"></icon>
                            <input type="file" name="page-thumbnail" accept="image/*" multiple="false" @change="handleLinkThumbnail">
                        </figure>
                    </div>
                    <div class="field">
                        <label class="label">Video Name</label>
                        <div class="control">
                            <input class="input" type="text" v-model="item.title" placeholder="Best link ever!">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Viemo ID</label>
                        <div class="control">
                            <input class="input" type="text" v-model="item.videoID" placeholder="1234567">
                        </div>
                    </div>
                </section>

                <footer class="modal-card-foot">
                    <div class="field is-grouped is-grouped-left">
                        <p class="control">
                            <button class="button is-success" @click.prevent="save">Save</button>
                            <button class="button is-light" @click="cancel">Cancel</button>
                            <button class="button has-text-danger is-light" @click="remove">Remove</button>
                        </p>
                    </div>
                </footer>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
</template>

<script>
import ModalMixin from "./ModalMixin";
import { ImageUploaderMixins } from "@/mixins";
export default {
    name: "AddVideoModal",
    mixins: [ModalMixin, ImageUploaderMixins],
    props: {},
    data() {
        return {
            uid: null,
            isLoadingThumbnail: false,
            item: {
                videoID: null,
                title: null,
                thumbnail: null,
            },
        };
    },
    components: {},
    methods: {
        getPayload() {
            var payload = {
                isVideo: true,
                videoID: this.item.videoID,
                title: this.item.title,
                updatedAt: this.firebaseTimestamp(),
            };
            return payload;
        },
        save() {
            var payload = this.getPayload();
            this.$firebaseRefs.item.update(payload);
            this.close();
        },
        close() {
            this.show = false;
            this.$emit("close");
            this.clear();
        },
        open(options) {
            options = options || {};
            this.uid = options.uid;
            this.show = true;
            if (!this.uid) {
                var temp = this.$db.ref("content").push();
                this.uid = temp.key;
            }
            var ref = this.$db.ref(`content/${this.uid}`);
            this.$bindAsObject("item", ref);
        },
        handleLinkThumbnail(e) {
            this.isLoadingThumbnail = true;
            this.hasSingleFile(e).then((file) => {
                var oldImage = this.getImage(this.thumbnail);
                this.uploadImage({ file: file, content_id: this.uid, existing: oldImage }).then((image) => {
                    this.item.thumbnail = image[".key"];
                    var payload = {
                        ...this.getPayload(),
                        ...{ thumbnail: image[".key"] },
                    };
                    this.$firebaseRefs.item.update(payload);
                    this.isLoadingThumbnail = false;
                });
            });
        },
        clear() {
            this.item = null;
        },
        remove() {
            this.$firebaseRefs.item.update({ status: "draft" });
            this.close();
        },
        cancel() {
            this.item = null;
            this.close();
        },
    },
    computed: {
        thumbnailImage() {
            var img = this.getImage(this.item.thumbnail);
            if (img) {
                return this.servingImage(img, "s150-c");
            }
            return "https://placehold.it/256x256?text=+f4c6fa36ca68";
        },
    },
    watch: {},
    created() {},
    deactivated() {
        console.log("video modal deactivated");
    },
    mounted() {
        console.log("video modal mounted");
    },
};
</script>

<style scoped lang="scss">
</style>
