<template>
    <img v-if="image" :src="`${image.serving_url}${computeSize}`" :srcset="srcSet" alt="">
</template>

<script>
export default {
    name: "ListImage",
    props: {
        size: {
            type: Number,
            required: true,
        },
        crop: {
            type: Boolean,
            default: true,
        },
        image: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {
        computeSize() {
            if (this.size) {
                return `=s${this.size}${this.crop ? "-c" : ""}`;
            }
            return null;
        },
        srcSet() {
            if (this.size) {
                let s = parseInt(this.size);
                let s1 = s * 1.5;
                let s2 = s * 2;
                let url = this.image.serving_url;
                let crop = `${this.crop ? "-c" : ""}`;
                return `${url}=s${s1}${crop} 1.5x, ${url}=s${s2}${crop} 2x`;
            }
            return null;
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
</style>
