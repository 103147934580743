<template>
    <li @click="() => { $emit('click', item) }">
        <div class="content-list-item is-link">

            <div class="item-left">
                <div class="item-body">
                    <slot></slot>
                </div>
            </div>

            <div class="item-right">
                <div class="item-body has-text-right">
                    <div class="field">
                        <p class="control">
                            <a class="button is-danger" @click.stop="() => { $emit('remove', item) }">
                                <span class="icon">
                                    <i class="fa fa-trash"></i>
                                </span>
                            </a>
                        </p>
                    </div>
                    <div class="field">
                        <p class="control">
                            <a class="button" @click.stop="() => { $emit('edit', item) }">
                                <span class="icon">
                                    <i class="fa fa-pencil"></i>
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </li>
</template>

<script>
export default {
    name: "ContentPageItem",
    props: {
        item: {
            required: true,
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";

.content-list-item {
    transition: background-color 200ms $easing;
    // background-color: lighten($black-color, 98);
    border-top: lighten($black-color, 90) solid 1px;
    display: flex;
    justify-content: space-between;
    height: 100px;
    &:hover {
        cursor: pointer;
        background-color: lighten($black-color, 97);
    }
    .item-body {
        height: 100%;
    }

    .item-details {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .item-left {
        .image {
            margin-right: 10px;
        }
        .item-body {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .item-right {
        .item-body {
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
    }
    .item-details {
        margin-left: 10px;
        .title {
            margin-bottom: 2px;
        }
        .item-title {
            font-weight: 800;
        }
        .page-status {
            margin-bottom: 0;
            font-family: $heading-font;
        }
    }
}
</style>
