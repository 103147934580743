<template>
    <admin-layout :isLoaded="isLoaded">

        <b-tabs v-model="activeTab" class="section" @change="clickTab">
            <b-tab-item :label="name | capitalize" v-for="(list, name) in productsList" :key="name" class="list">

                <!-- empty list -->
                <div class="column is-12 is-text-centered" v-if="list.length == 0">
                    <div class="subtitle">
                        No {{name}} items.
                    </div>
                    <div class="field has-text-centered">
                        <router-link :to="{path: `/admin/products/create`}" class="button">
                            <icon icon="fa-plus" text="Create new product"></icon>
                        </router-link>
                    </div>
                </div>
                <!-- end empty list -->

                <list-item v-for="product in list" :key="product._id" :class="{'is-sold-out':product.sold_out}" :url="`/admin/products/${product._id}`" :image="getProductDefaultImage(product)" :size="80">
                    <div slot="details">
                        <router-link :to="getProductEditURL(product)">
                            <strong>{{product.name}}</strong> <span class="is-muted">({{product.quantity}})</span><br>
                        </router-link>
                        <ul class="title is-tiny-text has-text-grey-lighter">
                            <li>SKU: {{product.sku}}</li>
                            <li>ID: {{product._id}}</li>
                        </ul>
                        <strong>{{getProductDimensionString(product)}}</strong><br>
                        <ul>
                            <li><b>{{product.price | price}}</b></li>
                            <li><small>{{product.is_orignal?'Original':''}}</small></li>
                            <li class="is-green-text" v-if="product.sold_out">
                                <b>SOLD OUT</b>
                            </li>
                        </ul>
                    </div>
                    <div slot="controls">
                        <list-controls @duplicate="duplicateProduct(product)" @trash="moveProductToTrash(product)" @view="$router.push(getProductURL(product))" @edit="$router.push(getProductEditURL(product))">
                        </list-controls>
                    </div>
                </list-item>
            </b-tab-item>

            <!-- add new product -->
            <b-tab-item label="Add" icon="plus"></b-tab-item>
            <!-- end add new product -->

        </b-tabs>

    </admin-layout>
</template>

<script>
import _ from "lodash";
import ProductMixins from "@/mixins/product-mixins";
export default {
    name: "AdminProductsPage",
    mixins: [ProductMixins],
    props: {},
    data() {
        return {
            errors: null,
            activeTab: null,
            isLoaded: true,
            products: [],
            crumbs: [
                { name: "Admin", path: "/admin" },
                { name: "Products", path: "/admin/products", active: true },
            ],
        };
    },
    components: {},
    methods: {
        updateTabFromHash() {
            var names = this.tabNames;
            var index = names.indexOf(this.$route.hash.substring(1));
            if (index > -1) {
                this.activeTab = index;
            }
        },
        clickTab(e) {
            if (e == 3) {
                this.$router.push("/admin/products/create");
            } else {
                let name = this.tabNames[e];
                this.$router.push(`#${name}`);
            }
        },
        moveProductToTrash(product) {
            this.updateProduct(product._id, { status: "trash" }).then((status) => {
                if (status) {
                    product.status = "trash";
                }
            });
        },
        duplicateProduct(product) {
            var startKeys = Object.keys(this.defaultProduct());
            var payload = _.pick(product, startKeys);
            payload.name += " - Copy";
            payload.images = [];
            payload.hero_image = null;
            payload.status = "draft";

            this.createProduct(payload)
                .then((data) => {
                    this.$router.push(this.getProductEditURL(data));
                })
                .catch((err) => {
                    this.$swal({
                        title: "Oops!",
                        text: err.error,
                        icon: "warning",
                    });
                });
        },
    },
    computed: {
        tabNames() {
            return Object.keys(this.productsList);
        },
        productsList() {
            return {
                published: _.filter(this.products, { status: "published" }),
                drafts: _.filter(this.products, { status: "draft" }),
                trash: _.filter(this.products, { status: "trash" }),
            };
        },
    },
    watch: {
        "$route.hash"() {
            this.updateTabFromHash();
        },
    },
    created() {},
    mounted() {
        this.$api.get("products").then((e) => {
            this.products = e.data;
            this.isLoaded = true;
        });
        this.updateTabFromHash();
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins";
@import "~@/styles/variables";
</style>
