<template>
    <div class="footer">
        <div class="main-footer container">
            <div class="columns is-multiline is-gapless has-text-centered">
                <div class="column is-12">
                    <a :href="item.url" class="social-link" target="_blank" v-for="(item, name) in activeSocial" :key="name">
                        <icon :icon="item.icon"></icon>
                    </a>
                </div>
                <div class="column is-12">
                    <div class="footer-note title is-tiny-text">Handmade with <img class="heart-image" src="@/assets/img/heart.png" alt="❤️"> {{locationDetails}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
export default {
    name: "MainFooter",
    props: {
        social: {
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {};
    },
    components: {},
    methods: {},
    computed: {
        activeSocial() {
            return _.filter(this.social, function (obj) {
                if (obj.url) {
                    return true;
                }
            });
        },
        locationDetails() {
            if (this.$profile.location_name) {
                return `in ${this.$profile.location_name}`;
            }
            return "";
        },
    },
    watch: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";
@import "../../styles/mixins";
.social-link {
    padding: 2px;
    color: rgba(0, 0, 0, 0.2);
    transition: all 200ms;
    color: rgba($white-color, 0.3);
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-left: 25px;
    margin-right: 25px;
    &:hover {
        color: $white-color;
    }
    @include mobile() {
        margin-left: 2px;
        margin-right: 2px;
        font-size: 22px;
    }
}
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding-top: 100px;
    .main-footer {
        width: 100%;
    }
}
.footer-note {
    padding-top: 50px;
}
.heart-image {
    width: 15px;
    position: relative;
    top: 2px;
}
</style>
